import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AdminService } from 'src/app/services/admin.service';
import { GlobalService } from 'src/app/services/global.service';
import { InstService } from 'src/app/services/inst.service';
import { SettingsService } from 'src/app/services/settings.service';
//import MD5 from "crypto-js/md5";

@Component({
  selector: 'app-account-access',
  templateUrl: './account-access.component.html',
//   styleUrls: ['./account-access.component.css']
})
export class AccountAccessComponent implements OnInit {
  public showPasswordText: boolean = false;
  public user = JSON.parse(localStorage.getItem('currentUser')!);

  public instID: number = 1;
  public instSlug: string;
  public instName: string;
  public instIcon: string;
  public instBranch: string;

  public userRoleData: any[] = [];

  public showError = false;

  constructor(
    private _router: Router,
    private _gService: GlobalService,
    private _fBuilder: FormBuilder,
    private _adminService: AdminService,
    private _instService: InstService,
    private _sService: SettingsService
  ) {}
  
  // Inst_url = new FormControl('', [Validators.required]);
  // Account_id = new FormControl('', [Validators.required]);
  // Passcode = new FormControl('', [Validators.required]);

  public rObj = this._fBuilder.group({
    Inst_url: ['',Validators.required],
    Account_id: ['',Validators.required],
    Passcode: ['', Validators.required],
})

  ngOnInit() {
    if (this.user) {
      this._router.navigate(['/dashboard']);
      return false;
    }

    this._gService.showLoader(true);

    this._adminService.getUserRoleData().subscribe(res => {
      console.log(res.result)
      if(res.status == true) {
        this.userRoleData = JSON.parse(res.result)
        console.log(this.userRoleData)
      }
    })
    this._gService.showLoader(false);

  }

  showPassword() {
    this.showPasswordText = !this.showPasswordText;
  }

  submit() {
    this._gService.showLoader(true);
      if (this.rObj.valid) {
        let rObj = this.rObj.value;
        let loginData = {
          action: 'save',
          inst_url: rObj.Inst_url,
          account_id: rObj.Account_id,
          passcode: rObj.Passcode,
        }
      console.log(loginData)
      this.showError = false;

      this._adminService.adminAccountaccess(loginData).subscribe(res => {
        console.log(res.status)
        if (res.status === true) {
          console.log(res.result)
          let data = JSON.parse(res.result)

          this._sService.getMultipleSettingsData(data[0].instID, 'activeSession').subscribe(settingRes => {
            console.log(settingRes)
            if (settingRes.status === true) {
              console.log(settingRes.result)

              this._instService.getMultiInstDataByID(data[0].instID, 'isMultiBranch,isVernacular').subscribe(settingsRes => {
                console.log(settingsRes)
                  let Data = JSON.parse(settingsRes.result)[0]
                  let isVernacular = Data.isVernacular, isMultiBranch = Data.isMultiBranch;
                  console.log(isVernacular, isMultiBranch);
              let SettingData = JSON.parse(settingRes.result)[0]
              let UserID = data[0].ID, AcademicID = SettingData.activeSession, InstID = data[0].instID, UserRole = data[0].userRole, activeMedium = SettingData.medium, activeLanguage = SettingData.inst_language;
              console.log(UserID, InstID, UserRole);
              localStorage.setItem('currentUser', JSON.stringify({ UserID, InstID, AcademicID, UserRole, isMultiBranch, isVernacular, activeMedium, activeLanguage}));
              this._router.navigate([]).then((result) => {
                  window.open('/dashboard/', '_blank');
                });
              this._gService.triggerSnackbar('Login Succesful!', 'success', true); 
            })
            } else {
              this._gService.triggerSnackbar('Something went wrong', 'error', true); 
            }
          })

          
              // let SettingData = JSON.parse(res.result)[0]
              // let UserID = data[0].ID, AcademicID = SettingData.activeSession, InstID = data[0].instID, UserRole = data[0].userRole, activeMedium = SettingData.medium, activeLanguage = SettingData.inst_language;
              // console.log(UserID, InstID, UserRole);
              // localStorage.setItem('currentUser', JSON.stringify({ UserID, InstID, AcademicID, UserRole, activeMedium, activeLanguage}));
              // this._router.navigate([]).then((result) => {
              //   window.open('/dashboard/', '_blank');
              // });
           this._gService.triggerSnackbar('Login Succesfull!', 'success', true); 
        } else {
          // console.log(res);
          this.showError = true;
          this._gService.triggerSnackbar('Something went wrong', 'error', true); 
        }
      })
      this._gService.showLoader(false);
    }
  }

  }
