<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Income</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
          <a href="../finance/income-head/add/" class="content-header-link">Add Income Head</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Income
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label> Income Head </label>
                            <small class="req"> *</small>
                            <select formControlName="Income_head" class="form-select">
                                <option value="">Select</option>
                                <option *ngFor="let i of incomeHeadData" value="{{i.ID}}">{{i.income_head}}</option>  
                            </select>
                            <div *ngIf="rObj.controls.Income_head.invalid && (rObj.controls.Income_head.dirty || rObj.controls.Income_head.touched)">
                                <p class="input-error-msg">Please enter Income head</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Income Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Income_name" >
                            <div *ngIf="rObj.controls.Income_name.invalid && (rObj.controls.Income_name.dirty || rObj.controls.Income_name.touched)">
                                <p class="input-error-msg">Please enter Income name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Invoice Number</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Invoice_no" class="form-control " required="">
                            <div *ngIf="rObj.controls.Invoice_no.invalid && (rObj.controls.Invoice_no.dirty || rObj.controls.Invoice_no.touched)">
                                <p class="input-error-msg">Please enter Invoice no</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Date</label>
                            <small class="req"> *</small>
                            <input type="date" formControlName="Income_date" class="form-control date-input" required="">
                            <div *ngIf="rObj.controls.Income_date.invalid && (rObj.controls.Income_date.dirty || rObj.controls.Income_date.touched)">
                                <p class="input-error-msg">Please enter Income date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Amount</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="Amount" class="form-control " required="">
                            <div *ngIf="rObj.controls.Amount.invalid && (rObj.controls.Amount.dirty || rObj.controls.Amount.touched)">
                                <p class="input-error-msg">Please enter Amount</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveIncome()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Income List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="incomeData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Income Head</th>
                                <th class="align-left">Income Name</th>
                                <th class="align-left">Invoice Number</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of incomeData">
                                <td class="align-left">
                                    <ng-container *ngFor="let income of incomeHeadData">
                                        <ng-container *ngIf="income.ID == i.income_head">
                                            {{income.income_head}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">{{i.income_name}}</td>
                                <td class="align-left">{{i.invoice_no}}</td>
                                <td>{{getDate(i.income_date)}}</td>
                                <td>{{i.amount}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openIncomeEditModal(i.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(i.ID, 'income', 'income ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!incomeData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
        
</div>