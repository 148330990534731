<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-7">Generate Payroll</h1>
        <!-- <div class="header-msg col-md-5">
                    </div> -->
        <div class="link-container col-md-5">
            <!-- <a href="../add/" class="content-header-link">Add New Student</a>
            <a href="../import/" class="content-header-link">Import Students</a> -->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Department
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Department" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let dept of DepartmentData" value="{{dept.ID}}">{{dept.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Designation
                            <small class="req"> *</small></label>
                            <select name="section" formControlName="Designation" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let des of DesignationData" value="{{des.ID}}">{{des.name}}</option>   
                            </select>
                            <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                                <p class="input-error-msg">Please select section</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Month
                            <small class="req"> *</small></label>
                            <select name="section" formControlName="Month" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let m of MonthData" value="{{m.month}}">{{m.monthName}}</option>   
                            </select>
                            <div *ngIf="rObj.controls.Month.invalid && (rObj.controls.Month.dirty || rObj.controls.Month.touched)">
                                <p class="input-error-msg">Please select Month</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Year
                            <small class="req"> *</small></label>
                            <select name="section" formControlName="Year" class="form-select" >
                                <option value="">Please Select</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <!-- <option *ngFor="let des of designationData" value="{{des.ID}}">{{des.name}}</option>    -->
                            </select>
                            <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                <p class="input-error-msg">Please select Year</p>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 24px;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="navigate()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffData.length">
                        <thead>
                            <tr>
                                <!-- <th class="align-left"><input type="checkbox" id="select_all"></th> -->
		                        <th class="align-left">Staff ID</th>
			                    <th class="align-left">Staff Name</th>
			                    <th class="align-left">Department</th>
                                <th class="align-left">Designation</th>
			                    <th>Role</th>
		                        <th>Mobile</th>
			                    <th>Status</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of staffData">
                                <!-- <td class="align-left">
                                  <input type="checkbox" class ="list-checkbox" value="ID">
                                    <input type='checkbox' class="list-checkbox" data-id='<?php echo $row['ID'] ; ? >' >
                                </td> -->
                                <td class="align-left">{{s.staff_id}}</td>
                                <td class="align-left">{{s.full_name}}</td>
                                <td class="align-left">{{s.department_name}}</td>
                                <td class="align-left">{{s.designation_name}}</td>
                                <td>{{s.role_name}}</td>
                                <td>{{s.mobile}}</td>
                                <td>{{getPayrollStatus(s.ID) ? getPayrollStatus(s.ID) : 'Not Generated'}}</td>
                                <td class="action-btn">
                                    <ng-container *ngIf="getPayrollStatus(s.ID) == 'Paid'">
                                        <a tooltip="Revert" class="edit-btn" (click)="RevertPage(s.ID, 'payroll', 'payroll')"><i class="fa-solid fa-rotate-right"></i></a>
                                        <button type="button" class="btn primary-btn small-btn" (click)="openPaymentReceiptModal(s.ID, s.full_name, monthId, yearId, departmentId, designationId)">View</button>
                                     </ng-container>
                                    <ng-container *ngIf="getPayrollStatus(s.ID) == 'Generated'">
                                        <a tooltip="Edit" class="edit-btn" (click)="openEditPage(s.ID)"><i class="far fa-edit"></i></a>
                                        <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'payroll', 'payroll')"><i class="far fa-trash-alt"></i></a>
                                        <button type="button" class="btn primary-btn small-btn" (click)="openPayModal(s.ID, s.full_name, monthId, yearId, payment_amt, departmentId, designationId)">Pay</button>
                                    </ng-container>
                                    <ng-container *ngIf="!getPayrollStatus(s.ID)?.length">
                                        <button class="btn primary-btn small-btn" (click)="goToGeneratePage(s.ID)" >Generate</button>
                                    </ng-container>

                                </td>
                            </tr>
                        </tbody>
                    </table>    
                    <div *ngIf="!staffData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>            
        
                            
            </div>
        </div>
      
    </div>
</div>