<header class="console-header">
    <div class="console-header-content">
        <div class="console-logo" [ngClass]="sidebarStatus ? 'collapse' : ''">
            <div class="icon"><img src="/assets/img/enp-header-icon.png"></div>
            <div class="logo"><img src="/assets/img/enp-header-logo.png"></div>
            <span>v 1.0</span>
        </div>
        <div class="console-header-item nav-control" (click)="toggleSidebar()">
            <span [ngClass]="sidebarStatus ? 'active' : '' ">
                <i class="fas fa-bars"></i>
            </span>  
        </div>
        <div class="console-header-item header-search-wrapper">
            <ng-autocomplete 
                [data]="searchData"
                [searchKeyword]="'name'"
                placeholder="Search..."
                (selected)='selectEvent($event)'
                (inputChanged)='onChangeSearch($event)'
                (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">                                 
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
                <a [innerHTML]="searchItem(item)"></a>
            </ng-template>
            
            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
            <!-- <input type="text" class="form-control search" placeholder="Search...">
            <button class="header-search-btn" name="header_search"><i class="fas fa-search"></i></button> -->
        </div>
        <ul class="console-header-items-right">
            <li class="console-header-item header-alert">
                            </li>
           
            <li class="console-header-item header-info">
                <div class="academic-session header-selection-wrapper">
                    <span>Academic Year:</span>
                    <p class="academic-year">{{activeAcademicSession}} <span class="change-icon" (click)="openAcademicYearsModal()" tooltip="Change Session"><i class="fa fa-pencil pull-right"></i></span></p>
                </div>
            </li>
            <li class="console-header-item header-info" *ngIf="isVernacular == 1">
                <div class="academic-session header-selection-wrapper">
                    <span>Medium:</span>
                    <select class="medium-dd" (change)="switchMedium($event)">
                        <option value="">Select</option>
                        <option *ngFor="let m of mediumData" value="{{m.ID}}" [selected]="activeMedium == m.ID">{{m.name}}</option>
                    </select>
                    <!-- <p class="academic-year">{{activeAcademicSession}} <span class="change-icon" (click)="openAcademicYearsModal()" tooltip="Change Session"><i class="fa fa-pencil pull-right"></i></span></p> -->
                </div>
            </li>
            <!-- <li class="console-header-item header-language has-dd" *ngIf="isVernacular == 'yes'">
                <span (click)="showLanguages($event)" class="header-language-icon" [ngClass]="showLanguageClass ? 'active' : ''">
                    <span class="icon-wrapper"><img [src]="'/assets/img/icons/language/'+activeLanguageIcon()"></span>
                </span>
                <ul class="console-header-dd header-language-list" [ngClass]="showLanguageClass ? 'show' : ''" (click)="$event.stopPropagation()">
                    <li *ngFor="let language of allLanguages" class="language-item" (click)="switchLanguage(language.value)"><img [src]="'/assets/img/icons/language/'+language.icon"><span class="text">{{language.title}}</span> <span class="check" [hidden]="language.value != activeLanguage"><i class="fa fas fa-check"></i></span></li>
                </ul>
            </li> -->
            <li class="console-header-item header-notification has-dd">
                <span (click)="showNotifications($event)" [ngClass]="showNotificationsClass ? 'active' : ''"><i class="fa-bell" [ngClass]="showNotificationsClass ? 'fas' : 'far'"></i></span>
                <div class="console-header-dd header-notification-info" [ngClass]="showNotificationsClass ? 'show' : ''" (click)="$event.stopPropagation()">
                    <div class="notification-link" *ngFor="let n of notificationData; let i = index">
                        <div *ngIf="(i < 3)" class="row">
                            <a class="view-all-link col-md-8" (click)="gotoPage(n.link)">{{n.title}}</a>
                            <h6 class="view-all-link col-md-4" style="justify-content: right; font-size: 12px; margin: 0px;">{{getDate(n.datetime)}}</h6>
                        </div>
                    </div>
                    <div class="notification-info-data" *ngIf="!notificationData.length">
                        <h5>No new notifications</h5>
                    </div>
                    <div class="notification-link" *ngIf="notificationData.length">
                        <a class="view-all-link" href="/notifications/">
                            View All
                        </a>
                    </div>
                </div>
                
            </li>
            <li class="console-header-item header-user has-dd">
                <span (click)="showUserInfo($event)" [ngClass]="showUserInfoClass ? 'active' : ''"><img src="{{imageSrc ? imgURL+imageSrc : gender == 'male' ? 'assets/img/male-worker.png' : 'assets/img/female-worker.png'}}"></span>
                <div class="console-header-dd header-user-info" [ngClass]="showUserInfoClass ? 'show' : ''" (click)="$event.stopPropagation()">
                    <div class="user-info-data">
                        <div class="user-img">
                            <img src="{{imageSrc ? imgURL+imageSrc : gender == 'male' ? 'assets/img/male-worker.png' : 'assets/img/female-worker.png'}}">
                        </div>
                        <div class="user-info">
                            <h5>{{userName}}</h5>
                            <h6>{{userRole}}</h6>
                            <a class="edit-profile-link" href="{{userRole == 'Admin' ? '/user/profile/' : 'staff/view/'+staffID}}">
                                <i class="far fa-user"></i>
                                <span>Profile</span>
                            </a>
                        </div>
                        
                    </div>
                    <div class="user-info-links">
                        <a class="edit-profile-link" (click)="openChnagePasswordmodal()">
                            <i class="far fa-user"></i>
                            <span>Change Password</span>
                        </a>
                        <a class="logout-link" (click)="logOut()">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</header>