import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy,ActivatedRoute } from '@angular/router';
import { PayrollService } from 'src/app/services/payroll.service';
import { ReportService } from 'src/app/services/report.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PrintPaymodalModalComponent } from 'src/app/components/modal/printPaymodal/printPaymodal.component';
@Component({
  selector: 'app-staff-viewStaff',
  templateUrl: './viewStaff.component.html'
})

export class ViewStaffComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public departmentData: any[] = [];
    public designationData: any[] = [];
    public staffPayrollData: any[] = [];
    public staffData: any[] = [];
    public documentData: any[] = [];
    public academicYearsData: any[] = [];
    public monthData: any[] = [];
    public payrollData: any[] = [];
    public userRoleData: any[] = [];

    public username: any;
    public password: any;
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];
    
    public imgURL = this._gService.getImgPath();
    imageSrc:any = '';
    status:boolean = false;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    dtOptions: any = {};
    modalRef: BsModalRef;

    viewId :any;

    public profileTab: boolean = true;
    public documentTab: boolean = false;
    public PayrollTab: boolean = false;
    public timelineTab: boolean = false;
    public detailsTab: boolean = false;
    
    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _uService: UserService,
        private _rService: ReportService,
        private _pService: PayrollService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,

    ) {}

    public rObj = this._fBuilder.group({
        Month: ['', Validators.required], 
        Year: ['', Validators.required],
    })

    ngOnInit(): void {
        this.viewId = this._aRoute.snapshot.paramMap.get('id');

        this._gService.getUserRoleData().subscribe(res =>{
            if (res.status === true) {
                this.userRoleData = JSON.parse(res.result)
                console.log(this.userRoleData)
            }
        })

        this._pService.getStaffpayrollDataByIdData(this.instID,this.activeAcademicID,this.viewId).subscribe(res => {
            console.log(res)
            if(res.status === true) {
                this.staffPayrollData = JSON.parse(res.result)
                console.log(this.staffPayrollData)
            }
        }) 

        this._aService.getStaffDocumentData(this.viewId).subscribe( res => {
            console.log(res)
            if(res.status === true){
                this.documentData = JSON.parse(res.result);
                
            }
        })

        

        this._gService.getDataByID(this.viewId, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
                console.log(this.staffData)
                let id = this.staffData[0].user_id
                this._uService.getDetailsData(id).subscribe(ayRes => {
                    console.log(ayRes)
                    if (ayRes.status === true) {
                        let data = JSON.parse(ayRes.result);
                        console.log(data)
                        this.username = data[0].username
                        this.password = data[0].passwordString    
                    }
                    this._gService.showLoader(false);
                })
        
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.imageSrc = this.staffData[0].photo_path ? this.imgURL+this.staffData[0].photo_path : '';
        console.log(this.imageSrc)
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    openPaymentReceiptModal(payId:any, month: any, year:any){
        this.modalRef = this.modalService.show(PrintPaymodalModalComponent, {
            class: 'modal-dialog-centered payreceipt-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                // payId:(this.getPayId(payId)),
                payId:payId,
                staff_name: this.staffData[0].full_name,
                month: month,
                year : year,
                department : this.staffData[0].department,
                designation : this.staffData[0].designation
            } 
        });
    }

    getPayId(staff_id : number) {
        let id =this.staffPayrollData.filter(r => r.staff_id == staff_id).map(r => r.ID)
        // alert(id)
        return id;
    }

    monthsBetween(d1: any, d2: any) {
        const monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
        console.log(monthNames)
        let result = [];
        let current = d1.getMonth();
        let end = (d2.getFullYear() - d1.getFullYear()) * 12 + d2.getMonth();
        for (;current <= end; current += 1) {
            result.push(monthNames[current % 12]);
        }
        return result;
    }

    getMonthName(month: any) {
        return this.MonthData.filter(m => m.month == month).map(m => m.monthName)     
    }
    getUserRoleName(roleID : any){
        return this.userRoleData.filter(r => r.ID == roleID).map(r => r.name)
    }

    setTabActive(tabName: string) {
        if (tabName == 'profileTab') {
            this.profileTab = true
        } else {
            this.profileTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'PayrollTab') {
            this.PayrollTab = true
        } else {
            this.PayrollTab = false
        }
        if (tabName == 'timelineTab') {
            this.timelineTab = true
        } else {
            this.timelineTab = false
        }
        if (tabName == 'detailsTab') {
            this.detailsTab = true
        } else {
            this.detailsTab = false
        }
    }

}