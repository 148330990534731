import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminHeaderComponent } from 'src/app/components/common/admin-header/admin-header.component';
import { AdminSidebarComponent } from 'src/app/components/common/admin-sidebar/admin-sidebar.component';
import { AdminPanelComponent } from './adminpanel.component';
import { modulesAccessComponent } from './modules-access/modules-access.component';
import { AdminDashboardComponent } from './admindashboard/admindashboard.component';
import { UsersComponent } from './users/addUsers/users.component';
import { UsersListComponent } from './users/usersList/usersList.component';
import { EditUsersModalComponent } from 'src/app/components/modal/editUsersmodal/editUsersmodal.component';
import { institutionAddComponent } from './institution/add/add.component';
import { institutionEditComponent } from './institution/edit/edit.component';
import { institutionListComponent } from './institution/list/list.component';
import { AdminEditProfileComponent } from './admin-profile/admin-edit-profile.component';
import { ChangeAdminPasswordModalComponent } from 'src/app/components/modal/changeAdminPasswordmodal/changeAdminPassword.component';
import { ActiveModalComponent } from 'src/app/components/modal/activemodal/activemodal.component';
import { ChangeUserPasswordModalComponent } from 'src/app/components/modal/changeUserPasswordmodal/changeUserPasswordmodal.component';
import { AccountAccessComponent } from './accountAccess/account-access.component';

let routes: Routes = [
    {
        path: '', component: AdminPanelComponent, title: 'ENP Admin',
        children: [
            { path: 'admin/modulesaccess', component: modulesAccessComponent, title: 'Module Access - ENP Admin' },
            { path: 'admin/modulesaccess/:instID', component: modulesAccessComponent, title: 'Module Access - ENP Admin' },
            { path: 'admin/dashboard', component: AdminDashboardComponent, title: 'Admin Dashboard - EduNationPro'}, 
            { path: 'admin/users/add', component: UsersComponent, title: 'Admin Users - EduNationPro'}, 
            { path: 'admin/users/list', component: UsersListComponent, title: 'Admin Users - EduNationPro'}, 
            { path: 'admin/institution/add', component: institutionAddComponent, title: 'Admin Users - EduNationPro'}, 
            { path: 'admin/institution/edit/:instId', component: institutionEditComponent, title: 'Admin Users - EduNationPro'}, 
            { path: 'admin/institution/list', component: institutionListComponent, title: 'Admin Users - EduNationPro'} ,
            { path: 'admin/edit/profile', component: AdminEditProfileComponent, title: 'Admin Edit Profile - EduNationPro'}, 
            { path: 'admin/accountAccess', component: AccountAccessComponent, title: 'Access Another Account - EduNationPro'} 
        ] 
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
  })

export class AdminPanelRoutingModule { }
export const AdminPanelRoutes = [
    AdminPanelComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    modulesAccessComponent,
    AdminDashboardComponent,
    UsersComponent,
    UsersListComponent,
    EditUsersModalComponent,
    institutionAddComponent,
    institutionEditComponent,
    institutionListComponent,
    AdminEditProfileComponent,
    ChangeAdminPasswordModalComponent,
    ActiveModalComponent,
    ChangeUserPasswordModalComponent,
    AccountAccessComponent
]