<div class="console-content">
    <form [formGroup]="rObj">
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link " [ngClass]="balancefeesStatement ? 'active' : ''">
                        <a (click)="setTabActive('balancefeesStatement')">Balance Fees Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="dailyCollectionReport ? 'active' : ''">
                        <a (click)="setTabActive('dailyCollectionReport')">Daily Collection Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="feesStatement ? 'active' : ''">
                        <a (click)="setTabActive('feesStatement')">Fees Statement</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="feesCollectionReport ? 'active' : ''">
                        <a (click)="setTabActive('feesCollectionReport')">Fees Collection Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="incomeReport ? 'active' : ''">
                        <a (click)="setTabActive('incomeReport')">Income Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="expenseReport ? 'active' : ''">
                        <a (click)="setTabActive('expenseReport')">Expense Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="payrollReport ? 'active' : ''">
                        <a  (click)="setTabActive('payrollReport')">Payroll Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="onlineAdmissionFeesCollection ? 'active' : ''">
                        <a  (click)="setTabActive('onlineAdmissionFeesCollection')">Online Admission Fees Collection Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="incomeGroupReport ? 'active' : ''">
                        <a  (click)="setTabActive('incomeGroupReport')">Income Group Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="expenseGroupReport ? 'active' : ''">
                        <a  (click)="setTabActive('expenseGroupReport')">Expense Group Report</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="balancefeesStatement" class="panel panel-margin-top" [ngClass]="balancefeesStatement ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Balance Fees Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row" style="margin-bottom: 20px;">  
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group form-btn-wrapper" style="margin-top:25px">
                                        <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="searchbalance()">Search</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>  
                           
                    </div>

                    <div class="panel-body">
                        <ng-container *ngIf="duefeeData.length">
                            <ng-container  *ngFor="let s of duefeeData">
                            <div class="row student-info">
                                <div class="col-md-3">
                                    <label>Admission No.:</label>{{s.admission_number}}
                                </div>
                                <div class="col-md-3">
                                    <label>Name:</label>{{s.student_name}}
                                </div>
                                <div class="col-md-3">
                                    <label>Father Name:</label>{{s.father_name}}    
                                </div>
                                <div class="col-md-3">
                                    <label>class(section):</label>{{s.class_name}}/{{s.section_name}}
                                </div>
    
                            </div>
                            
                        <table id="list" class="list"  >
                            <thead>
                                <tr>                                    
                                    <th class="align-left">Fees Group </th>
                                    <th>Fees Code</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>   
                                    <td class="align-left">{{s.class_name}}/{{s.section_name}}</td>  
                                    <td>{{s.fee_type_name}}</td>        
                                    <td>{{s.due_date}}</td>
                                    <td>Unpaid</td>
                                    <td>{{s.amount}}</td>                             
                                </tr>
                            </tbody>
                        </table>  
                        </ng-container>
                        </ng-container> 
                        <div *ngIf="!duefeeData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>


                </div>
                <div id="dailyCollectionReport" class="panel panel-margin-top" [ngClass]="dailyCollectionReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-6">Daily Collection Report</h1>
                        <div class="header-msg col-md-5">

                        </div>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div  class="col-md-12" style="display: contents;">
                                <div class="col-md-12 row">
                                    <div class="col-md-5" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 btn-container">
                                        <button class="submit-btn primary-btn btn" [disabled]="!rObj.valid" (click)="searchAdmission()">Search</button>
                                    </div>
                                </div>                               
                            </div>
                                   
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="dailyCollectionData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Date</th>
                                    <th>Total Transaction</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of dailyCollectionData">   
                                    <td class="align-left">{{s.payment_date}}</td>  
                                    <td>{{s.transactions}}</td>
                                    <td>{{s.amount}}</td>
                                    <td class="action-btn">
                                        <a tooltip="View" class="view-btn" (click)="viewModal(s.payment_date)"><i class="far fa-eye"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!dailyCollectionData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>

                </div>
                <div id="feesStatement" class="panel panel-margin-top" [ngClass]="feesStatement ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Fees Statement</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row" style="margin-bottom: 20px;"> 
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Student
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Student" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let s of studentListData" value="{{s.ID}}">{{s.full_name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group form-btn-wrapper" style="margin-top:25px">
                                        <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>     
                    </div>
                    
                    <div class="panel-body row" *ngFor="let student of studentData">
                        <div class="col-md-2">
                            <div class="student-photo">
                                <img [src]="student.photo_path ? imgURL+student.photo_path : (student.gender == 'male' ? '/assets/img/male-student.png' : '/assets/img/female-student.png')" width="65%">
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="row details">
                                <div class="col-md-3"  style="margin-bottom: 15px;">Name</div>
                                <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.full_name}}</div>
                                <div class="col-md-3" style="margin-bottom: 15px;">Class</div>
                                <div class="col-md-3 head" style="margin-bottom: 15px;">
                                    <ng-container *ngFor="let class of classData">
                                        <ng-container *ngIf="class.ID == student.class">
                                            {{class.name}}
                                        </ng-container>
                                    </ng-container>   
                                </div>
                                <div class=" col-md-3" style="margin-bottom: 15px;">Section</div>
                                <div class=" col-md-3 head" style="margin-bottom: 15px;">
                                    <ng-container *ngFor="let section of sectionData">
                                        <ng-container *ngIf="section.ID == student.section">
                                            {{section.name}}
                                        </ng-container>
                                    </ng-container>    
                                </div>
                        
                            <!-- <div class="row details"> -->
                                <div class="col-md-3" style="margin-bottom: 15px;">Roll No.</div>
                                <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.roll_no}}</div>
                                <div class="col-md-3" style="margin-bottom: 15px;">Admission Date.</div>
                                <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.admission_date}}</div>
                                <div class="col-md-3" style="margin-bottom: 15px;">Register No.</div>
                                <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.admission_number}}</div>
                            <!-- </div> -->
                        </div>
                        </div>
                    </div>
             
                    <div class="panel-body">
                        <!-- <ng-container *ngIf="feesMasterData.length">
                            <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                                <span class="rows_selected">{{selectedIds.length}} Selected</span>
                                <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                            </div>
                        </ng-container> -->
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feesMasterData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th>Fees Group</th>
                                        <th>Fees Type</th>
                                        <th>Amount</th>
                                        <th>Fine Amount</th>
                                        <th>Fine Type</th>    
                                        <th>Due Date</th>
                                        <th>Total Amount</th>
                                        <th>Transaction Id</th>
                                        <th>Status</th>
                                        
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let f of feesMasterData">
                                    {{dueCheck(f.due_date)}}
                                    <tr [ngClass]="isDue &&  !getTxnId(f.ID).length ? 'due-color' : ''">
                                        <td>
                                            <ng-container *ngFor="let class of classData">
                                                <ng-container *ngIf="class.ID == f.class">
                                                    {{class.name}}
                                                </ng-container>
                                            </ng-container>
                                            /
                                            <ng-container *ngFor="let section of sectionData">
                                                    <ng-container *ngIf="section.ID == f.section">
                                                    {{section.name}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <ng-container *ngFor="let type of feeTypeData">
                                                <ng-container *ngIf="type.ID == f.fees_type">
                                                    {{type.name}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>{{f.amount}}</td>
                                        <td>{{f.fine_amount}}</td>
                                        <td>{{f.fine_type}}</td>
                                        <td>{{f.due_date}}</td>
                                        <td>{{isDue ? totalAmount(f.amount, f.fine_amount) : f.amount}}</td>   
                                        <td>{{getTxnId(f.ID).length ? getTxnId(f.ID) : '-'}}</td> 
                                        <td>{{getTxnId(f.ID).length ? 'Paid' : 'Pending'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>   
                        <div *ngIf="!feesMasterData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>         
                           

                </div>
                <div id="feesCollectionReport" class="panel panel-margin-top" [ngClass]="feesCollectionReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Fees Collection Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row" style="margin-bottom: 20px;">
                                <!-- <div class="form-group row">    -->
                                <div class="col-md-2"> 
                                    <label>Period<small class="req"> *</small></label>   
                                    <select class="form-control" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>
                                    <div *ngIf="rObj.controls.Search_type.invalid && (rObj.controls.Search_type.dirty || rObj.controls.Search_type.touched)">
                                        <p class="input-error-msg">Please select Search type</p>
                                    </div>   
                                </div> 
                                <div  class="col-md-4" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                                <!-- </div>     -->
                                <div class="col-md-2">
                                    <label>Class
                                        <small class="req"> *</small>
                                    </label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Section</label>
                                        <small class="req"> *</small>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Fees Group</label>
                                    <!-- <small class="req"> *</small> -->
                                    <select name="class" formControlName="Fee_group" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let f of feeGroupData" value="{{f.ID}}">{{f.name}}</option>               
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="searchfeecollection()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">
                        <!-- <ng-container *ngIf="feesMasterData.length">
                            <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                                <span class="rows_selected">{{selectedIds.length}} Selected</span>
                                <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                            </div>
                        </ng-container> -->
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feeCollectionData.length">
                            <thead>
                                <tr>
                                    <th>Payment Id</th>
                                    <th>Date</th>
                                    <th>Admission No</th>
                                    <th>Name</th>
                                    <th>Class(Section)</th>    
                                    <th>Fee Group</th>
                                    <th>Mode</th>
                                    <th>Discount</th>
                                    <th>Fine</th>
                                    <th>Amount</th>                            
                                </tr>
                            </thead>
                            <tbody>
                               <tr *ngFor="let f of feeCollectionData">
                                    <td>{{f.txn_id}}</td>
                                    <td>{{f.payment_date}}</td>
                                    <td>{{f.admission_number}}</td>
                                    <td>{{f.student_name}}</td>
                                    <td>{{f.class_name}}({{f.section_name}})    </td>
                                    <td>{{f.fees_group_name}}</td>
                                    <td>{{f.payment_mode}}</td>
                                    <td>{{f.discount_amount}}</td>
                                    <td>{{f.fine_amount}}</td>
                                    <td>{{f.amount}}</td>
                               </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!feeCollectionData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>    
                  
                </div>
                <div id="incomeReport" class="panel panel-margin-top" [ngClass]="incomeReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Income Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>             
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-5"> 
                                <label>Period</label>   
                                <select class="form-control" formControlName="Period" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="today">Today</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div  class="col-md-4" *ngIf="rObj.controls.Period.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="incomeData.length">
                            <thead>
                                <tr>                                    
                                    <th class="align-left">Name </th>
                                    <th>Invoice No.</th>
                                    <th>Income Head</th>
                                    <th>Date</th>
                                    <th>Amount</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of incomeData">   
                                    <td class="align-left">{{s.income_name}}</td>  
                                    <td>{{s.invoice_no}}</td>        
                                    <td>{{s.income_head_name}}</td>
                                    <td>{{s.income_date}}</td>
                                    <td>{{s.amount}}</td>                             
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!incomeData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="expenseReport" class="panel panel-margin-top" [ngClass]="expenseReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-5">Expense Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>            
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-5"> 
                                <label>Period</label>   
                                <select class="form-control" formControlName="expense_Period" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="today">Today</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div  class="col-md-7" *ngIf="rObj.controls.expense_Period.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="expenseData.length">
                            <thead>
                                <tr>    
                                    <th class="align-left">Name </th>
                                    <th>Invoice No.</th>
                                    <th>Expense Head</th>
                                    <th>Date</th>
                                    <th>Amount</th>   
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of expenseData">   
                                    <td class="align-left">{{s.expense_name}}</td>  
                                    <td>{{s.invoice_no}}</td>        
                                    <td>{{s.expense_head_name}}</td>
                                    <td>{{s.expense_date}}</td>
                                    <td>{{s.amount}}</td>                             
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!expenseData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>

                </div>
                <div id="payrollReport" class="panel panel-margin-top" [ngClass]="payrollReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Payroll Report</h1>
                        <div class="header-msg col-md-5">

                        </div>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-5"> 
                                <label>Period</label>   
                                <select class="form-control" formControlName="Payroll_period" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div  class="col-md-7" *ngIf="rObj.controls.Payroll_period.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>
                    <!-- remaining  -->
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="payrollData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Name</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Month-Year</th>
                                    <th>Basic Salary</th>
                                    <th>Earnings</th>
                                    <th>Deduction</th>
                                    <th>Gross Salary</th>
                                    <th>Tax</th>
                                    <th>Net Salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of payrollData">   
                                    <td class="align-left">{{p.staff_name}}</td>  
                                    <td>{{p.department_name}}</td>
                                    <td>{{p.designation_name}}</td>
                                    <td>{{getMonth(p.month)}}-{{p.year}}</td>
                                    <td>{{p.basic}}</td>
                                    <td>{{p.earning}}</td>
                                    <td>{{p.deduction}}</td>
                                    <td>{{p.gross}}</td>
                                    <td>{{p.tax}}</td>                                  
                                    <td>{{p.net}}</td>                                  
                                                                      
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!payrollData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="onlineAdmissionFeesCollection" class="panel panel-margin-top" [ngClass]="onlineAdmissionFeesCollection ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Online Admission Fee Collection Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>
                
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="ApplicationData.length">
                            <thead>
                                <tr>
                                    <th>Reference No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Application No.</th>
                                    <th>Email</th>
                                    <th>Mobile No.</th>
                                    <th>Class</th>
                                    <th>Application Status </th>
                                    <th>Payment Status </th>
                                    <th>Transaction ID </th>
                                    <th>Date </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of ApplicationData">
                                    <td>{{s.ref_no}}</td>
                                    <td>{{s.full_name}}</td>
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>{{s.email}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{getClass(s.class)}}</td>
                                    <td>{{s.status == 1 ? 'Submitted' : 'NotSubmitted'}}</td>
                                    <td>{{s.payment_status == 1 ? 'Paid' : 'UnPaid'}}</td>
                                    <td>{{s.payment_status == 1 ? s.txn_id : '-'}}</td>
                                    <td>{{s.create_date}}</td>
                                    
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!ApplicationData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div> 
                    <!-- remaining  -->
                    <!-- <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="incomeData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Admission No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Class(Section)</th>
                                    <th>Father Name</th>
                                    <th>Date of Birth</th>
                                    <th>Admission date</th>
                                    <th>Gender</th>
                                    <th>Category</th>
                                    <th>Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of incomeData">   
                                    <td class="align-left">{{s.admission_number}}</td>  
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.class_name}}({{s.section_name}})</td>
                                    <td>{{s.father_name}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.admission_date}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.category}}</td>
                                    <td>{{s.mobile}}</td>                                  
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!incomeData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div> -->
                </div>
                <div id="incomeGroupReport" class="panel panel-margin-top" [ngClass]="incomeGroupReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-5">Income Group Report</h1>

                    </div>             
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-3"> 
                                <label>Period
                                <small class="req"> *</small></label>  
                                <select class="form-control" formControlName="Period" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="today">Today</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div  class="col-md-6" *ngIf="rObj.controls.Period.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" col-md-3"> 
                                <label>Income Head</label>   
                                <select class="form-control" formControlName="Income_Head" autocomplete="off">
                                    <option value="">Select</option>
                                    <option *ngFor="let i of incomeheadData" value="{{i.ID}}">{{i.income_head}}</option>               
                                </select>   
                            </div>


                           
                            <div class="form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="incomeData.length">
                            <thead>
                                <tr>                                    
                                    <th class="align-left">Name </th>
                                    <th>Invoice No.</th>
                                    <th>Income Head</th>
                                    <th>Date</th>
                                    <th>Amount</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of incomeData">   
                                    <td class="align-left">{{s.income_name}}</td>  
                                    <td>{{s.invoice_no}}</td>        
                                    <td>{{s.income_head_name}}</td>
                                    <td>{{s.income_date}}</td>
                                    <td>{{s.amount}}</td>                             
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!incomeData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="expenseGroupReport" class="panel panel-margin-top" [ngClass]="expenseGroupReport ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-5">Expense Group Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>            
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-3"> 
                                <label>Period</label>   
                                <select class="form-control" formControlName="expense_Period" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="today">Today</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div class=" col-md-3"> 
                                <label>Expense Head</label>   
                                <select class="form-control" formControlName="Expense_Head" autocomplete="off">
                                    <option value="">Select</option>
                                    <option *ngFor="let e of expenseheadData" value="{{e.ID}}">{{e.expense_head}}</option>               
                                </select>   
                            </div>
                            <div  class="col-md-6" *ngIf="rObj.controls.expense_Period.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="expenseData.length">
                            <thead>
                                <tr>    
                                    <th class="align-left">Name </th>
                                    <th>Invoice No.</th>
                                    <th>Expense Head</th>
                                    <th>Date</th>
                                    <th>Amount</th>   
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of expenseData">   
                                    <td class="align-left">{{s.expense_name}}</td>  
                                    <td>{{s.invoice_no}}</td>        
                                    <td>{{s.expense_head_name}}</td>
                                    <td>{{s.expense_date}}</td>
                                    <td>{{s.amount}}</td>                             
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!expenseData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>