<div class="modal-header">
    <h5 class="modal-title">Edit Exam</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Name" >
                <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                    <p class="input-error-msg">Please enter name</p>    
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" formControlName="Description"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>    
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateType()">Update</button>
            </div>
        </form> 
    </div>
</div>
