import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class InstService {
  constructor(
    private _http: HttpClient,
  ) { }

  private instData = `${environment.APIURL}/institution/instData/`;
  private instListByAdminID = `${environment.APIURL}/institution/instListByAdminID/`;
  private instDataBySlug = `${environment.APIURL}/institution/instDataBySlug/`;
  private addInst = `${environment.APIURL}/institution/addInst/`;
  private editInst = `${environment.APIURL}/institution/editInst/`;
  private deleteInst = `${environment.APIURL}/institution/deleteInst/`;
  private multiInstData = `${environment.APIURL}/institution/getMultiInstData/`;

  getMultiInstDataByID(inst_id: number, data: any): Observable<any> {
    return this._http.get(this.multiInstData+'?inst_id='+inst_id+'&data='+data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getInstDataByID(inst_id: number): Observable<any> {
    return this._http.get(this.instData+'?inst_id='+inst_id, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getInstListByAdminID(admin_id: number): Observable<any> {
    return this._http.get(this.instListByAdminID+'?admin_id='+admin_id, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getInstListBySlug(slug: string): Observable<any> {
    return this._http.get(this.instDataBySlug+'?slug='+slug, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  addInstData(data: object): Observable<any> {
    return this._http.post(this.addInst, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  editInstData(data: object): Observable<any> {
    return this._http.post(this.editInst, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  deleteInstData(inst_id: number): Observable<any> {
    return this._http.get(this.deleteInst+'?inst_id='+inst_id, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}