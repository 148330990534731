<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Homework List</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-9">
            <!--<a href="../../hostel-detail/add/" class="content-header-link">Add Hostel</a>
            <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Homework List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="assignmentData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Class</th>
                                <th class="align-left">Section</th>
                                <th class="align-left">Subject</th>
                                <th>Homework Date</th>
                                <th>Submission Date</th>
                                <th>Document</th>
                                <th class="align-left" style="width: 150px;">Description</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of assignmentData">
                                <td class="align-left">
                                    <ng-container *ngFor="let c of classData">
                                        <ng-container *ngIf="c.ID == a.class">
                                            {{c.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">
                                    <ng-container *ngFor="let c of sectionData">
                                        <ng-container *ngIf="c.ID == a.section">
                                            {{c.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">
                                    <ng-container *ngFor="let c of subjectData">
                                        <ng-container *ngIf="c.ID == a.subject">
                                            {{c.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{getDate(a.assignment_date)}}</td>
                                <td>{{getDate(a.submission_date)}}</td>
                                <td>
                                    <ng-container *ngIf="a.attachment">
                                        <a href="{{imgURL}}{{a.attachment}}" target="_blank">view document</a>
                                    </ng-container>
                                    <ng-container *ngIf="!a.attachment">-</ng-container>
                                </td>
                                <td class="align-left"><p>{{a.description}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openEditModal(a.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(a.ID, 'assignment', 'assignment')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!assignmentData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>