import { Component, OnInit } from '@angular/core';
import { Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ModulesService } from 'src/app/services/modules.service';
import { InstService } from 'src/app/services/inst.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-modules-access',
  templateUrl: 'modules-access.component.html',
  styleUrls: ['modules-access.component.css'],
})

export class modulesAccessComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public selectedInstID: any;
    public selectedModules: any[] = [];
    public selectedSubModules: any[] = [];
    public isMultiBranch: number;
    public isVernacular: number;
    public instData: any[] = [];

    public modulesData: any[] = [];
    public subModulesData: any[] = [];

    public modulesValue: any[] = [];
    public subModulesValue: any[] = [];

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _mService: ModulesService,
        private _adminService: AdminService,       
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        InstID: ['', Validators.required],
        Modules: this._fBuilder.array([], Validators.required),
        SubModules: this._fBuilder.array([], Validators.required)
    })

    ngOnInit(): void {

        this.selectedInstID = this._aRoute.snapshot.paramMap.get('instID');

        this._gService.showLoader(true);
        // alert(this.selectedInstID)


        this._adminService.getDataByModuleName('institutions').subscribe(instRes => {
            console.log(instRes);
            if (instRes.status === true) {
                this.instData = JSON.parse(instRes.result);
                if(this.selectedInstID) { 
                    let instdata = JSON.parse(instRes.result)
                    // let data = instdata.filter(d => d.ID == this.selectedInstID)
                    console.log(this.instData)
                    this.isMultiBranch = instdata.filter(d => d.ID == this.selectedInstID).map(d => d.isMultiBranch)
                    this.isVernacular = instdata.filter(d => d.ID == this.selectedInstID).map(d => d.isVernacular)
                    console.log(this.isMultiBranch)
                    console.log(this.isVernacular)
                }
            } else {
                console.log(instRes.msg);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(0, 0, 'modules').subscribe(mRes => {
            console.log(mRes);
            if (mRes.status === true) {
                console.log(this.isMultiBranch)
                let modulesData = JSON.parse(mRes.result);
                if (this.instData.length && this.isMultiBranch == 0) {
                    this.modulesData = modulesData.filter((m: any) =>  m.ID != 2)
                    console.log('1')
                } else {
                    console.log('2')
                    this.modulesData = modulesData
                }
                console.log(this.modulesData)

                this._gService.getAllDataByInstID(0, 0, 'sub_modules').subscribe(smRes => {
                    console.log(smRes);
                    if (smRes.status === true) {
                        let subModulesData = JSON.parse(smRes.result);
                        console.log(this.isVernacular == 0)
                        if (this.instData.length && this.isMultiBranch == 0) {
                            this.subModulesData = subModulesData.filter((m: any) =>  m.ID != 1 && m.ID != 2 && m.ID != 3)
                            console.log('1')
                        } else if (this.instData.length && this.isVernacular == 0) {
                            this.subModulesData = subModulesData.filter((m: any) => m.ID != 3)
                            console.log('2')
                        }
                         else {
                            console.log('3')
                            this.subModulesData = subModulesData
                        }
                        console.log(this.subModulesData)
                    } else {
                        this._gService.triggerSnackbar('No Data Found!', 'error', true);
                    }
                })
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        // this._gService.getAllDataByInstID(0, 0, 'sub_modules').subscribe(smRes => {
        //     console.log(smRes);
        //     if (smRes.status === true) {
        //         this.subModulesData = JSON.parse(smRes.result);
        //         console.log(this.subModulesData)
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        this.rObj.controls.InstID.valueChanges.subscribe(value => {
            // alert(value)
            this._reRoute.shouldReuseRoute = () => false;
            this._router.navigate(['/admin/modulesaccess', value]);
        });

        if (this.selectedInstID) {
            this._gService.getModulesAccessData(this.selectedInstID, 1).subscribe(maRes => {
                console.log(maRes)
                if (maRes.status === true) {
                    let maData = JSON.parse(maRes.result)[0];
                    let selectedModules = JSON.parse(maData.modules);
                    if (this.instData.length && this.isMultiBranch == 0 ) {
                        this.selectedModules = selectedModules.filter((m: any) =>  m != 2)
                    } else {
                        console.log('1')
                        this.selectedModules = selectedModules
                    }
                    console.log(selectedModules)
                    let selectedSubModules = JSON.parse(maData.sub_modules);
                    if (this.instData.length && this.isMultiBranch == 0) {
                        this.selectedSubModules = selectedSubModules.filter((m: any) =>  m != 1 && m != 2 )
                    } else if (this.instData.length &&  this.isVernacular == 0) {
                        this.selectedSubModules = selectedSubModules.filter((m: any) =>  m != 3)
                    }
                    else {
                        console.log('2')
                        this.selectedSubModules = selectedSubModules
                    }
                    console.log(selectedModules)
                    this.markModuleSelected(this.selectedModules)
                    this.markSubModuleSelected(this.selectedSubModules)
                } else {
                    this._gService.triggerSnackbar('Modules not assigned yet!', 'alert', true);
                }
            })
            this.rObj.patchValue({
                InstID: this.selectedInstID,
                // Modules: maData[0].modules,
                // SubModules: maData[0].sub_modules,
            }, { emitEvent: false });
        }

    }

    markModuleSelected(modules: any) {
        const selectedModules = <FormArray>this.rObj.controls.Modules;

        modules.forEach((module : any) => {
            selectedModules.push(new FormControl(module));
        });

        this.modulesValue = selectedModules.value;
    }

    markSubModuleSelected(sub_modules: any) {
        const selectedSubModules = <FormArray>this.rObj.controls.SubModules;

        sub_modules.forEach((sub_module : any) => {
            selectedSubModules.push(new FormControl(sub_module));
        });

        this.subModulesValue = selectedSubModules.value;
    }

    onModuleSelect(e: any) {
        const selectedModules = <FormArray>this.rObj.controls.Modules;

        selectedModules?.markAllAsTouched();

        if(e.target.checked) {
            selectedModules.push(new FormControl(e.target.value));
        } else {
          let index = selectedModules.controls.findIndex(x => x.value == e.target.value)
          selectedModules.removeAt(index);
        }

        this.modulesValue = selectedModules.value;

        console.log(selectedModules.value)
    }

    isActive(moduleID: string) {
        return this.modulesValue.includes(moduleID)
    }

    isChecked(moduleID: string, subModulesID: number) {
        let subModulesIDs = this.subModulesData.filter(smData => smData.parent == moduleID).map(smData => smData.ID)
        if (this.isActive(moduleID)) {
            // return subModulesIDs.includes(subModulesID)
        }
    }

    onSubModuleSelect(e: any) {
        const selectedSubModules = <FormArray>this.rObj.controls.SubModules;

        selectedSubModules?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSubModules.push(new FormControl(e.target.value));
        } else {
          let index = selectedSubModules.controls.findIndex(x => x.value == e.target.value)
          selectedSubModules.removeAt(index);
        }

        this.subModulesValue = selectedSubModules.value;

        console.log(selectedSubModules.value)
    }

    saveModulesAccess() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: rObj.InstID,
                user_role: 1,
                modules: JSON.stringify(this.modulesValue),
                sub_modules: JSON.stringify(this.subModulesValue),
            }
            console.log(mData)
            this._mService.saveModulesAccessData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Modules Access Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}