<!-- <div class="login-page container-fluid" style="width: calc(100% - 250px);float: right;padding: 20px 30px; min-height: 500%;
        transition: all 0.2s ease-out;">
    <div class="row" style="display: contents;">
        <div class="col-md-5 form-screen" style="width: 63%; margin-left: auto; margin-right: auto;">
            <div class="console-logo">
                <div class="logo"><img src="/assets/img/enp-logo.png"></div>
            </div>
            
           <h4 class="login-title">Login to Others Account</h4>
            <form name="login-form" style="text-align: center;">
                <div class="form-error" *ngIf="showError">Incorrect email address or password</div>
                <div class="form-group">
                    <input type="text" class="form-control login-input" [formControl]="Inst_url" [ngClass]=" Inst_url.valid ? 'has-val' : ''">
                    <span>Institute Url</span>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control login-input" [formControl]="Account_id" [ngClass]=" Account_id.valid ? 'has-val' : ''">
                    <span>Account Id</span>
                </div>
                <div class="form-group">
                    <input [type]="showPasswordText ? 'text' : 'password'" class="form-control login-input hide-password" [formControl]="Passcode" [ngClass]="Passcode.valid ? 'has-val' : ''">
                    <span>Passcode</span>
                    <i class="far show-hide-password-btn" [ngClass]="showPasswordText ? 'fa-eye-slash' : 'fa-eye'" (click)="showPassword()"></i>
                </div>
                 
                  <div class="form-group" style="text-align: center">
                      <input class="btn primary-btn" type="submit" value=" Log In " (click)="submit()" [disabled]="!Account_id.valid || !Passcode.valid">
                </div>
            </form>
        </div>
    </div>
</div> -->

<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Account Access of Users</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Medium</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Login Credentials
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label>Institute Url</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="Inst_url">
                                <div *ngIf="rObj.controls.Inst_url.invalid && (rObj.controls.Inst_url.dirty || rObj.controls.Inst_url.touched)">
                                    <p class="input-error-msg">Please enter Inst_url</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Account Id</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="Account_id">
                                <div *ngIf="rObj.controls.Account_id.invalid && (rObj.controls.Account_id.dirty || rObj.controls.Account_id.touched)">
                                    <p class="input-error-msg">Please enter user name</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Passcode</label>
                                <small class="req"> *</small>
                                <input type="email" class="form-control" formControlName="Passcode">
                                <div *ngIf="rObj.controls.Passcode.invalid && (rObj.controls.Passcode.dirty || rObj.controls.Passcode.touched)">
                                    <p class="input-error-msg">Please enter Passcode</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" target="_blank" (click)="submit()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
        
</div>