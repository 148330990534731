<div class="modal-header">
    <h5 class="modal-title">Edit Users</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
                <div class="form-group">
                    <label>Name</label>
                    <small class="req"> *</small>
                    <input type="text" class="form-control" formControlName="Name">
                    <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                        <p class="input-error-msg">Please enter name</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>User Name</label>
                    <small class="req"> *</small>
                    <input type="text" class="form-control" formControlName="UserName">
                    <div *ngIf="rObj.controls.UserName.invalid && (rObj.controls.UserName.dirty || rObj.controls.UserName.touched)">
                        <p class="input-error-msg">Please enter user name</p>
                    </div>
                </div>
            
                <div class="form-group">
                    <label>Email</label>
                    <small class="req"> *</small>
                    <input type="email" class="form-control" formControlName="Email">
                    <div *ngIf="rObj.controls.Email.invalid && (rObj.controls.Email.dirty || rObj.controls.Email.touched)">
                        <p class="input-error-msg">Please enter email</p>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label>Password</label>
                    <small class="req"> *</small>
                    <input type="password" class="form-control" formControlName="Password">
                    <div *ngIf="rObj.controls.Password.invalid && (rObj.controls.Password.dirty || rObj.controls.Password.touched)">
                        <p class="input-error-msg">Please enter password</p>
                    </div>
                </div> -->
    
                <div class="form-group">
                    <label>User Role</label>
                    <small class="req"> *</small>
                    <select class="form-select" formControlName="UserRole">
                        <option *ngFor="let role of UserRoleData" value="{{role.ID}}">{{role.name}}</option>
                    </select>
                    <div *ngIf="rObj.controls.UserRole.invalid && (rObj.controls.UserRole.dirty || rObj.controls.UserRole.touched)">
                        <p class="input-error-msg">Please select userRole</p>
                    </div>
                </div>
                <div class="form-group form-btn-wrapper">
                    <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateUsers()">Update</button>
                </div>
            
        </form>
    </div>
</div>
