<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Users</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Medium</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Users
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label>Name</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="Name">
                                <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                                    <p class="input-error-msg">Please enter name</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>User Name</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="UserName">
                                <div *ngIf="rObj.controls.UserName.invalid && (rObj.controls.UserName.dirty || rObj.controls.UserName.touched)">
                                    <p class="input-error-msg">Please enter user name</p>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label>Email</label>
                                <small class="req"> *</small>
                                <input type="email" class="form-control" formControlName="Email">
                                <div *ngIf="rObj.controls.Email.invalid && (rObj.controls.Email.dirty || rObj.controls.Email.touched)">
                                    <p class="input-error-msg">Please enter email</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Password</label>
                                <small class="req"> *</small>
                                <input type="password" class="form-control" formControlName="Password">
                                <div *ngIf="rObj.controls.Password.invalid && (rObj.controls.Password.dirty || rObj.controls.Password.touched)">
                                    <p class="input-error-msg">Please enter password</p>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label>User Role</label>
                                <small class="req"> *</small>
                                <select class="form-select" formControlName="UserRole">
                                    <option *ngFor="let role of UserRoleData" value="{{role.ID}}">{{role.name}}</option>
                                </select>
                                <div *ngIf="rObj.controls.UserRole.invalid && (rObj.controls.UserRole.dirty || rObj.controls.UserRole.touched)">
                                    <p class="input-error-msg">Please select userRole</p>
                                </div>
                            </div>
                            <!-- <div class="col-md-6 form-btn-wrapper" style="margin-top: 22px;">
                                    <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveMedium()">Save</button>
                                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
                            </div> -->
                        </div>
                        <!-- <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
                        </div> -->
                        <div class="form-group btn-container" style="text-align: right;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
        
</div>