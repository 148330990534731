<div class="console-content">  
    <div class="row">     
        <div class="col-md-3">
            <div class="panel" >
                <div class="view-meta student-meta" *ngFor="let student of studentData">
                    <div class="student-photo">
                        <img class="photo-preview" [src]="student.photo_path ? imgURL+student.photo_path : student.gender == 'male' ? 'assets/img/male-student.png' : 'assets/img/female-student.png'">
                    </div>
                    <ng-container >
                    <h3 class="name">{{student.full_name}}</h3>
                        <div class="view-info row">
                            <div class="col-md-6 title">Registeration No.</div>
                            <div class="col-md-6 info">{{student.admission_number}}</div>
                            <div class="col-md-6 title">Roll No.</div>
                            <div class="col-md-6 info">{{student.roll_no}}</div>
                            <div class="col-md-6 title">Class</div>
                            <div class="col-md-6 info">{{student.class_name}}</div>
                            <div class="col-md-6 title">Section</div>
                            <div class="col-md-6 info">{{student.section_name}}</div>
                            <div class="col-md-6 title">Gender</div>
                            <div class="col-md-6 info">{{student.gender}}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Siblings Details:
                    </div>
                </div>
                 <div class="panel-body">
                    <ng-container *ngIf="siblingIds.length">
                        <ng-container *ngFor="let students of allStudentsData">
                            <ng-container *ngIf="siblingIds.includes(students.ID)">
                                <div class="sibling" (click)="gotoPage(students.ID)" style="cursor: pointer;">
                                    <img [src]="students.photo_path ? imgURL+students.photo_path : '/assets/img/default-user-avatar.png'" >
                                    <!-- <img src="{{imgURL+students.photo_path}}"> -->
                                    <div class="detail">
                                        <h6>{{students.full_name}}</h6>
                                        <h6>{{students.class_name}}/({{students.section_name}})</h6>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <div *ngIf="!siblingIds.length">
                        <!-- <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png" width="350px">
                        </div> -->
                        <p style="text-align: center;">Siblings Data Not Found</p>
                    </div>
                 </div>
            </div>
        </div>
        
        <div class="col-md-9">
            <div class="console-content-navbar">
                <ul class="content-links">
                    <li class="content-link " [ngClass]="profileTab ? 'active' : ''" (click)="setTabActive('profileTab')">
                        <a id="personal">
                            <i class="fas fa-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="documentTab ? 'active' : ''" (click)="setTabActive('documentTab')">
                        <a id="documents">
                            <i class="fas fa-file-alt"></i>
                            <span>Documents</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="feesTab ? 'active' : ''" (click)="setTabActive('feesTab')">
                        <a id="fees">
                            <i class="far fa-money-bill-alt"></i>
                            <span>Fees</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="othersTab ? 'active' : ''" (click)="setTabActive('othersTab')">
                        <a id="fees">
                            <i class="far fa-money-bill-alt"></i>
                            <span>Others</span>
                        </a>
                    </li>                  
                    <li class="content-link" [ngClass]="attendanceTab ? 'active' : ''" (click)="setTabActive('attendanceTab')">
                        <a id="attendance">
                            <i class="fas fa-stream"></i>
                            <span>Attendance Details</span>
                        </a>
                    </li>
                    <!-- <a href="/student/edit/:id"><button class="btn extra-small-btn inline float-right ">Edit</button></a> -->
                    <a href="/../student/edit/{{viewId}}">
                        <button class="btn default-btn extra-small-btn inline float-right ">Edit</button>
                    </a>
                </ul>

            </div>
            
            <div class="console-content-tab">
                <div id="personal" class="panel panel-margin-top " [ngClass]="profileTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Personal Details:
                        </div>
                    </div>
                    <div class="panel-body"  *ngFor="let student of studentData">
                        <div class="row details" >
                            <div class="col-md-3 ">First Name</div>
                            <div class="col-md-3 head">{{student.first_name}}</div> 
                            <div class="col-md-3 ">Middle Name</div>
                            <div class="col-md-3 head">{{student.middle_name}}</div> 
                        </div> 
                        <div class="row details" >
                            <div class="col-md-3 ">Last Name</div>
                            <div class="col-md-3 head">{{student.last_name}}</div> 
                            <div class="col-md-3 ">Date of Birth</div>
                            <div class="col-md-3 head">{{student.dob}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Gender</div>
                            <div class="col-md-3 head">{{student.gender}}</div> 
                            <div class="col-md-3 ">Blood Group</div>
                            <div class="col-md-3 head">{{student.blood_group}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Mobile Number</div>
                            <div class="col-md-3 head">{{student.mobile}}</div> 
                            <div class="col-md-3 ">Email Id</div>
                            <div class="col-md-3 head">{{student.email}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Aadhar Number</div>
                            <div class="col-md-3 head">{{student.aadhaar_number}}</div> 
                            <div class="col-md-3 ">Birth Place</div>
                            <div class="col-md-3 head">{{student.birth_place}}</div>
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Nationality</div>
                            <div class="col-md-3 head">{{student.nationality}}</div> 
                            <div class="col-md-3 ">Mother Tongue</div>
                            <div class="col-md-3 head">{{student.mother_tongue}}</div>  
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Category</div>
                            <div class="col-md-3 head">{{student.category}}</div> 
                            <div class="col-md-3 ">Religion</div>
                            <div class="col-md-3 head">{{student.religion}}</div>
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Caste</div>
                            <div class="col-md-3 head">{{student.caste}}</div>   
                        </div> 
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Address Details:
                        </div>
                    </div>
                    <div class="panel-body"  *ngFor="let student of studentData">
                        <div class="row details">
                            <div class="col-md-3 ">Current Address</div>
                            <div class="col-md-9 head">{{student.current_address}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Permanent Address</div>
                            <div class="col-md-9 head">{{student.permanent_address}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">City</div>
                            <div class="col-md-3 head">{{student.city}}</div> 
                            <div class="col-md-3 ">Pincode</div>
                            <div class="col-md-3 head">{{student.pincode}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">State</div>
                            <div class="col-md-3 head">{{student.state}}</div> 
                            <div class="col-md-3 ">Country</div>
                            <div class="col-md-3 head">{{student.country}}</div>
                        </div> 
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Parents Details:
                        </div>
                    </div>
                    <div class="panel-body"  *ngFor="let student of studentData" >
                        <div class="row details">
                            <div class="col-md-3 ">Father Name</div>
                            <div class="col-md-3 head">{{student.father_name}}</div> 
                            <div class="col-md-3 ">Father Occupation</div>
                            <div class="col-md-3 head">{{student.father_occupation}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Father Mobile Number</div>
                            <div class="col-md-3 head">{{student.father_mobile}}</div> 
                            <div class="col-md-3 ">Mother Name</div>
                            <div class="col-md-3 head">{{student.mother_name}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Mother Occupation</div>
                            <div class="col-md-3 head">{{student.mother_occupation}}</div> 
                            <div class="col-md-3 ">Mother Mobile Number</div>
                            <div class="col-md-3 head">{{student.mother_mobile}}</div>
                        </div> 
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Guardian Details:
                        </div>
                    </div>
                    <div class="panel-body"  *ngFor="let student of studentData" >
                        <div class="row details">
                            <div class="col-md-3 ">Guardian Name</div>
                            <div class="col-md-3 head">{{student.guardian_name}}</div> 
                            <div class="col-md-3 ">Guardian Relation</div>
                            <div class="col-md-3 head">{{student.guardian_relation}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Guardian Mobile</div>
                            <div class="col-md-3 head">{{student.guardian_mobile}}</div> 
                            <div class="col-md-3 ">Guardian Email</div>
                            <div class="col-md-3 head">{{student.guardian_email}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Guardian Occupation</div>
                            <div class="col-md-3 head">{{student.guardian_occupation}}</div> 
                            <div class="col-md-3 ">Guardian Education</div>
                            <div class="col-md-3 head">{{student.guardian_education}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Guardian Address</div>
                            <div class="col-md-9 head">{{student.guardian_address}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Guardian Income</div>
                            <div class="col-md-3 head">{{student.guardian_income}}</div> 
                        </div>
                    </div> 
                </div>
                <div id="documents" class="panel panel-margin-top" [ngClass]="documentTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Document
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row details" *ngFor="let doc of documentData">
                            <div class="col-md-3 title">{{doc.name}}</div>
                            <div class="col-md-3 info">
                                <a href="{{imgURL}}{{doc.path}}" target="_blank" *ngIf="doc.path">View Document</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="fees" class="panel panel-margin-top" [ngClass]="feesTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Fees:
                        </div>
                    </div>
                    <div class="panel-body">
                        <table class="list" *ngIf="feesMasterData.length">
                            <thead>
                                <tr>
                                    <th>Fees Group</th>
                                    <th>Fees Type</th>
                                    <th>Amount</th>
                                    <th>Fine Amount</th>
                                    <th>Fine Type</th>    
                                    <th>Due Date</th>
                                    <th>Total Amount</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let f of feesMasterData">
                                    <tr>
                                        <td>{{f.fees_group_name}} </td>
                                    <td class="align-left">
                                        <ng-container *ngFor="let type_name of stringToArray(f.fees_type_names); let i = index">
                                            <ng-container *ngFor="let amt of stringToArray(f.amounts); let j = index">
                                                <ng-container *ngIf="i == j">
                                                 {{type_name}}- {{amt}}<br>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{f.total_amount}}</td>
                                    <td>{{isDue ? f.fine_type : '-'}}</td>
                                    <td>{{isDue ? (f.fine_type == 'percentage' ? (f.total_amount * f.percentage) / 100 : f.fine_amount) : '-'}}</td>
                                    <td>{{getDate(f.due_date)}}</td>
                                    <!-- <td>{{isDue ? totalAmount(f.total_amount, f.fine_type == 'percentage' ? f.percentage : f.fine_amount) : f.total_amount}}</td>    -->
                                    <td>{{getTotalAmount(f.total_amount,f.fine_type,f.fine_amount,f.percentage)}}</td>   
                                    <td>{{getTxnId(f.ID).length ? getTxnId(f.ID) : '-'}}</td> 
                                    <td>{{getTxnId(f.ID).length ? 'Paid' : 'Pending'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>   
                    </div>  
                     
                </div>
                <div id="others" class="panel panel-margin-top" [ngClass]="othersTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Transport Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row details"  *ngFor="let student of studentData">
                            <div class="col-md-3 ">Route Name</div>
                            <div class="col-md-3 head">{{student.route_name}}</div> 
                            <div class="col-md-3 ">Pickup Point Name</div>
                            <div class="col-md-3 head">{{student.pickup_point_name}}</div> 
                        </div> 
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Hostel Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row details"  *ngFor="let student of studentData">
                            <div class="col-md-3 ">Hostel Name</div>
                            <div class="col-md-3 head">{{student.hostel_name}}</div> 
                            <div class="col-md-3 ">Hostel Room Name</div>
                            <div class="col-md-3 head">{{student.hostel_room_name}}</div> 
                        </div>
                    </div>    
                </div>
                <div id="attendance" class="panel panel-margin-top" [ngClass]="attendanceTab ? 'active' : ''">
                    <div class="row panel-header">
                        <div class="panel-title col-md-6">
                            Attendance Details:
                        </div>
                        <div class="panel-title col-md-6">
                            <form [formGroup]="rObj">
                                <div class="row options" style="justify-content: end;">
                                    <div class="col-md-4">
                                        <select formControlName="Month" class="form-select" (change)="OnMonthChange($event)" style="font-size: 13px;padding: 8px 10px;line-height: 1;">
                                            <option value="">MONTH</option>
                                            <ng-container *ngFor="let month of monthData"> 
                                                <option [value]="month">{{getMonthName(month)}}</option>
                                            </ng-container>
                                        </select>
                                    </div>                                
                                    <div class="col-md-4">
                                        <select name="section" formControlName="Year" class="form-select" (change)="OnYearChange($event)" style="font-size: 13px;padding: 8px 10px;line-height: 1;">
                                            <option value="">YEAR</option>
                                            <ng-container *ngFor="let a of academicYearsData">
                                                <option [value]="a.start_year" selected>{{a.start_year}}</option>
                                                <option [value]="a.end_year">{{a.end_year}}</option>
                                            </ng-container>
                                        </select>    
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="panel-body" *ngFor="let a of studentAttendance">
                        <div class="row attendancedetails">
                            <div class="col-md-3">
                                <div class="data">
                                    <div class="">Present Count</div>
                                    <div class="count">{{a.present_count ? a.present_count : '0'}}</div> 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="data">
                                    <div class="">Absent Count</div>
                                    <div class="count">{{a.absent_count ? a.absent_count : '0'}}</div>
                                </div> 
                            </div>
                            <div class="col-md-3">
                                <div class="data">
                                    <div class="">Halfday Count</div>
                                    <div class="count">{{a.halfday_count ? a.halfday_count : '0'}}</div>
                                </div> 
                            </div>
                            <div class="col-md-3">
                                <div class="data">
                                    <div class="">Late Count</div>
                                    <div class="count">{{a.late_count ? a.late_count : '0'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!studentAttendance.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png" width="350px">
                        </div>
                        <p style="text-align: center;padding-bottom: 14px;">Data Not Found</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>