import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import $ from 'jquery';
import { BranchModalComponent } from '../../modal/branchmodal/branchmodal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit, AfterViewInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userRole = this.userData.UserRole;
    public isMultiBranch = this.userData.isMultiBranch;
    public instID = this.userData.InstID;
    public instLogo = '';
    public instName = '';
    public instBranch = '';

    public current_url = this._router.url;

    public modulesData: any[] = [];
    public subModulesData: any[] = [];
    public modulesAccessData: any[] = [];
    public subModulesAccessData: any[] = [];

    modalRef: BsModalRef;

    @Input() sidebarStatus: any;
    @Output() hoverSidebar = new EventEmitter();

    public isSelected: any = '';

    constructor(
        private _gService: GlobalService,
        private _router: Router,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(0, 0, 'modules').subscribe(mRes => {
            console.log(mRes);
            if (mRes.status === true) {
                this.modulesData = JSON.parse(mRes.result);
                console.log(this.modulesData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(0, 0, 'sub_modules').subscribe(smRes => {
            console.log(smRes);
            if (smRes.status === true) {
                this.subModulesData = JSON.parse(smRes.result);
                console.log(this.subModulesData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getModulesAccessData(this.instID, this.userRole).subscribe(maRes => {
            console.log(maRes);
            if (maRes.status === true) {
                let maData = JSON.parse(maRes.result)[0];
                this.modulesAccessData = JSON.parse(maData.modules);
                this.subModulesAccessData = JSON.parse(maData.sub_modules);
                // console.log(this.modulesData)
                console.log(this.modulesAccessData)
                console.log(this.subModulesAccessData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        
        this._gService.getDataByID(this.instID, 'institutions').subscribe(instRes => {
            console.log(instRes);
            let instData = JSON.parse(instRes.result)[0];
            console.log(instData)
            this.instLogo = instData.icon_path ? environment.IMGURL+instData.icon_path : '';
            this.instName = instData.inst_name;
            this.instBranch = instData.inst_branch;
        })

        function getUrlParameter(sParam: string) {
            var sPageURL = window.location.search.substring(1);
            var sURLVariables = sPageURL.split('&');
            for (var i = 0; i < sURLVariables.length; i++) {
                var sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] == sParam) {
                    return sParameterName[1];
                }
            }
        }

        // var curr_path = window.location.pathname;

        // $(document).find('.nav-links li').each(function() {
        //     var href = $(this).find('a').attr('href');
        //     var name = $(this).find('a').attr('data-name');
        //     if (curr_path == href || curr_path.indexOf('/' + name + '/') > -1 || (curr_path.indexOf('content') > -1 && getUrlParameter('type') == name)) {
        //         var parentClass = $(this).parent('ul').attr('class');
        //         if (parentClass == 'sub-nav') {
        //             $(this).addClass('active');
        //             $(this).parents('.nav-links li').addClass('active');
        //         } else {
        //             $(this).addClass('active');
        //         }
        //     }
        // });

        // var y = $(document).find('.sub-nav li.active').parents('.nav-link.has-sub-nav').html();
        // alert(y);
        
        // $(document).on('click', '.nav-links li.has-sub-nav', function() {
        //     if ($(this).hasClass('hovered')) {
        //         $(this).removeClass('hovered');
        //         $(this).children('ul.sub-nav').hide();
        //     } else {
        //         $('.nav-links li').removeClass('hovered');
        //         $('.nav-links li').children('ul.sub-nav').hide();
        //         $(this).addClass('hovered');
        //         $(this).children('ul.sub-nav').show();
        //     }
        // });

        // setTimeout(() => {
        //     alert(document.querySelector('.nav-link.active')?.innerHTML)
        //     document.querySelector('.nav-link.active')?.scrollIntoView({ behavior: 'smooth', block: 'center'});
        // }, 500)

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            document.querySelector('.nav-link.active')?.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }, 1000)
    }

    selectMenu(menuID: number) {
        this.isSelected = (this.isSelected === menuID ? null : menuID); 
    }

    isActive(menuID: number) {
        return this.isSelected === menuID
    }

    expandSidebar() {
        this.hoverSidebar.emit(false);
    }

    openBranchModal() {
        this.modalRef = this.modalService.show(BranchModalComponent, {
            class: 'modal-dialog-top branch-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                activeBranchID: this.instID,
            } 
        });
    }
}