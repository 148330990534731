<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-8">Id Cards Templates</h1>
        <div class="link-container col-md-4">
            <a href="/id/generate-id" class="content-header-link">Generate Id Card</a>
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-12" *ngIf="!templateID">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Select Template
                    </div>
                </div>
                <div class="panel-body" >
                    <div class="template-wrapper row"  >
                        <div class="col-md-3" *ngFor="let template of idCardTemplateData">
                            <img class="template-preview" src="{{template.preview_path}}" (click)="selectImage(template.ID)" >
                            <h5 class="template-preview-name">{{template.name}}</h5>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
        <div class="col-md-4" *ngIf="templateID">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Add Template
                    </div>
                </div>
                <div class="panel-body" >
                    <form [formGroup]="rObj">

                        <div class="form-group">
                            <label>Template Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Template_Name" class="form-control" >    
                            <div *ngIf="rObj.controls.Template_Name.invalid && (rObj.controls.Template_Name.dirty || rObj.controls.Template_Name.touched)">
                                <p class="input-error-msg">Please enter Template name</p>
                            </div>                          
                        </div>

                        <div class="form-group">
                            <label>School Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="checkbox" formControlName="School_Name" class="radio-inline" style="margin-left: 10px;">   
                            <ng-container *ngIf="checked">
                                <input type="text" formControlName="School_Name_Text" class="form-control">
                            </ng-container>    
                            <div *ngIf="rObj.controls.School_Name.invalid && (rObj.controls.School_Name.dirty || rObj.controls.School_Name.touched)">
                                <p class="input-error-msg">Please enter School Name</p>
                            </div>                          
                        </div>

                        <div class="form-group">
                            <label>School Logo</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                                <input type="file" formControlName="logo" class="form-control" (change)="onImgChange($event)" >
                            </div>
                            <div *ngIf="rObj.controls.logo.invalid && (rObj.controls.logo.dirty || rObj.controls.logo.touched)">
                                <p class="input-error-msg">Please select logo</p>
                            </div>                          
                        </div>
                       
                        <div class="form-group">
                            <label>School Address</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="checkbox" formControlName="School_address" class="radio-inline" style="margin-left: 10px;">    
                            <ng-container *ngIf="checked1">
                                <textarea formControlName="School_address_Text" class="form-control" >Address:</textarea>
                            </ng-container>   
                            <div *ngIf="rObj.controls.School_address.invalid && (rObj.controls.School_address.dirty || rObj.controls.School_address.touched)">
                                <p class="input-error-msg">Please enter Address</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>School Phone</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="checkbox" formControlName="School_phone" class="radio-inline" style="margin-left: 10px;"> 
                            <ng-container *ngIf="checked2">
                                <input type="text" formControlName="School_phone_Text" class="form-control">
                            </ng-container>     
                            <div *ngIf="rObj.controls.School_phone.invalid && (rObj.controls.School_phone.dirty || rObj.controls.School_phone.touched)">
                                <p class="input-error-msg">Please enter phone</p>
                            </div>                          
                        </div>

                        <div class="form-group">
                            <label>School Email</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="checkbox" formControlName="School_email" class="radio-inline" style="margin-left: 10px;"> 
                            <ng-container *ngIf="checked3">
                                <input type="email" formControlName="School_email_Text" class="form-control">
                            </ng-container>     
                            <div *ngIf="rObj.controls.School_phone.invalid && (rObj.controls.School_phone.dirty || rObj.controls.School_phone.touched)">
                                <p class="input-error-msg">Please enter email</p>
                            </div>                          
                        </div>

                        <div class="form-group">
                            <label>Background</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeBgImg()" *ngIf="bgimageSrc">Remove Image</div>  
                                <input type="file" formControlName="Background" class="form-control" (change)="onBgChange($event)" >
                            </div>
                            <div *ngIf="rObj.controls.Background.invalid && (rObj.controls.Background.dirty || rObj.controls.Background.touched)">
                                <p class="input-error-msg">Please select Background</p>
                            </div>                          
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveTemplate()">Save</button>
                        </div>
                                
                    </form>
                </div>           
            </div>
        </div>

        <div class="col-md-8" *ngIf="templateID">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Preview
                    </div>
                </div>
                <div class="panel-body">
                    <div class="template-priview-wrapper row">
                        <!-- <img class="template-preview" src="{{template.preview_path}}"> -->
                        <div class="col-md-12">
                            <!-- <div id="bg-logo"></div> -->
                            <div id="htmlPreview" [innerHTML]="templateHtml"> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>