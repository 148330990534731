<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-2">Task List</h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-5">
            <a href="task-management/add/" class="content-header-link">Assign Task</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="taskData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('staff', 'staff')">Delete</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="taskData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
		                        <th class="align-left">Task</th>
			                    <th>Task Date</th>
			                    <th>Due Date</th>
			                    <th>User Type </th>
                                <th class="align-left">Department</th>
                                <th class="align-left">Designation</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Assignee Count</th>
		                        <th>Status</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of taskData">
                                <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="t.isSelected" (change)="onChecked(t.ID, $event)">
                                </td>
                                <td class="align-left">{{t.task}}</td>
                                <td>{{getDate(t.task_date)}}</td>
                                <td>{{getDate(t.due_date)}}</td>
                                <td>{{t.user_type}}</td>
                                <td class="align-left"> {{getdtId(t.department).length ? getdtId(t.department) : '-'}} </td>
                                <td class="align-left"> {{getdsId(t.designation).length ? getdsId(t.designation) : '-'}}</td>
                                <td> {{getcId(t.class).length ? getcId(t.class) : '-'}}</td>
                                <td> {{getsId(t.section).length ? getsId(t.section) : '-'}}</td>
                                <td>
                                  {{getUserCount(t.user_id) == getAllUsersCount(t.class,t.section) ? 'All' : getUserCount(t.user_id)}} 
                                </td>
                                <td>{{t.status}}</td>

                                <td class="action-btn">
                                    <!-- <a tooltip="View" class="edit-btn" (click)="gotoViewLink(t.ID)" ><i class="far fa-eye"></i></a> -->
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(t.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(t.ID, 'task', 'delete task')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!taskData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>      
            </div>
        </div>
    </div>
</div>