import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';
import { AdminService } from 'src/app/services/admin.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveModalComponent } from 'src/app/components/modal/activemodal/activemodal.component';
@Component({
  selector: 'app-institution-edit',
  templateUrl: './edit.component.html'
})

export class institutionEditComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;

    public instID: any = 0;
    public instData = [];
    public instAdminData = [];

    dtOptions: DataTables.Settings = {};
    modalRef: BsModalRef;
    imageSrc: any = '';
    status: boolean = false;
    public total:number = 0;

    removeImgStatus: boolean = false;

    public imageURL = 'https://apiuat.edunationpro.com/uploads/';

    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _adminService: AdminService,
        private modalService: BsModalService,
        private _router: Router,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Address: ['', Validators.required],
        Branch: [''],
        Pincode: ['', [Validators.required]],
        Country: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email ]],//Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        Phone: ['', [Validators.required, Validators.pattern(/^(6|7|8|9)(\d{9})$/)]],
        Fax: [''],
        Is_Vernacular: [''], 
        Is_Multi_branch: [''], 
        Currency:[{value: 'INR', disabled: true}],
        Language:[{value: 'English', disabled: true}],
        Start_date: [''], 
        Renewal_date: [''], 
        Update_date: [''], 
        Secret_Key: [{value: this.secretKeyGenerater(48), disabled: true}], 
        Subscription_period: [''], 
        Allowed_users: [''], 
        Subscription_Cost: [''], 
        Total_cost: [{value: 0,disabled: true}], 
        Status: [''], 
        First_name: [{value:'', disabled: true}], 
        Last_name: [{value:'', disabled: true}], 
        Admin_Email: [{value:'', disabled: true}],  
        Username: [{value:'', disabled: true}],  
        Password: [{value:'', disabled: true}],  
        Url: [{value:'', disabled: true}],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this.instID = this._aRoute.snapshot.paramMap.get('instId');
        
        this._gService.getDataByID(this.instID, 'institutions').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let instData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Name: instData[0].inst_name,
                    Address: instData[0].inst_address,
                    Branch: instData[0].inst_branch,
                    Pincode: instData[0].inst_pincode,
                    Country: instData[0].inst_country,
                    Email: instData[0].inst_email,
                    Phone: instData[0].inst_phone,
                    Fax: instData[0].inst_fax,
                    Is_Vernacular: instData[0].isVernacular,
                    Is_Multi_branch: instData[0].isMultiBranch,
                    Currency: instData[0].inst_currency,
                    Language: instData[0].inst_language,
                    Start_date: instData[0].start_date, 
                    Renewal_date: instData[0].renewal_date, 
                    Update_date: instData[0].update_date, 
                    Secret_Key: instData[0].inst_key, 
                    Subscription_period: instData[0].subscription_period, 
                    Allowed_users: instData[0].allowed_users, 
                    Subscription_Cost: instData[0].per_user_cost, 
                    Total_cost: instData[0].total_cost,
                    Status: instData[0].status,
                    Url: instData[0].inst_slug+'.edunationpro.com'
                });
                this.imageSrc = instData[0].icon_path ? this.imageURL+instData[0].icon_path : '';

                this._adminService.getInstAdminData(this.instID).subscribe(res =>{
                    console.log(res)
                    if (res.status == true) {
                        this.instAdminData = JSON.parse(res.result)
                        console.log(this.instAdminData)
                        let name = this.instAdminData[0].name.split(' ')
                        this.rObj.patchValue({
                            First_name: name[0],
                            Last_name: name[1],
                            Admin_Email: this.instAdminData[0].email,
                            Username: this.instAdminData[0].username,
                            Password: this.instAdminData[0].passwordString
                        })
                    }
                })
            }
            this._gService.showLoader(false);
        })
    }
    getValue(allowedUsers: any, cost: any) {
        this.total = allowedUsers * cost
        console.log(this.total)
        return this.total
    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
        this.removeImgStatus = true;
    }

    copyInputMessage(inputElement: any){
        if(navigator.clipboard.writeText(inputElement)) {
            this._gService.triggerSnackbar('Copied', 'success', true)
        } 
     }

    secretKeyGenerater(length: number) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; 
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    openModal(instId: any) {
        this.modalRef = this.modalService.show(ActiveModalComponent, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                instId: instId
            } 
        });
    }

    updateInst() {
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            console.log(this.rObj.controls.Secret_Key.value)
            let instData = {
                action: 'update',
                inst_id: this.instID,
                inst_name: rObj.Name,
                isMultiBranch: rObj.Is_Multi_branch,
                isVernacular: rObj.Is_Vernacular,
                inst_address: rObj.Address,
                inst_branch: rObj.Branch,
                inst_pincode: rObj.Pincode,
                inst_country: rObj.Country,
                inst_email: rObj.Email,
                inst_phone: rObj.Phone,
                inst_fax: rObj.Fax,
                inst_currency: "INR",
                inst_language: "English",
                inst_key: this.rObj.controls.Secret_Key.value,
                start_date: rObj.Start_date,
                renewal_date: rObj.Renewal_date,
                update_date:rObj. Update_date,
                subscription_period: rObj.Subscription_period,
                allowed_users: rObj.Allowed_users,
                per_user_cost: rObj.Subscription_Cost,
                total_cost: this.total,
                icon_path: this.status ? this.imageSrc : '',
                remove_photo:this.removeImgStatus,
            }
            // let instData = {
            //     inst_id: this.instID,
            //     inst_name: rObj.Name,
            //     inst_address: rObj.Address,
            //     inst_branch: rObj.Branch,
            //     inst_pincode: rObj.Pincode,
            //     inst_country: rObj.Country,
            //     inst_email: rObj.Email,
            //     inst_phone: rObj.Phone,
            //     inst_fax: rObj.Fax,
            //     inst_code: rObj.Code,
            //     inst_currency: "INR",
            //     inst_language: "English",
            //     icon_path: this.status ? this.imageSrc : '',
            //     remove_icon: this.removeImgStatus,
            // }
            console.log(instData);
            this._adminService.addInstData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._router.navigate(['/admin/institution/list']);
                    this._gService.triggerSnackbar('Institution Updated Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
}