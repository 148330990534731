import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
  
@Component({
    selector: 'app-changeUserPasswordmodal',
    templateUrl: './changeUserPasswordmodal.component.html',
    // styleUrls: ['./changePasswordmodal.component.css'],
})

export class ChangeUserPasswordModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    // public userID = this.userData.UserID;
    public userid : number

    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Password: ['', Validators.required],
        Confirm_Password: ['', Validators.required],
    })

    ngOnInit(): void {
        // this._gService.showLoader(true);

        // this._gService.getDataByID(this.mediumID, 'medium').subscribe(dataRes => {
            console.log(this.userid);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    updatePassword() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                user_id: this.userid,
                password: rObj.Confirm_Password,
            }
            this._adminService.changeAdminPassword(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        // this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate(['admin/dashboard'])
                        this._gService.triggerSnackbar('Password Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}