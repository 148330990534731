import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { FeesService } from 'src/app/services/fees.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router,RouteReuseStrategy,ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/report.service';
import * as moment from 'moment';

@Component({
  selector: 'app-student-viewStudent',
  templateUrl: './viewStudent.component.html',
  styleUrls: ['./viewStudent.component.css']
})

export class ViewStudentComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public allStudentsData: any[] = [];
    public studentData: any[] = [];
    public documentData: any[] = [];
    public statusData: any[] = [];
    public feesData: any[] = [];
    public academicYearsData: any[] = [];
    public yearData: any[] = [];
    public feeTypeData: any[] = [];
    public studentAttendance: any[] = [];
    public feesMasterData: any[] = [];
    
    public studentId: any;
    public isDue:boolean = false;

    public imgURL = this._gService.getImgPath();

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public siblingsInfo : any[] = [];
    public monthData : any[] = [];
    public siblingIds :any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    doc1Src: any = '';
    doc2Src: any = '';
    doc3Src: any = '';
    doc4Src: any = '';
    dtOptions: any = {};

    viewId :any;
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];

    public profileTab: boolean = true;
    public documentTab: boolean = false;
    public feesTab: boolean = false;
    public othersTab: boolean = false;
    public attendanceTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fService: FeesService,
        private _fBuilder: FormBuilder,
        private _rService: ReportService,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private _router: Router,

    ) {}

    public rObj = this._fBuilder.group({
        Month: [''],
        Year: [''],
    })
    

    ngOnInit(): void {
        this.viewId = this._aRoute.snapshot.paramMap.get('id');

        // this.rObj.controls.Year.value = this.academicYearsData[0].start_year

        this._gService.getAcademicYearsDataByInstID(this.instID).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                let data = JSON.parse(ayRes.result);
                this.academicYearsData =  data.filter((a : any) => a.ID == this.activeAcademicID)
                console.log(this.academicYearsData) 
                let startMonthDate = new Date(this.academicYearsData[0].start_year+'-'+this.academicYearsData[0].start_month+'-01')
                let endMonthDate = new Date(this.academicYearsData[0].end_year+'-'+this.academicYearsData[0].end_month+'-01')
                console.log(startMonthDate)        
                console.log(endMonthDate)        
                this.monthData = this.monthsBetween(startMonthDate, endMonthDate)
                console.log(this.monthData)        
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._fService.getStatusData(this.viewId).subscribe( res => {
            console.log(res)
            if(res.status === true){
                this.statusData = JSON.parse(res.result);
            }
            console.log(this.statusData)
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.feeTypeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._aService.getStudentDocumentData(this.viewId).subscribe( res => {
            console.log(res)
            if(res.status === true) {
                this.documentData = JSON.parse(res.result);
            }
        })

        this._gService.getDataByID(this.viewId, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
                console.log(this.studentData)
                console.log(this.studentData[0].section)
                console.log(this.studentData[0].class)
                this.siblingIds = this.studentData[0].sibling_ids ? this.studentData[0].sibling_ids.split(',') : []
                console.log(this.siblingIds)
                this._fService.getFeesMasterData(this.instID, this.activeAcademicID,this.studentData[0].class, this.studentData[0].section).subscribe(cRes => {
                    console.log(cRes)
                    if (cRes.status === true) {
                        this.feesMasterData = JSON.parse(cRes.result);
                        // this.feesMasterData =  this.feesMasterData.filter(r => r.class == this.studentData[0].class && r.section == this.studentData[0].section)
                        console.log(this.feesMasterData)
                    } else {
                        // this._gService.triggerSnackbar('No Data Found!', 'error', true);
                    }
                })
                // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_master').subscribe(cRes => {
                //     console.log(cRes)
                //     if (cRes.status === true) {
                //         this.feesData = JSON.parse(cRes.result);
                //         // console.log(this.feesData)
                //         this.feesData =  this.feesData.filter(r => r.class == this.studentData[0].class && r.section == this.studentData[0].section)
                //         console.log(this.feesData)                        
                //     } else {
                //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
                //     }
                // })
                this.rObj.patchValue({
                    Year : this.academicYearsData[0].start_year
                })

            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.allStudentsData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'pickup_points').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.sectionData = JSON.parse(cRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        // })
        this.imageSrc = this.studentData[0].photo_path ? this.imgURL+this.studentData[0].photo_path : '';
        console.log(this.imageSrc)
        
        console.log(this.documentData)
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: '',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    gotoPage(studID: any) {
        // alert(studID)
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/student/view/'+studID])
    }

    stringToArray(text: string) {
        return text.split(',')
    }

    
    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    getTotalAmount(amount : any, fine_type: any,fine_amt: any, percentage_amt: any) {
        let total_amt;
        if(this.isDue) {
            total_amt = fine_type == 'fix' ? parseInt(amount) + parseInt(fine_amt) : parseInt(amount) + (parseInt(amount) * parseInt(percentage_amt)) / 100
        } else {
            total_amt = amount
        }
        return total_amt
    }
    OnMonthChange(month:any) {
        let rObj = this.rObj.value;
        // alert(month.target.value)
        console.log(rObj.Month)
        console.log(rObj.Year)
        console.log(this.studentData[0].class)
        console.log(this.studentData[0].section)
        this._rService.getStudentAttendanceData(this.instID, this.activeAcademicID, this.studentData[0].class, this.studentData[0].section, month.target.value, rObj.Year).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let attendance = JSON.parse(mRes.result);
                this.studentAttendance = attendance.filter((a : any) => a.student_id == this.viewId)
                console.log(this.studentAttendance)
            } else {
                this.studentAttendance = []
            }
            this._gService.showLoader(false);
        })
    }

    OnYearChange(year:any) {
        let rObj = this.rObj.value;
        // alert(year.target.value)
        console.log(rObj.Month)
        console.log(rObj.Year)
        console.log(this.studentData[0].class)
        console.log(this.studentData[0].section)
        this._rService.getStudentAttendanceData(this.instID, this.activeAcademicID, this.studentData[0].class, this.studentData[0].section, rObj.Month, year.target.value).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let attendance = JSON.parse(mRes.result);
                this.studentAttendance = attendance.filter((a : any) => a.student_id == this.viewId)
                console.log(this.studentAttendance)
            } else {
                this.studentAttendance = []
            }
            this._gService.showLoader(false);
        })
    }
    
    getMonthName(month: any) {
        return this.MonthData.filter(m => m.month == month).map(m => m.monthName)
    }
     monthsBetween(d1: any, d2: any) {
        const monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
        console.log(monthNames)
        let result = [];
        let current = d1.getMonth();
        let end = (d2.getFullYear() - d1.getFullYear()) * 12 + d2.getMonth();
        for (;current <= end; current += 1) {
            result.push(monthNames[current % 12]);
        }
        return result;
    }

    getMonths(d1: any, d2: any) {
        var startDate : any = new Date(d1)
        var endDate : any = new Date(d2)
        var betweenMonths = [];
        console.log(startDate)
        if (startDate < endDate){
        var date = startDate.startOf('month');

        while (date < endDate.endOf('month')) {
            betweenMonths.push(date.format('YYYY-MM'));
            date.add(1,'month');
        }
        }
    }

    monthDiff(d1 : any, d2 : any) {
        var d1 : any = new Date(d1)
        var d2 : any = new Date(d2)
        console.log(d1)
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    totalAmount(amt : any, f_amt :any){  
        if(this.isDue == true) {
            return parseInt(amt)+parseInt(f_amt);
        }
    }

    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_id == feesId).map(s => s.txn_id)
    }


    setTabActive(tabName: string) {
        if (tabName == 'profileTab') {
            this.profileTab = true
        } else {
            this.profileTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'feesTab') {
            this.feesTab = true
        } else {
            this.feesTab = false
        }
        if (tabName == 'othersTab') {
            this.othersTab = true
        } else {
            this.othersTab = false
        }
        if (tabName == 'attendanceTab') {
            this.attendanceTab = true
        } else {
            this.attendanceTab = false
        }
    }
}