<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Mark Register </h1>
            <!-- <div class="header-msg col-md-4">
            </div> -->
            <div class="link-container col-md-6">
                <!-- <a href="./examination/exam-schedule/list" class="content-header-link"> Mark Register</a> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="filter-options">
                        <form [formGroup]="rObj">
                            <!-- <div class="row"> -->
                                <div class="form-group">
                                    <label>Exam
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Exam" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let type of examData" value="{{type.ID}}">{{type.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Exam.invalid && (rObj.controls.Exam.dirty || rObj.controls.Exam.touched)">
                                        <p class="input-error-msg">Please select exam</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData">
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div> 
                            <!-- </div>  -->
                            <div class="form-group btn-container" style="margin-top: 22px;">
                                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="ExaminationData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Subject</th>
                                    <th>Date</th>
                                    <th>Start Time</th>
                                    <th>End Time </th>
                                    <th>Full Marks</th>
                                    <th>Passing Marks</th>
                                    <th>Room</th>
                                    <th class="no-sort">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of ExaminationData">
                                    <td class="align-left">{{e.subject_name}}</td>
                                    <td>{{e.date}}</td>
                                    <td>{{e.start_time}}</td>
                                    <td>{{e.end_time}}</td>
                                    <td>{{e.full_marks}}</td>
                                    <td>{{e.passing_marks}}</td>
                                    <td>{{e.room_no}}</td>
                                    <td class="action-btn">
                                        <a tooltip="Add Marks" class="edit-btn" (click)="openAddMarksModal(e.ID, e.full_marks, e.subject_name)" ><i class="fa-solid fa-plus"></i></a>
                                        <!-- <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(e.ID)" ><i class="far fa-edit"></i></a> -->
                                        <!-- <a tooltip="Delete" class="del-btn" (click)="deleteData(e.ID, 'staff', 'delete staff')"><i class="far fa-trash-alt"></i></a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!ExaminationData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>            
                                
                </div>
            </div>
        </div>
    </form>
</div>