<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Pick Up Details</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Medium</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Pick Up Point
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Pick Up Point</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="PickUpPoint">
                            <div *ngIf="rObj.controls.PickUpPoint.invalid && (rObj.controls.PickUpPoint.dirty || rObj.controls.PickUpPoint.touched)">
                                <p class="input-error-msg">Please enter Pick Up Point</p>
                            </div>
                        </div>
                        <a href="https://www.google.com/maps" target="_blank">Click here to get latitude and longitude</a>
                        <div class="form-group">
                            <label>Latitude</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Latitude">
                            <div *ngIf="rObj.controls.Latitude.invalid && (rObj.controls.Latitude.dirty || rObj.controls.Latitude.touched)">
                                <p class="input-error-msg">Please select latitude</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Longitude</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Longitude">
                            <div *ngIf="rObj.controls.Longitude.invalid && (rObj.controls.Longitude.dirty || rObj.controls.Longitude.touched)">
                                <p class="input-error-msg">Please select Longitude</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="savePoint()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Pick Point List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="pickUpData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Name</th>
                                <th class="align-left">Latitude</th>
                                <th class="align-left">Longitude</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of pickUpData">
                                <td class="align-left">{{p.name}}</td>
                                <td class="align-left">{{p.latitude}}</td>
                                <td class="align-left">{{p.longitude}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openPickUpEditModal(p.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(p.ID, 'pickup_points', 'pick up point')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!pickUpData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>