<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Apply Leave</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
          <!--  <a href="../../section/add/" class="content-header-link">Add Section</a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Leave Details
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Reason</label>
                            <small class="req"> *</small>
                            <textarea class="form-control" formControlName="Reason" ></textarea>
                            <div *ngIf="rObj.controls.Reason.invalid && (rObj.controls.Reason.dirty || rObj.controls.Reason.touched)">
                                <p class="input-error-msg">Please enter reason</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Apply Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control date-input" formControlName="Apply_date" required="" value="">
                            <div *ngIf="rObj.controls.Apply_date.invalid && (rObj.controls.Apply_date.dirty || rObj.controls.Apply_date.touched)">
                                <p class="input-error-msg">Please enter date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Leave From Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control date-input" formControlName="Leave_from_date" required="" value="">
                            <div *ngIf="rObj.controls.Leave_from_date.invalid && (rObj.controls.Leave_from_date.dirty || rObj.controls.Leave_from_date.touched)">
                                <p class="input-error-msg">Please enter leave from date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Leave To Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control date-input" formControlName="Leave_to_date" required="" value="">
                            <div *ngIf="rObj.controls.Leave_to_date.invalid && (rObj.controls.Leave_to_date.dirty || rObj.controls.Leave_to_date.touched)">
                                <p class="input-error-msg">Please enter leave to date</p>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label>Attach Document</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                                <input type="file" formControlName="Leave_document" class="form-control" (change)="onFileChange($event)">
                            </div>
                        </div> -->
                        <div class="form-group">
                            <label>Attach Document</label>
                            <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;" *ngIf="imageSrc">View Document</a>
                            <!-- <input type="file" class="form-control" formControlName="Leave_document"> -->
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Document</div>  
                                <input type="file" formControlName="Leave_document" class="form-control" (change)="onFileChange($event)">
                            </div>
                            <!-- <div *ngIf="rObj.controls.Leave_document.invalid && (rObj.controls.Leave_document.dirty || rObj.controls.Leave_document.touched)">
                                <p class="input-error-msg">Please enter document</p>
                            </div> -->
                        </div>
                        
                        <div class="form-group">
                        <label>Leave Type</label>
                        <small class="req"> *</small>
                            <select class="form-select" formControlName="Leave_type">
                                <option>Select Leave Type</option>
                                <option *ngFor="let type of leaveData" value="{{type.ID}}">{{type.name}}</option>                     
                             </select>
                             <div *ngIf="rObj.controls.Leave_type.invalid && (rObj.controls.Leave_type.dirty || rObj.controls.Leave_type.touched)">
                                <p class="input-error-msg">Please enter leave type</p>
                            </div>  
                        </div>
                       
                       <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveLeave()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Apply Leave List
                    </div>
                </div>
                <div class="panel-body">
                    <!-- <ng-container *ngIf="applyLeaveData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('leaves', 'apply leaves')">Delete</button>
                        </div>
                    </ng-container> -->
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="applyLeaveData.length">
                        <thead>
                            <tr>
                                <th class="align-left"> REASON</th>
                                <th class="align-left">APPLY DATE</th>
                                <th class="align-left">APPLY FROM DATE</th>
                                <th class="align-left">APPLY TO DATE</th>
                                <th>APPLY DOCUMENT</th>
                                <th>STATUS</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let leave of applyLeaveData">
                                <td class="align-left">{{leave.reason}}</td>
                                <td class="align-left">{{getDate(leave.apply_date)}}</td>
                                <td class="align-left">{{getDate(leave.leave_from_date)}}</td>
                                <td class="align-left">{{getDate(leave.leave_to_date)}}</td>
                                <td>
                                    <ng-container *ngIf="leave.leave_document">
                                        <a href="{{imgURL}}{{leave.leave_document}}" target="_blank">view document</a>
                                    </ng-container>
                                </td>
                                <td>{{leave.status ? leave.status : '-'}} </td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openApplyLeaveEditModal(leave.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(leave.ID, 'leaves', 'apply leaves')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!applyLeaveData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>