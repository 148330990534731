<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Homework</h1>
        <div class="link-container col-md-6">
            <a href="homework/list" class="content-header-link">Homework List</a>
            <!-- <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a> -->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel"> 
                <div class="panel-header">
                    <div class="panel-title">
                        Add Homework
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select formControlName="Section" class="form-select">
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData">
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Subject</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Subject" class="form-select">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let sub of subjectData" value="{{sub.ID}}">{{sub.name}}</option> 
                                    </select>
                                    <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                                        <p class="input-error-msg">Please select Subject</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Homework Date</label>
                                    <small class="req"> *</small>
                                    <input type="date" class="form-control date-input" formControlName="Homework_date">
                                    <div *ngIf="rObj.controls.Homework_date.invalid && (rObj.controls.Homework_date.dirty || rObj.controls.Homework_date.touched)">
                                        <p class="input-error-msg">Please select Homework date</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Submission Date</label>
                                    <small class="req"> *</small>
                                    <input type="date" class="form-control date-input" formControlName="Submission_date">
                                    <div *ngIf="rObj.controls.Submission_date.invalid && (rObj.controls.Submission_date.dirty || rObj.controls.Submission_date.touched)">
                                        <p class="input-error-msg">Please select Submission date</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Attachment </label>
                                    <div class="imgUpload-wrapper">
                                        <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                                        <input type="file" formControlName="Document" class="form-control" (change)="onFileChange($event)">
                                    </div>
                                    <div *ngIf="rObj.controls.Document.invalid && (rObj.controls.Document.dirty || rObj.controls.Document.touched)">
                                        <p class="input-error-msg">Please select Document</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" formControlName="Description" rows="3" class="form-control"></textarea>
                        </div>    -->
                        <div class="form-group">
                            <label>Content</label>
                            <angular-editor [config]="editorConfig" formControlName="Description" class="form-control"></angular-editor>
                            <!-- <textarea formControlName="Content" class="form-control" rows="15" required=""></textarea> -->
                        </div>
                        
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="UpdateHomework()">Update</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
        
</div>