import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { EventService } from 'src/app/services/event.service';
import { EventModalComponent } from 'src/app/components/modal/eventmodal/eventmodal.component';
import { Title } from '@angular/platform-browser';
import { CircularService } from 'src/app/services/circular.service';
import { CircularmodalComponent } from 'src/app/components/modal/circularmodal/circularmodal.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-communication-email',
  templateUrl: './email.component.html',
})

export class EmailComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public mediumData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public circularData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    public searchData: any[] = [];
    public imgURL = this._gService.getImgPath();

    public typeIds : any[] = [];
    public typeData : any[] = [];
    public groupTab: boolean = true;
    public individualTab: boolean = false;
    public classTab: boolean = false;
    public birthdayTab: boolean = false;
    public sectionValue: '';
    public module: any;
    public groupValue: any;
    
    dtOptions: any = {};

    modalRef: BsModalRef;
    editorConfig: AngularEditorConfig = {
        editable: true,
        height: '250px',
        placeholder: 'Enter text here...',
    }

    constructor(
        private _gService: GlobalService,
        private _aService: CircularService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Content: ['',Validators.required],
        Subject: [''],
        Document: [''],
        // Date: ['', Validators.required],
        // Send_to: ['', Validators.required],
        Class: ['', Validators.required],
        Section: this._fBuilder.array([], Validators.required),
        Group: this._fBuilder.array([]),
    })
    public mObj = this._fBuilder.group({
        Type: ['student'],
    })

    selectEvent(item: any) {
        // this._reRoute.shouldReuseRoute = () => false;
        // this._router.navigate(['/student/view/'+item.id])
        this.typeIds.push(item.id)
        this.typeData.push(item)
        console.log(this.typeData)
        console.log(this.typeIds)
    }

    onChangeSearch(val: string) {
        let m : any = this.mObj.controls.Type.value;
        console.log(m)
            this._gService.getSearch(m,val).subscribe(sRes => {
                console.log(sRes);
                if (sRes.status === true) {
                    this.searchData = JSON.parse(sRes.result)
                }
            })
    }
    
    onFocused(e: any) {
        this.searchData = []
    }

    searchItem(item: any) {
        // let searchImgSrc = item.photo ? 'https://apiuat.edunationpro.com/uploads/'+item.photo : '../../assets/img/default-institution-icon.png';
        // let classSection = item.type == 'student' ? '<span class="search-meta">'+item.class_name+' ('+item.section_name+')</span>' : '';
        return '<div class="search-item-wrapper"> <div class="search-name">'+item.name +'</div></div>';
    }

    delSibling(id: any) {
        this.typeData.splice(this.typeData.indexOf(id), 1)
    }

    ngOnInit(): void {

        this._gService.showLoader(true);
    //    this.module = this.rObj.controls.Type.value;
    //     alert(this.module)
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'circulars').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.circularData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

    

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    onGroupSelect(e: any) {
        const selectedGroups = <FormArray>this.rObj.controls.Group;

        selectedGroups?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedGroups.push(new FormControl(e.target.value));
        } else {
          let index = selectedGroups.controls.findIndex(x => x.value == e.target.value)
          selectedGroups.removeAt(index);
        }

        this.groupValue = selectedGroups.value;

        console.log(selectedGroups.value)
    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    onSectionSelect(e: any) {
        const selectedSections = <FormArray>this.rObj.controls.Section;

        selectedSections?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSections.push(new FormControl(e.target.value));
        } else {
          let index = selectedSections.controls.findIndex(x => x.value == e.target.value)
          selectedSections.removeAt(index);
        }

        this.sectionValue = selectedSections.value;

        console.log(selectedSections.value)
    }
    setTabActive(tabName: string) {
        if (tabName == 'groupTab') {
            this.groupTab = true;
        } else {
            this.groupTab = false
        }
        if (tabName == 'individualTab') {
            this.individualTab = true
        } else {
            this.individualTab = false
        }
        if (tabName == 'classTab') {
            this.classTab = true
        } else {
            this.classTab = false
        }
        if (tabName == 'birthdayTab') {
            this.birthdayTab = true
        } else {
            this.birthdayTab = false
        }
    }


    removeImg() {
        this.imageSrc = '';
    }

    openCircularEditModal(circularID: number) {
        this.modalRef = this.modalService.show(CircularmodalComponent, {
            class: 'modal-dialog-centered circular-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                circularID: circularID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveEmail() {
        // if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                subject: rObj.Subject,
                content: rObj.Content,
                attachment: this.imageSrc,             
                group: JSON.stringify(this.groupValue),
                individual_data: JSON.stringify(this.typeIds),
                class: rObj.Class,
                section: JSON.stringify(this.sectionValue)           
            }
            
            console.log(mData)
            //remaining 
            // this._aService.circularData(mData).subscribe(eRes => {
            //     console.log(eRes)
            //     if (eRes.status === true) {
            //         this._reRoute.shouldReuseRoute = () => false;
            //         this._router.navigate([this._router.url]);
            //         this._gService.triggerSnackbar('Email Send Successfully', 'success', true);
            //     } else {
            //         this._gService.triggerSnackbar('Something went wrong', 'error', true);
            //     }
            // }, err => {
            //     console.log(err);
            //     this._gService.triggerSnackbar('Server Error!', 'error', true);
            // })
        // }
    }

}