import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy ,ActivatedRoute} from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { StaffService } from 'src/app/services/staff.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { InventoryIssueModalComponent } from 'src/app/components/modal/inventoryIssuemodal/inventoryIssuemodal.component';
import { TaskService } from 'src/app/services/task.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-taskManagement-editTask',
  templateUrl: './editTask.component.html',
})

export class EditTaskComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public isVernacular = this.userData.isVernacular;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public staffData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public deparmentData: any[] = [];
    public designationData:any[] = [];
    public staffListData: any[] = [];
    public inventoryIssueData: any[] = [];
    public inventoryItemData: any[] = [];
    public studentData: any[] = [];
    public taskData: any[] = [];
    public mediumData: any[] = [];
    public idsData: any[] = [];
    
    public patched : boolean = false;

    public taskID: any;

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: TaskService,
        private _sService: StaffService,
        private _nService: NotificationService,
        private _stService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Task: ['', Validators.required],
        Task_date: ['', Validators.required],
        Due_date: ['', Validators.required],
        Status: ['', Validators.required],
        Priority:['', Validators.required],
        User_type: ['', Validators.required],
        Department: [''],
        Designation: [''],
        User_student: [[]],
        User_staff: [[]],
        Class: [''],
        Section: [''],
        Description: [''],
        Medium: ['']
    
        
    })

    ngOnInit(): void {
        this.taskID = this._aRoute.snapshot.paramMap.get('id');

        this._gService.showLoader(true)

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.deparmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })      

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

       
        this.rObj.controls['Section'].valueChanges.subscribe((value : any) =>{
            this._gService.showLoader(true);
            if (!this.patched) { 
                this._stService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, value).subscribe(sRes => {
                    console.log(sRes)
                    if (sRes.status === true) {
                        this.studentListData = JSON.parse(sRes.result)
                    } else {
                        this.studentListData = [];
                    }
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
                this._gService.showLoader(false);
            }
        })

        this.rObj.controls['Designation'].valueChanges.subscribe((value : any)=>{
            this._gService.showLoader(true); 
            this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.staffListData = JSON.parse(sRes.result)
                } else {
                    this.staffListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false); 
        })

        

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        this._gService.getDataByID(this.taskID, 'task').subscribe(dataRes =>{
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.patched = true;
                this._stService.studentListData(this.instID, this.activeAcademicID, sData[0].class, sData[0].section).subscribe(sRes => {
                    console.log(sRes)
                    if (sRes.status === true) {
                        this.studentListData = JSON.parse(sRes.result)
                        console.log(this.studentListData)
                    } else {
                        this.studentListData = [];
                    }
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
                
                this.rObj.patchValue({
                    Task: sData[0].task,
                    Task_date: sData[0].task_date,
                    Due_date: sData[0].due_date,
                    Status: sData[0].status,
                    Priority:sData[0].priority,
                    User_type: sData[0].user_type,
                    Department: sData[0].department,
                    Designation: sData[0].designation,
                    User_staff: JSON.parse(sData[0].user_id),
                    User_student:JSON.parse(sData[0].user_id),
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Description: sData[0].description,
                });
                
            }
            this.patched = false;
            this._gService.showLoader(false);
        })



        this._gService.showLoader(false);
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    getStudentUserId(ids: any) {
        let userId : any
        let id : any[] 

        ids.forEach(res => {
            console.log(res)
            console.log(userId)
            userId = this.studentListData.filter(r => r.ID == res).map(r => r.user_id)
            this.idsData.push(JSON.parse(userId))
            console.log(this.idsData)
        });
        return this.idsData
    }


    updateTask() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.taskID,
                user_type: rObj.User_type,
                class: rObj.User_type == 'student' ?  rObj.Class : '',
                section: rObj.User_type == 'student' ? rObj.Section : '',
                department:  rObj.User_type == 'staff' ? rObj.Department : '',
                designation:  rObj.User_type == 'staff' ? rObj.Designation : '',
                task: rObj.Task,
                task_date: rObj.Task_date,
                due_date: rObj.Due_date,
                status: rObj.Status,
                priority: rObj.Priority,
                user_id: rObj.User_type == 'student' ? JSON.stringify(rObj.User_student) : JSON.stringify(rObj.User_staff),
                description: rObj.Description
              
               
            }
            console.log(mData)
            this._aService.taskData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['task-management/list']);
                    this._gService.triggerSnackbar('Task Updated Successfully', 'success', true);
                    let ndata = {
                        inst_id: this.instID,
                        academic_id: this.activeAcademicID,
                        user_role: rObj.User_type == 'student' ? 4 : 3,
                        user_ids: rObj.User_type == 'student' ? JSON.stringify(this.getStudentUserId(rObj.User_student)) : JSON.stringify(rObj.User_staff),
                        title: 'Assignment Added',
                        msg: 'optional',
                        link: '/task-management/list',
                        screen: 'Assignments'
                    }
                    console.log(ndata)
                    this._nService.sendNotificationData(ndata).subscribe(res => {
                        console.log(res)
                        if (res.status === true) {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);
                            this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                        }
                    })   
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}