<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Expense</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
          <a href="../finance/expense-head/add" class="content-header-link">Add Expense Head</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Expense
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label> Expense Head </label>
                            <small class="req"> *</small>
                            <select formControlName="Expense_head" class="form-select">
                                <option value="">Select</option>
                                <option *ngFor="let i of expenseHeadData" value="{{i.ID}}">{{i.expense_head}}</option>  
                            </select>
                            <div *ngIf="rObj.controls.Expense_head.invalid && (rObj.controls.Expense_head.dirty || rObj.controls.Expense_head.touched)">
                                <p class="input-error-msg">Please enter Expense head</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Expense Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Expense_name" >
                            <div *ngIf="rObj.controls.Expense_name.invalid && (rObj.controls.Expense_name.dirty || rObj.controls.Expense_name.touched)">
                                <p class="input-error-msg">Please enter Expense name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Invoice Number</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Invoice_no" class="form-control " required="">
                            <div *ngIf="rObj.controls.Invoice_no.invalid && (rObj.controls.Invoice_no.dirty || rObj.controls.Invoice_no.touched)">
                                <p class="input-error-msg">Please enter Invoice no</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Date</label>
                            <small class="req"> *</small>
                            <input type="date" formControlName="Expense_date" class="form-control date-input" required="">
                            <div *ngIf="rObj.controls.Expense_date.invalid && (rObj.controls.Expense_date.dirty || rObj.controls.Expense_date.touched)">
                                <p class="input-error-msg">Please enter Expense date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Description</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Description" class="form-control " required="">
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter Description</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Amount</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="Amount" class="form-control " required="">
                            <div *ngIf="rObj.controls.Amount.invalid && (rObj.controls.Amount.dirty || rObj.controls.Amount.touched)">
                                <p class="input-error-msg">Please enter Amount</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label> Attachment Document</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="file" formControlName="Document" class="form-control" (change)="onFileChange($event)">
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveExpense()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Expense List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="expenseData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Expense Head</th>
                                <th class="align-left">Expense Name</th>
                                <th>Invoice Number</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Document</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of expenseData">
                                <td class="align-left">
                                    <ng-container *ngFor="let expense of expenseHeadData">
                                        <ng-container *ngIf="expense.ID == i.expense_head">
                                            {{expense.expense_head}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">{{i.expense_name}}</td>
                                <td class="align-left">{{i.invoice_no}}</td>
                                <td>{{getDate(i.expense_date)}}</td>
                                <td><p>{{i.description}}</p></td>
                                <td>{{i.amount}}</td>
                                <td>{{i.document}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openExpenseEditModal(i.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(i.ID, 'expense', 'expense ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!expenseData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>