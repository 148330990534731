<div class="console-sidebar" [ngClass]="sidebarStatus ? 'collapse' : ''" (mouseenter)="sidebarStatus ? expandSidebar() : ''">
    <!-- <div class="institution-info">
        <div class="institution-icon">
            <img [src]="instLogo ? instLogo : '/assets/img/default-institution-icon.png'">
        </div>
        <div class="institution-meta">
            <div class="name">{{instName}}</div>
            <div class="branch">{{instBranch}}<span class="branch-switch" *ngIf="userRole == 1 && isMultiInst == 1" tooltip="Switch Branch" (click)="openBranchModal()"><i class="fa-solid fa-repeat"></i></span></div>
        </div>
    </div> -->
    <div class="navigation-links">
        <ul class="nav-links">
            <li class="nav-link ng-star-inserted active" >
                <a class="top-link ng-star-inserted"  href="/admin/dashboard"><i class="nav-icon fas fa-tachometer-alt"></i><span >Dashboard</span></a>
            </li>
            <!-- <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-university"></i>
                    <span> Dashboard <a href="/admin/dashboard"></a></span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Dashboard</li>
                    <li>
                        <a href="/admin/dashboard"></a>
                    </li>
                    <li>
                        <a data-name="institution/edit" href="/institution/list">All Branches</a>
                    </li>
                </ul>
            </li> -->
            <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-university"></i>
                    <span>Institutions</span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Institution</li>
                    <li>
                        <a href="/admin/institution/add">Add Institution</a>
                    </li>
                    <li>
                        <a data-name="institution/edit" href="/admin/institution/list">All Institutes</a>
                    </li>
                </ul>
            </li>
            <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-graduation-cap"></i>
                    <span>Users</span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Users</li>
                    <li>
                        <a data-name="users" href="/admin/users/add">Add Users</a>
                    </li>
                    <li>
                        <a data-name="usersList" href="/admin/users/list">Users List</a>
                    </li>
                    <!--<li>
                        <a data-name="class" href="/academic/class">Class</a>
                    </li>
                    <li>
                        <a data-name="subjects" href="/academic/subjects">Subjects</a>
                    </li>
                    <li>
                        <a data-name="assign-subject" href="/academic/assign-subject">Assign Class Subject</a>
                    </li>
                    <li>
                        <a data-name="assign-teacher" href="/academic/assign-teacher">Assign Class Teacher</a>
                    </li>
                    <li>
                        <a data-name="class-timetable" href="/academic/class-timetable">Class Time Table</a>
                    </li>
                    <li>
                        <a data-name="teachers-timetable" href="/academic/teacher-timetable">Teachers Time Table</a>
                    </li> -->
                </ul>
            </li>
            <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-graduation-cap"></i>
                    <span>Module Access</span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Module Access</li>
                    <li>
                        <a data-name="modulesaccess" href="/admin/modulesaccess">Module Access</a>
                    </li>
                </ul>
            </li>
            <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-graduation-cap"></i>
                    <span>Account Access</span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Account Access</li>
                    <li>
                        <a data-name="modulesaccess" href="/admin/accountAccess">Users Account Access</a>
                    </li>
                </ul>
            </li>
            <!-- <li class="nav-link has-sub-nav">
                <a class="top-link">
                    <i class="nav-icon fas fa-user-graduate"></i>
                    <span>Student</span>
                </a>
                <ul class="sub-nav">
                    <li class="title">Student</li>
                    <li>
                        <a href="/student/add/">Add Student</a>
                    </li>
                    <li>
                        <a href="/student/list">Student List</a>
                    </li>
                    <li>
                        <a href="/student/import/">Bulk Import</a>
                    </li>
                    <li>
                        <a href="/student/generate-id/list/">Generate ID Card</a>
                    </li>
                    <li>
                        <a href="/student/promote/add/">Promote Student</a>
                    </li>
                    <li>
                        <a href="/student/birthday/list/">BirthDay</a>
                    </li>
                </ul>
            </li> -->
            
        </ul>
    </div>
</div>