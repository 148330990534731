<div class="console-content ">
    
    <div class="row">
        
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper primary-color">
                            <span class="data-icon nav-icon fas fa-university"></span>
                            <!-- <i class="nav-icon fas fa-university"></i> -->
                        </div>
                        <div class="data">52</div>
                    </div>
                    <div class="data-title">Total Institutes</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper secondary-color">
                            <span class="data-icon teachers-icon"></span>
                            <i class="fa-solid fa-chalkboard-user data-icon"></i>
                        </div>
                        <div class="data">4</div>
                    </div>
                    <div class="data-title">Total Users</div>
                </div>
            </div>
        </div>
       
    </div>

</div>