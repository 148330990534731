<div class="console-content">
        <div class="console-content-header row">
            <h1 id="panel_title" class="title col-md-4">Promote Students </h1>
            <div class="header-msg col-md-5">
                        </div>
            <div class="link-container col-md-5">
                <!-- <a href="../student/add/" class="content-header-link">Add New Student</a>
                <a href="../student/import/" class="content-header-link">Import Students</a> -->
            </div>
        </div>

        <div class="panel">
            <div class="filter-options">
                <form [formGroup]="rObj">    
                <div class="row">
                    <div class="col-md-3 form-group">
                        <label>Class
                        <small class="req"> *</small></label>
                        <select name="class" formControlName="Class" class="form-select" style="width: 100%;">
                            <option value="">Please Select</option>
                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                            <p class="input-error-msg">Please select class</p>
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>Section
                            <small class="req"> *</small></label>
                            <select name="section" formControlName="Section" class="form-select" style="width: 100%;">
                                <option value="">Please Select</option>
                                <ng-container *ngFor="let section of classSectionData" >
                                    <option [value]="section.id">{{section.name}}</option>
                                </ng-container>
                            </select>
                            <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                <p class="input-error-msg">Please select section</p>
                            </div>
                    </div> 
                   

                    <div class="col-md-3 btn-container" style="margin-top: 25px;" >
                        <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                    </div> 
                </div> 
                </form>                     
            </div> 
        </div>
        <form [formGroup]="sObj">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="row form-group" *ngIf="studentListData.length" style="margin-bottom: 20px;">
                                <div class="col-md-4">
                                    <label>Promote In Sessions<small class="req"> *</small></label>
                                    <select name="class" formControlName="Academic_year" class="form-select" style="width: 100%;">
                                        <option value="">Please Select</option>
                                        <!-- <option *ngFor="let a of academicYearsData" value="{{a.ID}}">{{a.year}}</option> -->
                                        <option *ngFor="let ay of academicYearsData" [value]="ay.ID" [selected]="ay.ID == activeAcademicID">{{ay.start_year}} - {{+ay.end_year}}</option>
                            
                                        </select>
                                        <div *ngIf="sObj.controls.Academic_year.invalid && (sObj.controls.Academic_year.dirty || sObj.controls.Academic_year.touched)">
                                        <p class="input-error-msg">Please select Academic year</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Class<small class="req"> *</small></label>
                                    <select name="class" formControlName="Class2" class="form-select" style="width: 100%;">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                        </select>
                                        <div *ngIf="sObj.controls.Class2.invalid && (sObj.controls.Class2.dirty || sObj.controls.Class2.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Section<small class="req"> *</small></label>
                                        <select name="section" formControlName="Section2" class="form-select" style="width: 100%;">
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of class2SectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="sObj.controls.Section2.invalid && (sObj.controls.Section2.dirty || sObj.controls.Section2.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>                       
                            </div> 
                            
                            <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentListData.length">
                                <thead>
                                    <tr>
                                        <th class="align-left">Student Name</th>
                                         <th class="align-left">Admission/GR number</th>
                                        <th>Roll Number</th>
                                        <th>Gender</th>
                                        <th>Blood Group</th>
                                        <th>Date of Birth</th>
                                        <th>Current Status</th>
                                        <th class="no-sort">Next Session Status</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="studentInfo">
                                    <ng-container *ngFor="let s of studentListData; let i=index">
                                    <tr [formGroupName]="i">
                                        <input type="hidden" value="s.ID" formControlName="studentID">
                                        <td class="align-left">
                                            <img [src]="s.photo_path ? imgURL+s.photo_path : '/assets/img/default-user-avatar.png'" style="width: 40px; border: 1px solid #dcdcdc;">
                                            {{s.full_name}}
                                        </td>
                                        
                                         <td class="align-left">{{s.admission_number}}</td>
                                        <td>{{s.roll_no}}</td>
                                        <td>{{s.gender}}</td>
                                        <td>{{s.blood_group}}</td>
                                        <td>{{s.dob}}</td>
                                        <td>
                                            <div class="radio required">
                                                <label class="radio-inline">
                                                    <input class="form-check-input" type="radio" formControlName="currentResult" value="pass">Pass
                                                </label>
                                                <label class="radio-inline">
                                                    <input class="form-check-input" type="radio" formControlName="currentResult" value="fail">Fail
                                                </label>
                                            </div>
                                        </td>
                                        <!-- <td class="action-btn">
                                            <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                            <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                            <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                        </td> -->
                                        <td>
                                            <div class="radio required">
                                                <label class="radio-inline">
                                                    <input class="form-check-input" type="radio" formControlName="sessionStatus" value="continue">Continue
                                                </label>
                                                <label class="radio-inline">
                                                    <input class="form-check-input" type="radio" formControlName="sessionStatus" value="leave">Leave
                                                </label>
                                            </div>
                                        </td>                                       
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tr>
                                <div class="btn-container" style="margin-top: 15px;margin-bottom: 15px;">
                                    <button class="btn primary-btn small-btn" [disabled]="!sObj.valid" (click)="promote()">Promote Students</button>
                                </div>
                            </tr>

                            </table>   
                            <div *ngIf="!studentListData.length">
                                <div class="noDataWrapper">
                                    <img src="/assets/img/No-data-found.png">
                                </div>
                                <p style="text-align: center;">Data Not Found</p>
                            </div>
                        </div>         
                    </div>
                </div>
            </div>
        </form>
</div>