import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransportService } from 'src/app/services/transport.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
    selector: 'app-students-editStudent',
    templateUrl: './editStudent.component.html',
    styleUrls: ['./editStudent.component.css']
})

export class editStudentComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public admission_no : any;
    public Setting_admission_no : any;
    public Setting_roll_no : any;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public currentClassID: number;
    public currentSectionID: number;
    public currentRollNo: number;
    public currentAdmissionNo: number;
    public routeData: any[] = [];
    public hosteldetailsData: any[] = [];
    public hostelRoomsData: any[] = [];
    public studentData: any[] = [];
    public countriesData: any[] = [];
    public pickUpRouteList: any[] = [];
    public searchData: any[] = [];
    public patchsiblingData: any[] = [];
    public siblingData : any[] = [];
    public allStudentsData: any[] = []
    public SettingData: any[] = []
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];
    public activeAcademicSession : any;
    imageSrc:any = '';
    status:boolean = false;
    remove:boolean = false;

    file1Src:any = '';
    file1status:boolean = false;
    remove1Document:boolean = false;

    file2Src:any = '';
    file2status:boolean = false;
    remove2Document:boolean = false;

    file3Src:any = '';
    file3status:boolean = false;
    remove3Document:boolean = false;

    file4Src:any = '';
    file4status:boolean = false;
    remove4Document:boolean = false;

    dtOptions: any = {};

    modalRef: BsModalRef;

    public studentID: any;

    public basicTab: boolean = true;
    public addressTab: boolean = false;
    public parentTab: boolean = false;
    public documentTab: boolean = false;
    public siblingsTab: boolean = false;
    public otherTab: boolean = false;
    public imgURL = this._gService.getImgPath();
    public roll_no : any;

    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _studService: StudentService,
        private _tService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}
    selectEvent(item: any) {
        console.log(item)
        // this._reRoute.shouldReuseRoute = () => false;
        // this._router.navigate(['/student/view/'+item.id])
        // alert('yes')
        // console.log(item.id)
        this.siblingData.push(item.id)
        this.patchsiblingData.push(item)
        console.log(this.siblingData)
        console.log(this.patchsiblingData)
    }

    onChangeSearch(val: string) {
        // alert(val)
        this._gService.getSearchData(val).subscribe(sRes => {
            console.log(sRes);
            if (sRes.status === true) {
                let searchData = JSON.parse(sRes.result)
                console.log(searchData)
                this.searchData = searchData.filter(s => s.id != this.studentID)
            }
        })
    }
    
    onFocused(e: any) {
        this.searchData = []
    }

    searchItem(item: any) {
        let searchImgSrc = item.photo ? 'https://apiuat.edunationpro.com/uploads/'+item.photo : '../../assets/img/default-institution-icon.png';
        let classSection = item.type == 'student' ? '<span class="search-meta">'+item.class_name+' ('+item.section_name+')</span>' : '';
        return '<div class="search-item-wrapper"><img class="search-img" src="'+searchImgSrc+'"> <div class="search-name">'+item.name + classSection+'</div><span class="search-result-type">Student</span></span><div>';
    }

    delSibling(siblingID: any) {
        console.log(siblingID)
        console.log(this.siblingData)
        this.siblingData.splice(this.siblingData.indexOf(siblingID), 1)
        console.log(this.siblingData)
        this.patchsiblingData.splice(this.patchsiblingData.map(m => m.ID).indexOf(siblingID), 1)
        console.log(this.patchsiblingData)
    }


    // selectEvent(item: any) {
    //     // this._reRoute.shouldReuseRoute = () => false;
    //     // this._router.navigate(['/student/view/'+item.id])
    //     this.siblingIds.push(item.id)
    //     this.siblingData.push(item)
    //     console.log(this.siblingData)
    //     console.log(this.siblingIds)
    // }

    // onChangeSearch(val: string) {

    //     this._gService.getSearchData(val).subscribe(sRes => {
    //         console.log(sRes);
    //         if (sRes.status === true) {
    //             this.searchData = JSON.parse(sRes.result)
    //             this.searchData.filter(function(sd, index, siblingIds) {
    //                 if (siblingIds.includes(sd.id)) {
    //                     return false
    //                 }
    //             }) 
    //             console.log(this.searchData)
                
    //         }
    //     })
    // }
    
    // onFocused(e: any) {
    //     this.searchData = []
    // }

    // searchItem(item: any) {
    //     let searchImgSrc = item.photo ? 'https://apiuat.edunationpro.com/uploads/'+item.photo : '../../assets/img/default-institution-icon.png';
    //     let classSection = item.type == 'student' ? '<span class="search-meta">'+item.class_name+' ('+item.section_name+')</span>' : '';
    //     return '<div class="search-item-wrapper"><img class="search-img" src="'+searchImgSrc+'"> <div class="search-name">'+item.name + classSection+'</div><span class="search-result-type">Student</span></span><div>';
    // }

    // delSibling(siblingID: any) {
    //     this.siblingIds.splice(this.siblingIds.indexOf(siblingID.id), 1)
    // }


    public rObj = this._fBuilder.group({
        Register_no: ['', Validators.required],
        Admission_date: ['',Validators.required],
        Class: ['',Validators.required], 
        Section: ['', Validators.required],
        Roll_no: ['', Validators.required],
        First_name: ['', Validators.required],
        Middle_name: ['', Validators.required],
        Last_name: ['', Validators.required],
        Birth_date: ['', Validators.required],
        Gender: ['', Validators.required],
        Blood_Group: ['', Validators.required],
        Mobile_no: ['', [Validators.required,Validators.pattern("[0-9 ]{10}")]],
        Email: ['', [Validators.required, Validators.email]],
        PhotoUpload:[''],
        Adhar_no: [''],
        Birth_place: [''],
        Nationality: [''],
        Mother_tongue: [''],
        Category: [''],
        Religion:  [''],
        Caste: [''],
        Permanent_address: [''],
        Current_address: [''],
        City: [''],
        Pincode: [''],
        State: [''],
        Country: [''],
        Father_name:[''],
        Father_mobile:['',Validators.pattern("[0-9 ]{10}")],
        Father_occupation:[''],
        Father_photo: [''],
        Mother_name: [''],
        Mother_mobile: ['',Validators.pattern("[0-9 ]{10}")],
        Mother_occupation:[''],
        Mother_photo: [''],
        Guardian_is: ['father'],
        Guardian_name: [''],
        Guardian_relation: [''],
        Guardian_number:['',Validators.pattern("[0-9 ]{10}")],
        Guardian_email: [''],
        Guardian_occupation:[''],
        Guardian_income: [''],
        Guardian_photo: [''],
        Guardian_address: [''],
        Guardian_education:[''],
        Document1_title:[''],
        Document1_file: [''],
        Document2_title: [''],
        Document2_file: [''],
        Document3_title: [''],
        Document3_file: [''],
        Document4_title: [''],
        Document4_file:[''],
        Sibling_ids: [''],
        previous_school_name: [''],
        previous_school_address:[''],
        previous_qualification: [''],
        Fees_Month: [''],
        Pickup_Point: [''],
        Route_id: [''],
        hostel_room_id: [''],
        hostel_id:[''],
       
    })

    ngOnInit(): void {

        this.studentID = this._aRoute.snapshot.paramMap.get('id');

        this._gService.showLoader(true);

        this._sService.getSettingsData(this.instID).subscribe(aRes =>{
            console.log(aRes)
            if(aRes.status === true) {
                this.SettingData = JSON.parse(aRes.result)
                console.log(this.SettingData)
                this.Setting_admission_no = this.SettingData[0].autogenerate_admission_gr_no
                this.Setting_roll_no = this.SettingData[0].autogenerate_roll_no
                if (this.SettingData[0].autogenerate_admission_gr_no == 1) {
                    this.rObj.controls.Register_no.disable()
                } else {
                    this.rObj.controls.Register_no.enable()
                }
                if (this.SettingData[0].autogenerate_roll_no == 1) {
                    this.rObj.controls.Roll_no.disable()
                } else {
                    this.rObj.controls.Roll_no.enable()
                }

                // if (this.currentAdmissionNo) {
                //     this.admission_no = this.currentAdmissionNo
                // } else {
                //     this._studService.getLastAdmissionNoData(this.instID, this.activeAcademicID).subscribe(aRes =>{
                //         console.log(aRes)
                //         if(aRes.status === true) {
                //             let adata = aRes.result
                //             console.log((adata))
                //             let str = parseInt(adata.replace(/\D/g, "")) + 1
                //             console.log(str)
                //             this.admission_no = this.SettingData[0].prefix + str.toString()
                //             console.log(this.admission_no)
                //         }
                //     })
                // }


            }
        })

        this._gService.getDataByID(this.activeAcademicID, 'academic_years').subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                let ayData = JSON.parse(ayRes.result)[0];
                console.log(ayData)
                this.activeAcademicSession = ayData.start_year +'-'+ ayData.end_year;
            }
        })
        // alert(this.activeAcademicID)

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'pickup_points').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.pickupPointData = JSON.parse(cRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.allStudentsData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.routeData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.hosteldetailsData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.hostelRoomsData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.studentData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })

        this.rObj.controls['Route_id'].valueChanges.subscribe((value: any) => {
            // alert(value)
            this._gService.showLoader(true);
            this._tService.getPickUpRouteData(this.instID, value).subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    this.pickUpRouteList = JSON.parse(dataRes.result)
                    console.log(this.pickUpRouteList)
                }
            })
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.studentID, 'students').subscribe(dataRes => {
            console.log(dataRes);
           
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                console.log(sData)
                this.rObj.patchValue({
                    Register_no: sData[0].admission_number,
                    Admission_date: sData[0].admission_date,
                    Class: sData[0].class, 
                    Section: sData[0].section,
                    Roll_no: sData[0].roll_no,
                    First_name: sData[0].first_name,
                    Middle_name: sData[0].middle_name,
                    Last_name: sData[0].last_name,
                    Birth_date: sData[0].dob,
                    Gender: sData[0].gender,
                    Blood_Group: sData[0].blood_group,
                    Mobile_no: sData[0].mobile  != 0 ? sData[0].mobile : '',
                    Email: sData[0].email,
                    Adhar_no: sData[0].aadhaar_number != 0 ? sData[0].aadhaar_number : '',
                    Birth_place: sData[0].birth_place,
                    Nationality: sData[0].nationality,
                    Mother_tongue: sData[0].mother_tongue,
                    Category: sData[0].category,
                    Religion:  sData[0].religion,
                    Caste: sData[0].caste,
                    Permanent_address: sData[0].permanent_address,
                    Current_address: sData[0].current_address,
                    City: sData[0].city,
                    Pincode: sData[0].pincode,
                    State: sData[0].state,
                    Country: sData[0].country,
                    Father_name:sData[0].father_name,
                    Father_mobile:sData[0].father_mobile != 0 ? sData[0].father_mobile : '',
                    Father_occupation:sData[0].father_occupation,
                    Father_photo: sData[0].Father_photo,
                    Mother_name:sData[0].mother_name,
                    Mother_mobile: sData[0].mother_mobile  != 0 ? sData[0].mother_mobile : '',
                    Mother_occupation:sData[0].mother_occupation,
                    Mother_photo: sData[0].Mother_photo,
                    Guardian_is: sData[0].guardian_is,
                    Guardian_name:sData[0].guardian_name,
                    Guardian_relation: sData[0].guardian_relation,
                    Guardian_number:sData[0].guardian_mobile != 0 ? sData[0].guardian_mobile : '',
                    Guardian_email: sData[0].guardian_email,
                    Guardian_occupation:sData[0].guardian_occupation,
                    Guardian_income: sData[0].guardian_income,
                    Guardian_photo: sData[0].Guardian_photo,
                    Guardian_address: sData[0].guardian_address,
                    Guardian_education:sData[0].guardian_education,
                    // Sibling_ids: sData[0].sibling_ids,
                    previous_school_name: sData[0].previous_school_name,
                    previous_school_address:sData[0].previous_school_address,
                    previous_qualification: sData[0].previous_qualification,
                    Fees_Month: sData[0].transport_fees_months ? sData[0].transport_fees_months.split(',') : '',
                    Route_id: sData[0].transport_route_id ? sData[0].transport_route_id : '',
                    Pickup_Point: sData[0].transport_route_pickup_id ? sData[0].transport_route_pickup_id : '',
                    hostel_room_id: sData[0].hostel_room_id,
                    hostel_id:sData[0].hostel_id,
                });
                this.onSelect(sData[0].guardian_is)

                this.currentClassID = sData[0].class
                this.currentSectionID = sData[0].section
                this.currentRollNo = sData[0].roll_no
                this.currentAdmissionNo = sData[0].admission_number
                this.imageSrc = sData[0].photo_path ? this.imgURL+sData[0].photo_path : '';
                let siblingIds = sData[0].sibling_ids ? sData[0].sibling_ids.split(',') : []
                console.log(siblingIds)
                this.siblingData = siblingIds
                console.log(this.siblingData)
                if (siblingIds) {
                    this._studService.getSiblingsDatabyID(this.instID, this.activeAcademicID, siblingIds).subscribe(res => {
                        console.log(JSON.parse(res.result))
                        this.patchsiblingData = JSON.parse(res.result)
                        console.log(this.patchsiblingData)
                    })
                }
                // siblingIds.forEach(id => {
                //     console.log(id)
                //     // this._gService.getDataByID(id, 'students').subscribe(res =>{
                //     //     console.log(res.result)
                //     //     this.patchsiblingData = JSON.parse(res.result)
                //     //     console.log(this.patchsiblingData)
                //     // })
                // })
                // this._gService.getDataByID(this.siblingData)
                // this.siblingData.push(this.siblingIds)
                
                this._studService.getStudentDocumentData(this.studentID).subscribe( res =>{
                    console.log(res)
                    if(res.status === true){
                        let dData = JSON.parse(res.result); 
                        let d1Data = dData.filter((t : any) => t.doc_no == 1).map((t : any) => t.path)
                        this.file1Src = d1Data.length ? this.imgURL+d1Data : '';
                        let d2Data = dData.filter((t : any) => t.doc_no == 2).map((t : any) => t.path)
                        this.file2Src = d2Data.length ? this.imgURL+d2Data : '';
                        let d3Data = dData.filter((t : any) => t.doc_no == 3).map((t : any) => t.path)
                        this.file3Src = d3Data.length ? this.imgURL+d3Data : '';
                        let d4Data = dData.filter((t : any) => t.doc_no == 4).map((t : any) => t.path)
                        this.file4Src = d4Data.length ? this.imgURL+d4Data : '';
                        this.rObj.patchValue({
                           Document1_title: dData.filter((t : any) => t.doc_no == 1).map((t : any) => t.name),  
                           Document2_title: dData.filter((t : any) => t.doc_no == 2).map((t : any) => t.name),
                           Document3_title: dData.filter((t : any) => t.doc_no == 3).map((t : any) => t.name),
                           Document4_title: dData.filter((t : any) => t.doc_no == 4).map((t : any) => t.name),
                        })
                    }
                })
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    onSelect(value : any) {
        // this.disabled = true
        if (value == 'father' || value == 'mother') {
            this.rObj.controls.Guardian_name.disable() 
            this.rObj.controls.Guardian_number.disable() 
            this.rObj.controls.Guardian_occupation.disable()
            this.rObj.controls.Guardian_relation.disable()

        } else {
            this.rObj.controls.Guardian_name.enable() 
            this.rObj.controls.Guardian_number.enable() 
            this.rObj.controls.Guardian_occupation.enable()
            this.rObj.controls.Guardian_relation.enable()
        }
    }

    getLastRollNo(classID: any, sectionID: any){
        if (classID == this.currentClassID && sectionID == this.currentSectionID) {
            this.roll_no = this.currentRollNo
        } else {
            this._studService.getLastRollNoData(this.instID, this.activeAcademicID, classID, sectionID).subscribe(aRes =>{
                console.log(aRes)
                if(aRes.status === true) {
                    let adata = JSON.parse(aRes.result)
                    console.log(adata)
                    this.roll_no = adata + 1
                    console.log(this.roll_no)
                } else {
                    this.roll_no = 1
                }
            })
        }
    }  

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.remove = true;
        this.imageSrc = '';
    }

    onDoc1FileChange(event:any) {
        this.file1status = false
        const file = event.target.files[0];
   
        this.file1status = event.target.files.length > 0 ? true : false;
        if (this.file1status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file1Src = reader.result;   
                 
           }
        }
        console.log(file)
        console.log(this.file1status)
        console.log(this.file1Src)   
        
    }

    removeDocument1() {
        this.file1Src = '';
        this.remove1Document = true;
    }

    onDoc2FileChange(event:any) {
        this.file2status = false
        const file = event.target.files[0];
        this.file2status = event.target.files.length > 0 ? true : false;
        if (this.file2status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file2Src = reader.result;   
           }
        }
        console.log(file)
        console.log(this.file2status)
        console.log(this.file2Src)   
        
    }
    removeDocument2() {
        this.file2Src = '';
        this.remove2Document = true
    }
    onDoc3FileChange(event:any) {
        this.file3status = false
        const file = event.target.files[0];
        this.file3status = event.target.files.length > 0 ? true : false;
        if (this.file3status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file3Src = reader.result;   
                 
           }
        }
        console.log(this.file3Src)   
        
    }

    removeDocument3() {
        this.file3Src = '';
        this.remove3Document = true
    }
    onDoc4FileChange(event:any) {
        this.file4status = false
        const file = event.target.files[0];
        this.file4status = event.target.files.length > 0 ? true : false;
        if (this.file4status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file4Src = reader.result;   
                 
           }
        }
        console.log(this.file4Src)   
        
    }

    removeDocument4() {
        this.file4Src = '';
        this.remove4Document = true
    }
    setTabActive(tabName: string) {
        if (tabName == 'basicTab') {
            this.basicTab = true
        } else {
            this.basicTab = false
        }
        if (tabName == 'addressTab') {
            this.addressTab = true
        } else {
            this.addressTab = false
        }
        if (tabName == 'parentTab') {
            this.parentTab = true
        } else {
            this.parentTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'siblingsTab') {
            this.siblingsTab = true
        } else {
            this.siblingsTab = false
        }
        if (tabName == 'otherTab') {
            this.otherTab = true
        } else {
            this.otherTab = false
        }
    }


    updateStudent() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'update',
                id: this.studentID,
                register_number: this.currentAdmissionNo ? this.currentAdmissionNo : rObj.Register_no,
                // register_number:  rObj.Register_no,
                admission_date: rObj.Admission_date,
                class: rObj.Class,
                section: rObj.Section,
                // roll_no: this.SettingData[0].autogenerate_roll_no == 1 ? this.roll_no : rObj.Roll_no,
                roll_no: this.currentRollNo ? this.currentRollNo :  rObj.Roll_no,
                first_name: rObj.First_name,
                middle_name: rObj.Middle_name,
                last_name: rObj.Last_name,
                dob: rObj.Birth_date,
                gender: rObj.Gender,
                blood_group: rObj.Blood_Group,
                birth_place: rObj.Birth_place,
                nationality: rObj.Nationality,
                mother_tongue: rObj.Mother_tongue,
                category: rObj.Category,
                religion: rObj.Religion,
                caste: rObj.Caste,
                aadhaar_number: rObj.Adhar_no  ? rObj.Adhar_no : '',
                mobile: rObj.Mobile_no ? rObj.Mobile_no : '',
                email: rObj.Email,
                permanent_address: rObj.Permanent_address,
                current_address: rObj.Current_address,
                city: rObj.City,
                pincode: rObj.Pincode ? rObj.Pincode : '', 
                state: rObj.State,
                country: rObj.Country,
                father_name: rObj.Father_name,
                father_mobile: rObj.Father_mobile ? rObj.Father_mobile : '',
                father_occupation: rObj.Father_occupation,
                mother_name: rObj.Mother_name,
                mother_mobile: rObj.Mother_mobile ? rObj.Mother_mobile : '',
                mother_occupation: rObj.Mother_occupation,
                guardian_is: rObj.Guardian_is,
                guardian_name: rObj.Guardian_is == 'father'? rObj.Father_name : rObj.Guardian_is == 'mother' ? rObj.Mother_name : rObj.Guardian_name,
                guardian_relation: rObj.Guardian_is == 'father'? 'father' : rObj.Guardian_is == 'mother' ? 'mother' : rObj.Guardian_relation,
                guardian_mobile: rObj.Guardian_number ? rObj.Guardian_is == 'father'? rObj.Father_mobile : rObj.Guardian_is == 'mother' ? rObj.Mother_mobile : rObj.Guardian_number : '',
                guardian_email: rObj.Guardian_email,
                guardian_occupation: rObj.Guardian_is == 'father'? rObj.Father_occupation : rObj.Guardian_is == 'mother' ? rObj.Mother_occupation : rObj.Guardian_occupation,
                guardian_education: rObj.Guardian_education,
                guardian_income: rObj.Guardian_income ? rObj.Guardian_income : '',
                guardian_address: rObj.Guardian_address,
                previous_school_name: rObj.previous_school_name,
                previous_school_address: rObj.previous_school_address,
                previous_qualification: rObj.previous_qualification,
                // sibling_ids: JSON.stringify(this.siblingIds),
                sibling_ids: this.siblingData.length > 0 ? this.siblingData.toString() : '',
                transport_route_id: rObj.Route_id ? rObj.Route_id : '',
                transport_route_pickup_id: rObj.Pickup_Point ? rObj.Pickup_Point : '',
                transport_fees_months: rObj.Fees_Month ? rObj.Fees_Month.toString() : '',
                hostel_id: rObj.hostel_id ? rObj.hostel_id : '',
                hostel_room_id: rObj.hostel_room_id ? rObj.hostel_room_id : '',
                photo_path: this.status ? this.imageSrc : '',
                remove_photo:this.remove,
                document1_title: rObj.Document1_title,
                document1_file: this.file1status ? this.file1Src : '',
                document2_title: rObj.Document2_title,
                document2_file: this.file2status ? this.file2Src : '',
                document3_title: rObj.Document3_title,
                document3_file: this.file3status ? this.file3Src : '',
                document4_title: rObj.Document4_title,
                document4_file: this.file4status ? this.file4Src : '',
                remove_document1: this.remove1Document,
                remove_document2: this.remove2Document,
                remove_document3: this.remove3Document,
                remove_document4: this.remove4Document,
            }
            console.log(sData)
            this._studService.studentData(sData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['student/list/'+rObj.Class+'/'+rObj.Section]);
                    this._gService.triggerSnackbar('Student Details Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}