<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-4">Due Fees </h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-5">
            <!--<a href="../add/" class="content-header-link">Add New Student</a>
            <a href="../import/" class="content-header-link">Import Students</a>-->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Fees Type<small class="req"> *</small></label>
                            <select formControlName="Fees_type" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let type of feesTypeData" value="{{type.ID}}">{{type.name}}</option>                        
                            </select>
                            <div *ngIf="rObj.controls.Fees_type.invalid && (rObj.controls.Fees_type.dirty || rObj.controls.Fees_type.touched)">
                                <p class="input-error-msg">Please enter Fees type</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Class<small class="req"> *</small></label>
                            <select formControlName="Class" class="form-select" data-next="getSection" data-type="options" required="">
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter Class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section<small class="req"> *</small></label>
                            <select  formControlName="Section" class="form-select" >
                                <option value="">Please Select</option>
                                <ng-container *ngFor="let section of classSectionData" >
                                    <option [value]="section.id">{{section.name}}</option>
                                </ng-container>
                            </select>
                            <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                <p class="input-error-msg">Please enter Section</p>
                            </div>
                        </div>
                       
                        <div class="form-group" style="margin-top: 24px;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                       Due Fees List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="duefeeData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Class</th>
                                <th>Section</th>
                                <th class="align-left">Student Name</th>
                                <th>Fees Type</th>
                                <!-- <th>Due Date</th> -->
                                <th>Amount</th>
                                <!-- <th>Fine Amount</th> -->
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of duefeeData">
                                <td class="align-left">
                                    <ng-container *ngFor="let class of classData">
                                        <ng-container *ngIf="class.ID == f.class">
                                            {{class.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let section of sectionData">
                                        <ng-container *ngIf="section.ID == f.section">
                                            {{section.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">{{f.student_name}}</td>
                                <td>
                                    <ng-container *ngFor="let type of feesTypeData">
                                        <ng-container *ngIf="type.ID == f.fees_type">
                                            {{type.name}}
                                        </ng-container>
                                    </ng-container>
                                    </td>
                                <!-- <td>{{f.due_date}}</td> -->
                                <td>{{f.amount}}</td>
                                <!-- <td>{{f.fine_amount}}</td> -->
                                <!-- <td class="action-btn">
                                    <button type="button" class="btn btn-primary pay_btn" (click)="openPayModal(f.ID,totalAmount(f.amount, f.fine_amount), f.amount, f.fine_type, f.fine_amount)">Pay</button>
                                </td> -->
                                <td class="action-btn">
                                    <a tooltip="Collect Fee" class="edit-btn" (click)="gotoPayLink(f.student_id)" ><i class="fas fa-money-bill-wave-alt"></i></a>
                                    <!-- <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'student', 'delete student')"><i class="far fa-trash-alt"></i></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!duefeeData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</div>