import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AssignmentModalComponent } from 'src/app/components/modal/assignmentmodal/assignmentmodal.component';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-homework-edithomework',
  templateUrl: './edithomework.component.html',
})

export class EditHomeworkComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userRole = this.userData.UserRole;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public ids: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectData: any[] = [];
    public assignmentData: any[] = [];
    public mediumData: any[] = [];
    public imageSrc:any = '';
    public homeworkID: any = '';
    public assignmentID:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    modalRef: BsModalRef;

    editorConfig: AngularEditorConfig = {
        editable: true,
        height: '350px',
        placeholder: 'Enter text here...',
    }

    constructor(
        private _gService: GlobalService,
        private _aService: AssignmentService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Subject: ['', Validators.required],
        Medium: [''],
        Homework_date: ['', Validators.required],
        Submission_date: ['', Validators.required],
        Document: [''],
        Description: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this.homeworkID = this._aRoute.snapshot.paramMap.get('editID');

        this._gService.getDataByID(this.homeworkID, 'assignment').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Subject: sData[0].subject,
                    Homework_date: sData[0].assignment_date,
                    Submission_date: sData[0].submission_date,
                    Description: sData[0].description,
                    // Document: sData[0].attachment,
                }); this.imageSrc = sData[0].attachment ? this.imgURL+sData[0].attachment : '';
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.subjectData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assignment').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.assignmentData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        
         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           
        }
        // alert(this.status)
        // alert(this.imageSrc)
    }

    removeImg() {
        this.status = false
        this.imageSrc = '';
    }
    openMediumEditModal(assignmentID: number) {
        this.modalRef = this.modalService.show(AssignmentModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                assignmentID: assignmentID,
            } 
        });
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    UpdateHomework() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.homeworkID,
                assignment_date: rObj.Homework_date,
                submission_date: rObj.Submission_date,
                class: rObj.Class,
                section: rObj.Section,
                subject: rObj.Subject,
                description: rObj.Description,
                attachment: this.status ? this.imageSrc : '',
            }
            console.log(mData)
            this._aService.AssignmentData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['homework/list']);
                    this._gService.triggerSnackbar('Assignment Saved Successfully', 'success', true);
                    let ndata = {
                        inst_id: this.instID,
                        academic_id: this.activeAcademicID,
                        user_role: '4',
                        user_ids: JSON.stringify(this.ids),
                        title: 'Homework Updated',
                        msg: 'optional',
                        link: '/homework/list',
                        screen: 'Homeworks'
                    }
                    console.log(ndata)
                    this._nService.sendNotificationData(ndata).subscribe(res => {
                        console.log(res)
                        if (res.status === true) {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);
                            this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                        }
                    })                                              
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}