import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { AdminService } from 'src/app/services/admin.service';
import { EditUsersModalComponent } from 'src/app/components/modal/editUsersmodal/editUsersmodal.component';
import { ChangeUserPasswordModalComponent } from 'src/app/components/modal/changeUserPasswordmodal/changeUserPasswordmodal.component';

@Component({
  selector: 'app-admin-panel-usersList',
  templateUrl: './usersList.component.html',
})

export class UsersListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;
    public userRole = this.userData.AdminUserRole;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public adminUsersData: any[] = [];
    public UserRoleData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    ngOnInit(): void {
        console.log(this.userData)

        this._gService.showLoader(true);

        this._adminService.getDataByModuleName('admin_users').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.adminUsersData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._adminService.getUserRoleData().subscribe(res =>{
            console.log(res)
            if (res.status === true) {
                this.UserRoleData = JSON.parse(res.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    getuserName(roleId: any) {
        return this.UserRoleData.filter(i => i.ID == roleId).map(i => i.name)
    }

    openUsersEditModal(usersID: number) {
        this.modalRef = this.modalService.show(EditUsersModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                usersID: usersID,
            } 
        });
    }

    openChangePassword(userid: number) {
        this.modalRef = this.modalService.show(ChangeUserPasswordModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                userid: userid,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openAdminDeleteModal(dataID, moduleName, moduleTitle, this.userRole)
    }

    // save() {
    //     if (this.rObj.valid) {
    //         let rObj = this.rObj.value;
    //         let mData = {
    //             action: 'save',
    //             name: rObj.Name,
    //             username: rObj.UserName,
    //             email: rObj.Email,
    //             password: rObj.Password,
    //             userRole: rObj.UserRole,
    //         }
    //         console.log(mData)
    //         this._adminService.UserData(mData).subscribe(ayRes => {
    //             console.log(ayRes)
    //             if (ayRes.status === true) {
    //                 this._reRoute.shouldReuseRoute = () => false;
    //                 this._router.navigate([this._router.url]);
    //                 this._gService.triggerSnackbar('User Saved Successfully', 'success', true);
    //             } else {
    //                 this._gService.triggerSnackbar('Something went wrong', 'error', true);
    //             }
    //         }, err => {
    //             console.log(err);
    //             this._gService.triggerSnackbar('Server Error!', 'error', true);
    //         })
    //     }
    // }

}