<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3"> Driver</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../../transport/vehicles/add" class="content-header-link">Add Vehicle</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                    Add Driver
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                        <label>Vehicle No.</label>
                            <small class="req"> *</small>
                            <select formControlName="Vehicle_no" class="form-control" >
                                <option value="">Please Select</option>
                                <option *ngFor="let No of vehicleData" value="{{No.ID}}">{{No.vehicle_no}}</option>
            
                             </select>
                             <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                                <p class="input-error-msg">Please select vehicle number</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Driver Name</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="D_name" class="form-control" >
                            <div *ngIf="rObj.controls.D_name.invalid && (rObj.controls.D_name.dirty || rObj.controls.D_name.touched)">
                                <p class="input-error-msg">Please enter name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Driver Phone No.</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="D_phone" class="form-control" >
                            <div *ngIf="rObj.controls.D_phone.invalid && (rObj.controls.D_phone.dirty || rObj.controls.D_phone.touched)">
                                <p class="input-error-msg">Please enter phone number</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Alternate Contact No.</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="D_altPhone" class="form-control" >
                            <div *ngIf="rObj.controls.D_altPhone.invalid && (rObj.controls.D_altPhone.dirty || rObj.controls.D_altPhone.touched)">
                                <p class="input-error-msg">Please enter alternate phone number</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Present Address</label>
                            <small class="req"> *</small>
                            <textarea type="text" formControlName="D_address" class="form-control"></textarea>
                            <div *ngIf="rObj.controls.D_address.invalid && (rObj.controls.D_address.dirty || rObj.controls.D_address.touched)">
                                <p class="input-error-msg">Please enter present address </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Perment Address</label>
                            <small class="req"> *</small>
                            <textarea type="text" formControlName="D_Confirmaddress" class="form-control" ></textarea>
                            <div *ngIf="rObj.controls.D_Confirmaddress.invalid && (rObj.controls.D_Confirmaddress.dirty || rObj.controls.D_Confirmaddress.touched)">
                                <p class="input-error-msg">Please enter permanent address</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Driving License No.</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Driving_licence" class="form-control" >
                            <div *ngIf="rObj.controls.Driving_licence.invalid && (rObj.controls.Driving_licence.dirty || rObj.controls.Driving_licence.touched)">
                                <p class="input-error-msg">Please enter license number</p>
                            </div>
                        </div>
                        
                       
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveDriver()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                    Drivers List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="driverData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">VEHICLE NO</th>
                                    <th class="align-left">Driver Name</th>
                                    <th class="align-left">Phone No </th>
                                    <th class="align-left">Permanent Address </th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let driver of driverData">
                                <td class="align-left">
                                    <ng-container *ngFor="let v of vehicleData">
                                        <ng-container *ngIf="v.ID == driver.vehicle_no">
                                        {{v.vehicle_no}}
                                        </ng-container>  
                                    </ng-container>
                            </td>
                                <td class="align-left">{{driver.name}}</td>
                                <td class="align-left">{{driver.phone_no}}</td>
                                <td class="align-left">{{driver.permanent_addr}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openDriverEditModal(driver.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(driver.ID, 'driver', 'driver details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                        <div *ngIf="!driverData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>     
                    </div>
                </div>
            </div>
    </div>
        
</div>