<div class="console-content">

    <div class="console-content-header row">
        <h1 class="title col-md-3">Transport Report</h1>     
    </div>
    <div class="row">
        <div class="col-md-12 ">
            <div class="panel">
                <!-- <div class="panel-header">
                    <h1 id="panel_title" class="title col-md-4">Transport Report</h1>
                </div> -->
            
                <div class="panel-body">   
                    <form [formGroup]="rObj">
                        <div class="form-group">   
                            <div class="row">  
                                 
                                <div class="col-md-2">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Section
                                    <small class="req"> *</small></label>
                                    <select name="section" formControlName="Section" class="form-select" >
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let section of classSectionData" >
                                            <option [value]="section.id">{{section.name}}</option>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                        <p class="input-error-msg">Please select section</p>
                                    </div>
                                </div>
                                                        
                                <div class="col-md-3">
                                    <label>Route</label>
                                    <select formControlName="Route" class="form-control" required="">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>       
                                    </select>
                                    <div *ngIf="rObj.controls.Route.invalid && (rObj.controls.Route.dirty || rObj.controls.Route.touched)">
                                        <p class="input-error-msg">Please select route</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Pickup Point</label>
                                    <select formControlName="PickupPoint" class="form-control" required="">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let r of routePickupPoint" value="{{r.ID}}">{{r.pickup_point_name}}</option>       
                                    </select>
                                    <div *ngIf="rObj.controls.Route.invalid && (rObj.controls.Route.dirty || rObj.controls.Route.touched)">
                                        <p class="input-error-msg">Please select pickup point</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>  
                    </form>     
                </div>
                <div class="panel-body">

                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentDetailsData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Class (Section)</th>
                                <th>Admission No.</th>
                                <th class="align-left">Student Name</th>
                                <th>Mobile No.</th>
                                <th>Father Name</th>
                                <th>Route Name</th>
                                <th>Vehicle Number</th>
                                <th>Driver Name</th>
                                <th>Driver Contact</th>
                                <th>Fare</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentDetailsData">
                                <td class="align-left">{{s.class_name}}({{s.section_name}})</td>   
                                 <td class="align-left">{{s.admission_number}}</td>
                                <td>{{s.student_name}}</td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.father_name}}</td>
                                <td>{{s.route_name}}</td>
                                <td>{{s.vehicle_number}}</td>
                                <td>{{s.driver_name}}</td>
                                <td>{{s.driver_contact_number}}</td>
                                <td>{{s.fare}}</td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentDetailsData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>  
            
            </div>
        </div>
    </div>
</div>