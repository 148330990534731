<div class="console-content">
    <form [formGroup]="rObj">   
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link" [ngClass]="inventoryPurchaseReport ? 'active' : ''">
                        <a (click)="setTabActive('inventoryPurchaseReport')">Inventory Purchase Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="inventoryReport ? 'active' : ''">
                        <a (click)="setTabActive('inventoryReport')">Inventory Issue Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="stockReport ? 'active' : ''">
                        <a (click)="setTabActive('stockReport')">Inventory Stock Report</a>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="inventoryPurchase-details" class="panel panel-margin-top" [ngClass]="inventoryPurchaseReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Inventory Purchase Report</h1>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-3"> 
                                    <label>Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-6" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-md-2 btn-container">
                                    <button class="submit-btn primary-btn" type="submit" (click)="search()" style="margin-top: 22px;">Search</button>
                                </div>
                                
                            </div>
                        </div>
                               
                    </div>
                    <div class="panel-body">

                         <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryPurchaseData.length">
                            <thead>
                            <tr>
			                    <th class="align-left">Name</th>
			                    <th class="align-left">Category</th>
			                    <th class="align-left">Supplier </th>
			                    <th>Quantity</th>
			                    <th>Purchase Price</th>
			                    <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of inventoryPurchaseData">        
                                <td class="align-left">{{s.item_name}}</td>
                                <td class="align-left">{{s.category_name}}</td>
                                <td class="align-left">{{s.vendor_name}}</td>
                                <td>{{s.qty}}</td>
                                <td>{{s.cost}}</td>
                                <td>{{s.date}}</td>
                               
                            </tr>
                        </tbody>
                        </table>   
                        <div *ngIf="!inventoryPurchaseData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div> 
                    </div>  
                </div>
                <div id="inventory-details" class="panel panel-margin-top" [ngClass]="inventoryReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Inventory Issue Report</h1>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4"> 
                                    <label>Joining Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Period" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-6" *ngIf="rObj.controls.Period.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-md-2 btn-container">
                                    <button class="submit-btn primary-btn" type="submit" (click)="search()" style="margin-top: 22px;">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">

                         <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryIssueData.length">
                            <thead>
                            <tr>
			                    <th class="align-left">Name</th>
			                    <th class="align-left">Category</th>
			                    <th>Issue-Date </th>
                                <th>Return Date</th>
			                    <th class="align-left">Issue-To</th>
			                    <th class="align-left">Issue By</th>
			                    <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of inventoryIssueData">        
                                <td class="align-left">{{s.item_name}}</td>
                                <td class="align-left">{{s.category_name}}</td>
                                <td>{{s.date}}</td>
                                <td>{{s.return_date}}</td>
                                <td class="align-left">{{s.issue_to_name}}</td>
                                <td class="align-left">
                                    <ng-container *ngFor="let st of staffData">
                                        <ng-container *ngIf="st.ID == s.issue_by">
                                            {{st.full_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{s.qty}}</td>
                               
                            </tr>
                        </tbody>
                        </table>   
                        <div *ngIf="!inventoryIssueData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div> 
                    </div>    
                </div>
                <div id="stock-details" class="panel panel-margin-top" [ngClass]="stockReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Inventory Stock Report</h1>
                    </div>
                
                    <div class="panel-body">

                         <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="stockData.length">
                            <thead>
                            <tr>
			                    <th class="align-left">Name</th>
			                    <th class="align-left">Category</th>
			                    <th class="align-left">Supplier </th>
			                    <th>Initial Qunatity</th>
			                    <th>Purchase Quantity</th>
			                    <th>Issued Quantity</th>
			                    <th>Total Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of stockData">        
                                <td class="align-left">{{s.item_name}}</td>
                                <td class="align-left">{{s.category_name}}</td>
                                <td class="align-left">{{s.vendor_name}}</td>
                                <td>{{s.initial_qty}}</td>
                                <td>{{s.purchased_qty}}</td>
                                <td>{{s.issued_qty}}</td>
                                <td>{{getTotal(s.initial_qty,s.purchased_qty)}}</td>
                                 <!-- <td>{{s.initial_qty + s.purchased_qty}}</td> -->
                            </tr>
                        </tbody>
                        </table>   
                        <div *ngIf="!stockData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div> 
                    </div>    
                </div>
            </div>
        </div>

    </form>
</div>