<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-8">Edit Id Card </h1>
        <div class="header-msg col-md-6">
        </div>
        <div class="link-container col-md-3">
            <!--<a href="../../income/add/" class="content-header-link">Add Income</a>-->
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Edit Template
                    </div>
                </div>
                <div class="panel-body" >
                    <form [formGroup]="rObj">

                        <div class="form-group">
                          <label>Template Name</label>
                          <!-- <small class="req"> *</small> -->
                          <input type="text" formControlName="Template_Name" class="form-control" >    
                          <div *ngIf="rObj.controls.Template_Name.invalid && (rObj.controls.Template_Name.dirty || rObj.controls.Template_Name.touched)">
                              <p class="input-error-msg">Please enter Template name</p>
                          </div>                          
                      </div>

                      <div class="form-group">
                          <label>School Name</label>
                          <!-- <small class="req"> *</small> -->
                          <input type="checkbox" [checked]="rObj.controls.School_Name_Text.value" formControlName="School_Name" class="radio-inline" style="margin-left: 10px;">   
                          <ng-container *ngIf="checked">
                              <input type="text" formControlName="School_Name_Text" class="form-control">
                          </ng-container>    
                          <div *ngIf="rObj.controls.School_Name.invalid && (rObj.controls.School_Name.dirty || rObj.controls.School_Name.touched)">
                              <p class="input-error-msg">Please enter School Name</p>
                          </div>                          
                      </div>
                     
                      <!-- <div class="form-group">
                          <label>Student Name</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Student_Name" class="radio-inline">    
                          <div *ngIf="rObj.controls.Student_Name.invalid && (rObj.controls.Student_Name.dirty || rObj.controls.Student_Name.touched)">
                              <p class="input-error-msg">Please enter  Student name</p>
                          </div>                          
                      </div>
                      <div class="form-group">
                          <label>Class</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Class" class="radio-inline">    
                          <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                              <p class="input-error-msg">Please enter Class name</p>
                          </div>                          
                      </div> -->
                      <!-- <div class="form-group">
                          <label>Student Name</label>
                          <small class="req"> *</small>
                          <input type="text" formControlName="Student_Name" class="form-control" >    
                          <div *ngIf="rObj.controls.Student_Name.invalid && (rObj.controls.Student_Name.dirty || rObj.controls.Student_Name.touched)">
                              <p class="input-error-msg">Please enter Student name</p>
                          </div>                          
                      </div> -->
                      <!-- <div class="form-group">
                          <label>Section</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Section" class="radio-inline" >    
                          <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                              <p class="input-error-msg">Please enter Section</p>
                          </div>                          
                      </div> -->
                      
                      <!-- <div class="form-group">
                          <label>Student_Img</label>
                          <div class="imgUpload-wrapper">
                              <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                              <input type="file" formControlName="Student_Img" class="form-control" (change)="onImgChange($event)" >
                          </div>
                          <div *ngIf="rObj.controls.Student_Img.invalid && (rObj.controls.Student_Img.dirty || rObj.controls.Student_Img.touched)">
                              <p class="input-error-msg">Please select Student Img</p>
                          </div>                          
                      </div> -->

                      <!-- <div class="form-group">
                          <label>Roll No.</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Roll_No" class="radio-inline" >    
                          <div *ngIf="rObj.controls.Roll_No.invalid && (rObj.controls.Roll_No.dirty || rObj.controls.Roll_No.touched)">
                              <p class="input-error-msg">Please enter Roll No.</p>
                          </div>                          
                      </div>
                      <div class="form-group">
                          <label>Address</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Address" class="radio-inline" >    
                          <div *ngIf="rObj.controls.Address.invalid && (rObj.controls.Address.dirty || rObj.controls.Address.touched)">
                              <p class="input-error-msg">Please enter Address </p>
                          </div>                          
                      </div>
                      <div class="form-group">
                          <label>Phone No.</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Phone_No" class="radio-inline" >    
                          <div *ngIf="rObj.controls.Phone_No.invalid && (rObj.controls.Phone_No.dirty || rObj.controls.Phone_No.touched)">
                              <p class="input-error-msg">Please enter Phone No.</p>
                          </div>                          
                      </div> -->
                      <!-- <div class="form-group">
                          <label>Email</label>
                          <small class="req"> *</small>
                          <input type="checkbox" formControlName="Email" class="radio-inline" > 
                             
                          <div *ngIf="rObj.controls.Email.invalid && (rObj.controls.Email.dirty || rObj.controls.Email.touched)">
                              <p class="input-error-msg">Please enter Email</p>
                          </div>                          
                      </div> -->
                      <div class="form-group">
                          <label>School Address</label>
                          <!-- <small class="req"> *</small> -->
                          <input type="checkbox" [checked]="rObj.controls.School_address_Text.value" formControlName="School_address" class="radio-inline" style="margin-left: 10px;">    
                          <ng-container *ngIf="checked1">
                              <textarea formControlName="School_address_Text" class="form-control" >Address:</textarea>
                          </ng-container>   
                          <div *ngIf="rObj.controls.School_address.invalid && (rObj.controls.School_address.dirty || rObj.controls.School_address.touched)">
                              <p class="input-error-msg">Please enter Address</p>
                          </div>                          
                      </div>
                      <div class="form-group">
                          <label>School Phone</label>
                          <!-- <small class="req"> *</small> -->
                          <input type="checkbox" [checked]="rObj.controls.School_phone_Text.value" formControlName="School_phone" class="radio-inline" style="margin-left: 10px;"> 
                          <ng-container *ngIf="checked2">
                              <input type="text" formControlName="School_phone_Text" class="form-control">
                          </ng-container>     
                          <div *ngIf="rObj.controls.School_phone.invalid && (rObj.controls.School_phone.dirty || rObj.controls.School_phone.touched)">
                              <p class="input-error-msg">Please enter phone</p>
                          </div>                          
                      </div>

                      <div class="form-group">
                          <label>School Email</label>
                          <!-- <small class="req"> *</small> -->
                          <input type="checkbox" [checked]="rObj.controls.School_email_Text.value" formControlName="School_email" class="radio-inline" style="margin-left: 10px;"> 
                          <ng-container *ngIf="checked3">
                              <input type="email" formControlName="School_email_Text" class="form-control">
                          </ng-container>     
                          <div *ngIf="rObj.controls.School_phone.invalid && (rObj.controls.School_phone.dirty || rObj.controls.School_phone.touched)">
                              <p class="input-error-msg">Please enter email</p>
                          </div>                          
                      </div>

                      <div class="form-group">
                          <label>Background</label>
                          <div class="imgUpload-wrapper">
                              <div class="removeImgOverlay" (click)="removeBgImg()" *ngIf="bgimageSrc">Remove Image</div>  
                              <input type="file" formControlName="Background" class="form-control" (change)="onBgChange($event)" >
                          </div>
                          <div *ngIf="rObj.controls.Background.invalid && (rObj.controls.Background.dirty || rObj.controls.Background.touched)">
                              <p class="input-error-msg">Please select Background</p>
                          </div>                          
                      </div>

                      <div class="form-btn-wrapper">
                          <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="UpdateTemplate()">Save</button>
                      </div>
                              
                  </form>
                </div>           
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Preview
                    </div>
                </div>
                <div class="panel-body">
                    <div class="template-priview-wrapper row">
                        <!-- <img class="template-preview" src="{{template.preview_path}}"> -->
                        <div class="col-md-12">
                            <!-- <div id="bg-logo"></div> -->
                            <div id="htmlPreview" [innerHTML]="idCard_html"> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>  
</div>