<div class="console-content">
    <div class="console-content-header row">
        <h1 class="title col-md-3">Hostel Report</h1>     
    </div>
    <div class="row">
        <div class="col-md-12 ">
            <div class="panel">
                <!-- <div class="panel-header">
                    <h1 id="panel_title" class="title col-md-4">Transport Report</h1>
                </div> -->
            
                <div class="panel-body">   
                    <form [formGroup]="rObj">
                        <div class="form-group">   
                            <div class="row">    
                               <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>                                                       
                                <div class="col-md-3">
                                    <label>Hostel</label>
                                    <select formControlName="Hostel" class="form-control">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let h of hostelData" value="{{h.ID}}">{{h.name}}</option>       
                                    </select>
                                        <div *ngIf="rObj.controls.Hostel.invalid && (rObj.controls.Hostel.dirty || rObj.controls.Hostel.touched)">
                                            <p class="input-error-msg">Please select Hostel</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>  
                    </form>     
                </div>
                <div class="panel-body">

                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Class(Section)</th>
                                <th>Admission No</th>
                                <th class="align-left">Student Name</th>
                                <th>Mobile Number</th>
                                <th>Guardian Phone</th>
                                <th>Hostel Name</th>
                                <th>Room Number/ Name</th>
                                <th>Room Type</th>
                                <th>Cost Per Bed($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentData">
                                <td class="align-left">{{s.class_name}}({{s.section_name}})</td>                        
                                 <td class="align-left">{{s.admission_number}}</td>
                                <td>{{s.full_name}}</td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.guardian_mobile}}</td>
                                <td>{{s.hostel_name}}</td>
                                <td>{{s.room_no}}</td>
                                <td>{{s.room_type}}</td>
                                <td>{{s.cost_bed}}</td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>           
            </div>
        </div>
    </div>
</div>