<div class="console-content ">
        
                
    <div class="console-content-header row">
        <h1 class="title col-md-6">Assign Class Teacher</h1>
        <div class="header-msg col-md-5">
                      </div>
        <!-- <div class="link-container col-md-4">
            <a href="../../class/add/" class="content-header-link">Add Class</a>
            <a href="../../section/add/" class="content-header-link">Add Section</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Class Teacher
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Class Name</label>
                            <small class="req"> *</small>
                            <!-- <input type="" class="form-control" formControlName="Class"> -->
                            <select class="form-select" formControlName="Class">
                                <option value="">Select</option>
                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter class name</p>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label>Section</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Section">
                                <option value="">Select</option>
                                <ng-container *ngFor="let section of classSectionData" >
                                    <option [value]="section.id">{{section.name}}</option>
                                </ng-container>
                            </select>
                        </div> 
                        
                        <div class="form-group">
                            <label>Teacher</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Teacher">
                                <option value="">Select</option>
                                <option *ngFor="let teacher of teacherData" value="{{teacher.ID}}">{{teacher.full_name}}</option>
                            </select>
                        </div>
                        
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveAssignTeacher()">Save</button>
                        </div>
                </form>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Class Teacher List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="assignteacherData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Class Name</th>
                                    <th>Sections</th>
                                    <th>Class Teacher</th>
                                    <th class="no-sort">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let assignteacher of assignteacherData">
                                <td class="align-left">
                                    <ng-container *ngFor="let class of classData">
                                        <ng-container *ngIf="class.ID == assignteacher.class">
                                            {{class.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let s of sectionData">
                                        <ng-container *ngIf="s.ID == assignteacher.section">
                                            {{s.name}}
                                        </ng-container>  
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let teacher of  teacherData">
                                        <ng-container *ngIf="teacher.ID == assignteacher.class_teacher">
                                            {{teacher.full_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openAssignTeacherEditModal(assignteacher.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(assignteacher.ID, 'assign_teacher', 'assigned class teacher')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                        <div *ngIf="!assignteacherData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div>
                </div>
            </div>
    </div>
        
</div>
    
        
