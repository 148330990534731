import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';

@Component({
  selector: 'app-reports-studentReport',
  templateUrl: './studentReport.component.html',
})

export class studentReportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public mediumID = this.userData.activeMedium;

    public studentCountData: any[] = [];
    public classSectionData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public studentData: any[] = [];
    public guardianData: any[] = [];
    public SiblingData: any[] = [];
    public studentListData: any[] = [];
    public studentGenderData: any[] = [];
    public studentTeacherData: any[] = [];
    public classSubjectData: any[] = [];
    public siblingsData: any[] = [];
    public allStudentData: any[] = [];
    public getAdmissionData: any[] = [];
    public studentHistoryData: any[] = [];
    public genderRatio: any;
    public mediumData: any[] = [];
    
    public studentReports: boolean = true;
    public classSectionReports: boolean = false;
    public guardianReports: boolean = false;
    public studentHistoryReports: boolean = false;
    public classSubjectReports: boolean = false;
    public admissionReports: boolean = false;
    public siblingsReports: boolean = false;
    public studentGenderRatioReports: boolean = false;
    public studentTeacherRatioReports: boolean = false;
    
    public imgURL = this._gService.getImgPath();




    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Gender: [''],
        Category: [''],
        Search_type: [''],
        Date_to: [''],
        Date_From: [''],
        Medium: ['']
    })


    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._rService.getStudentHistoryData(this.instID).subscribe((hRes: any) => {
            console.log(hRes)
            if (hRes.status === true) {
                this.studentHistoryData = JSON.parse(hRes.result); 
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._rService.getStudentTeacherRatioData(this.instID, this.activeAcademicID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.studentTeacherData = JSON.parse(mRes.result);
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._rService.getStudentgenderRatioData(this.instID, this.activeAcademicID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.studentGenderData = JSON.parse(mRes.result);
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._rService.getStudentCountData(this.instID, this.activeAcademicID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.studentCountData = JSON.parse(mRes.result);
                console.log(this.studentCountData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.allStudentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    setTabActive(tabName: string) {
        if (tabName == 'studentReports') {
            this.studentReports = true
        } else {
            this.studentReports = false
        }
        if (tabName == 'classSectionReports') {
            this.classSectionReports = true
        } else {
            this.classSectionReports = false
        }
        if (tabName == 'guardianReports') {
            this.guardianReports = true
        } else {
            this.guardianReports = false
        }
        if (tabName == 'studentHistoryReports') {
            this.studentHistoryReports = true
        } else {
            this.studentHistoryReports = false
        }
        if (tabName == 'classSubjectReports') {
            this.classSubjectReports = true
        } else {
            this.classSubjectReports = false
        }
        if (tabName == 'admissionReports') {
            this.admissionReports = true
        } else {
            this.admissionReports = false
        }
        if (tabName == 'siblingsReports') {
            this.siblingsReports = true
        } else {
            this.siblingsReports = false
        }
        if (tabName == 'studentGenderRatioReports') {
            this.studentGenderRatioReports = true
        } else {
            this.studentGenderRatioReports = false
        }
        if (tabName == 'studentTeacherRatioReports') {
            this.studentTeacherRatioReports = true
        } else {
            this.studentTeacherRatioReports = false
        }
        
    }

    getTwoDigit(n : number) {
        if(n) {
        return n-2000
        }
        return 0
    }

    stringToArray(data: string) {
        return JSON.parse(data)
    }

    getStudentData(studentId: number, data: string){
        return this.allStudentData.filter(s => s.ID == studentId).map(s => s[data])
    }

    getRatio(count1: number, count2: number) {
        return this.genderRatio = count1 / count2;
    }
    
    gotoViewLink(classId: any, sectionId: any){
        this._router.navigate(['student/list/'+classId+'/'+sectionId])
    }

    gotoEditLink(dataID: number){
        this._router.navigate(['/student/edit/'+dataID])
    }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }



    openMediumEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    searchAdmission(){
        let rObj = this.rObj.value;
        this._rService.getAdmissionData(this.instID, this.activeAcademicID, rObj.Search_type, rObj.Date_From, rObj.Date_to).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.getAdmissionData = JSON.parse(mRes.result);
                console.log(this.getAdmissionData)  
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
    }

    searchGuardianReport(){
        let rObj = this.rObj.value;

        this._aService.studentListData(this.instID,this.activeAcademicID,rObj.Class,rObj.Section).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                //  this._router.navigate([this._router.url]);
                this.guardianData = JSON.parse(ayRes.result)
                console.log(this.guardianData)
                // this._gService.triggerSnackbar('Medium Saved Successfully', 'success', true);
            } else {
                this.guardianData = []
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
    }
    searchclassSubjectReport(){
        let rObj = this.rObj.value;
        this._rService.classSubjectData(this.instID, this.activeAcademicID,rObj.Class,rObj.Section, this.mediumID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classSubjectData = JSON.parse(mRes.result);
                console.log(this.classSubjectData)
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    }
    searchsiblingReport(){
        let rObj = this.rObj.value;
            
        this._aService.studentListData(this.instID,this.activeAcademicID,rObj.Class,rObj.Section).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                //  this._router.navigate([this._router.url]);
                this.SiblingData = JSON.parse(ayRes.result)
                console.log(this.SiblingData)

                this.siblingsData = JSON.parse(this.SiblingData[0].sibling_ids)
                console.log(this.siblingsData)  
                // console.log(this.studentData)
                // this._gService.triggerSnackbar('Medium Saved Successfully', 'success', true);
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
    }

    search() {
        // alert('yes')
        if (this.rObj.valid) {
            let rObj = this.rObj.value;  
            this._aService.studentListData(this.instID,this.activeAcademicID,rObj.Class,rObj.Section).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    //  this._router.navigate([this._router.url]);
                    let studentData = JSON.parse(ayRes.result)
                    console.log(studentData)

                    this.siblingsData = JSON.parse(studentData[0].sibling_ids)
                    console.log(this.siblingsData) 
                    if (rObj.Category && rObj.Gender) {
                        this.studentData = studentData.filter((s : any) => s.gender == rObj.Gender && s.category == rObj.Category)
                    } else if (rObj.Category) {
                        this.studentData = studentData.filter((c : any) => c.category == rObj.Category)
                    } else if (rObj.Gender) {
                        this.studentData = studentData.filter((g : any) => g.gender == rObj.Gender)
                    } else {
                        this.studentData = studentData
                    }
                    console.log(this.studentData)
                    // this._gService.triggerSnackbar('Medium Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}