<div class="modal-header">
    <h5 class="modal-title">Edit Class Subject</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Class Name</label>
                <small class="req"> *</small>
                <!-- <input type="" class="form-control" formControlName="Class"> -->
                <select class="form-select" formControlName="Class">
                    <option value="">Select</option>
                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>
                </select>
                <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>

            <div class="form-group">
                <label>Sections</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <label *ngFor="let section of classSectionData " formArrayName="Section" style="margin-right: 15px;">
                        <input type="checkbox" [value]="section.id" [checked]="sectionValue.includes(section.id) ? true : false" (change)="onSectionSelect($event)">
                        {{section.name}}
                    </label>
                </div>    
                <!-- <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                    <p class="input-error-msg">Please select a section</p>
                </div> -->
            </div>

            <!-- <div class="form-group">
                <label>Sections</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <label *ngFor="let section of classSectionData" formControlName="Section">
                        <input type="checkbox" [checked]="false" [value]="rObj.controls['Section'].value[i].value" [formControlName]="i" (change)="onSectionSelect(i, section.ID, $event)">
                        <input type="checkbox"  [value]="section.id" >
                        {{section.name}}           
                        {{check(section.id)}} {{section.id}}           
                    </label>

                    <label *ngFor="let section of classSectionData; let i = index" formArrayName="Section">
                        <input type="checkbox" [checked]="rObj.controls['Section'].value[i].isSelected == true ? true : false" [value]="section.id" [formControlName]="i" (change)="onSectionSelect($event)">
                        <input type="checkbox" [checked]="rObj.controls['Section'].value[i].isSelected == 'true' ? true : false" [value]="section.id" [formControlName]="i" (change)="onSectionSelect($event)">
                        {{rObj.controls['Section'].value[section.id].isSelected}} 
                        {{section.name}}
                    </label>
                </div>    
                <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                    <p class="input-error-msg">Please select a section</p>
                </div>                                                      
            </div>   -->

            <div class="form-group">
                <label>Subjects</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <label *ngFor="let subject of subjectData.slice(); let i = index" formArrayName="Subject">
                        <input type="checkbox" [value]="subject.ID" [checked]="subjectValue.includes(subject.ID)" (change)="onSubjectSelect($event)">
                         {{subject.name}} 
                    </label>
                </div>    
                <!-- <div class="section-list">
                    <label *ngFor="let subject of subjectData.slice(); let i = index" formArrayName="Subject">
                        <input type="checkbox" [checked]="checkSubject(rObj.controls['Subject'].value[i].value)" [value]="rObj.controls['Subject'].value[i].value" [formControlName]="i" (change)="onSubjectSelect($event)">
                        {{rObj.controls['Subject'].value[i].value}} {{checkSubject(rObj.controls['Subject'].value[i].value)}} {{subject.name}} 
                    </label>
                </div>     -->
                <!-- <div *ngIf="rObj.controls.Subject.errors?.['required'] && rObj.controls.Subject.touched">
                    <p class="input-error-msg">Please select a subject</p>
                </div> -->
            </div>

            <div class="modal-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateClass()">Update</button>
            </div>
        </form> 
    </div>
</div>
