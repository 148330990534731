import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransportService } from 'src/app/services/transport.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { InstService } from 'src/app/services/inst.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
    selector: 'app-students-addstudent',
    templateUrl: './addstudent.component.html',
    styleUrls: ['./addstudent.component.css']
})

export class addStudentComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public mediumID = this.userData.activeMedium;
    public activeAcademicSession : any;
    public roll_no : any;
    public admission_no : any;
    public Setting_admission_no : any;
    public Setting_roll_no : any;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public routeData: any[] = [];
    public hosteldetailsData: any[] = [];
    public hostelRoomsData: any[] = [];
    public studentData: any[] = [];
    public countriesData: any[] = [];
    public pickupPointData: any[] = [];
    public mediumData: any[] = [];
    public searchData: any[] = [];
    public imgURL = this._gService.getImgPath();
    public siblingIds : any[] = [];
    public siblingData : any[] = [];
    public pickupRouteData : any[] = [];
    public pickUpRouteArray: any[] = [];
    public pickUpRouteList: any[] = [];
    public InstituteData: any[] = [];
    public SettingData: any[] = [];
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];
    public totalStudents: any
    public allowedStudents: any
    public disabled: any
    imageSrc:any = '';
    status:boolean = false;

    file1Src:any = '';
    file1status:boolean = false;
    file2Src:any = '';
    file2status:boolean = false;
    file3Src:any = '';
    file3status:boolean = false;
    file4Src:any = '';
    file4status:boolean = false;

    dtOptions: any = {};

    modalRef: BsModalRef;

    public basicTab: boolean = true;
    public addressTab: boolean = false;
    public parentTab: boolean = false;
    public documentTab: boolean = false;
    public siblingsTab: boolean = false;
    public otherTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _studService: StudentService,
        private _dService: DashboardService,
        private _tService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private instService: InstService,
    ) {}

    selectEvent(item: any) {
        this.siblingIds.push(item.id)
        this.siblingData.push(item)
        console.log(this.siblingData)
        console.log(this.siblingIds)
    }

    onChangeSearch(val: string) {
        this._gService.getSearchData(val).subscribe(sRes => {
            console.log(sRes);
            if (sRes.status === true) {
                this.searchData = JSON.parse(sRes.result)
            }
        })
    }
    
    onFocused(e: any) {
        this.searchData = []
    }

    searchItem(item: any) {
        let searchImgSrc = item.photo ? 'https://apiuat.edunationpro.com/uploads/'+item.photo : '../../assets/img/default-institution-icon.png';
        let classSection = item.type == 'student' ? '<span class="search-meta">'+item.class_name+' ('+item.section_name+')</span>' : '';
        return '<div class="search-item-wrapper"><img class="search-img" src="'+searchImgSrc+'"> <div class="search-name">'+item.name + classSection+'</div><span class="search-result-type">Student</span></span><div>';
    }

    delSibling(siblingID: any) {
        this.siblingData.splice(this.siblingData.indexOf(siblingID), 1)
    }

    public rObj = this._fBuilder.group({
        Register_no: ['', Validators.required],
        Admission_date: ['',Validators.required],
        Class: ['',Validators.required], 
        Section: ['', Validators.required],
        Roll_no: ['', Validators.required],
        First_name: ['', Validators.required],
        Middle_name: ['', Validators.required],
        Last_name: ['', Validators.required],
        Birth_date: ['', Validators.required],
        Gender: ['', Validators.required],
        Blood_Group: ['', Validators.required],
        Mobile_no: ['', [Validators.required,Validators.pattern("[0-9 ]{10}")]],
        Email: ['', [Validators.required, Validators.email]],
        Adhar_no: [''],
        Birth_place: [''],
        Nationality: [''],
        Mother_tongue: [''],
        Category: [''],
        Religion:  [''],
        Caste: [''],
        Permanent_address: [''],
        Current_address: [''],
        City: [''],
        Pincode: [''],
        State: [''],
        Country: [''],
        Father_name:[''],
        Father_mobile:['',Validators.pattern("[0-9 ]{10}")],
        Father_occupation:[''],
        Father_photo: [''],
        Mother_name: [''],
        Mother_mobile: ['',Validators.pattern("[0-9 ]{10}")],
        Mother_occupation:[''],
        Mother_photo: [''],
        Guardian_is: ['father'],
        Guardian_name: [''],
        Guardian_relation: [''],
        Guardian_number:['',Validators.pattern("[0-9 ]{10}")],
        Guardian_email: [''],
        Guardian_occupation:[''],
        Guardian_income: [''],
        Guardian_photo: [''],
        Guardian_address: [''],
        Guardian_education:[''],
        Document1_title:[''],
        Document1_file: [''],
        Document2_title: [''],
        Document2_file: [''],
        Document3_title: [''],
        Document3_file: [''],
        Document4_title: [''],
        Document4_file:[''],
        Sibling_ids: [''],
        previous_school_name: [''],
        previous_school_address:[''],
        previous_qualification: [''],
        Fees_Month: [''],
        Pickup_Point: [''],
        Route_id: [''],
        hostel_room_id: [''],
        hostel_id:[''],
        PhotoUpload:[''],
       
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        this._sService.getSettingsData(this.instID).subscribe(aRes =>{
            console.log(aRes)
            if(aRes.status === true) {
                this.SettingData = JSON.parse(aRes.result)
                console.log(this.SettingData)
                this.Setting_admission_no = this.SettingData[0].autogenerate_admission_gr_no
                this.Setting_roll_no = this.SettingData[0].autogenerate_roll_no
                if (this.SettingData[0].autogenerate_admission_gr_no == 1) {
                    this.rObj.controls.Register_no.disable()
                } else {
                    this.rObj.controls.Register_no.enable()
                }
                if (this.SettingData[0].autogenerate_roll_no == 1) {
                    this.rObj.controls.Roll_no.disable()
                } else {
                    this.rObj.controls.Roll_no.enable()
                }

                this._studService.getLastAdmissionNoData(this.instID, this.activeAcademicID).subscribe(aRes =>{
                    console.log(aRes)
                    if(aRes.status === true) {
                        let adata = aRes.result
                        console.log((adata))
                        // let str = parseInt(adata.replace(/\D/g, "")) + 1
                        let str = this.SettingData[0].prefix ? parseInt(adata.replace(this.SettingData[0].prefix, "")) + 1 : parseInt(adata) + 1
                        console.log(str)
                        this.admission_no = this.SettingData[0].prefix ? this.SettingData[0].prefix + str.toString() : str.toString()
                        console.log(this.admission_no)
                    } else {
                        this.admission_no = this.SettingData[0].prefix ? this.SettingData[0].prefix + '1' : '1'
                    }
                })
            }
        })



        if (this.rObj.controls.Guardian_is.value == 'father' || this.rObj.controls.Guardian_is.value == 'mother') {
            this.rObj.controls.Guardian_name.disable() 
            this.rObj.controls.Guardian_number.disable() 
            this.rObj.controls.Guardian_occupation.disable()
            this.rObj.controls.Guardian_relation.disable()

        } else {
            this.rObj.controls.Guardian_name.enable() 
            this.rObj.controls.Guardian_number.enable() 
            this.rObj.controls.Guardian_occupation.enable()
            this.rObj.controls.Guardian_relation.enable()

        }

        // this.disabled = this.rObj.controls.Guardian_is.value == 'father' || this.rObj.controls.Guardian_is.value == 'mother'

        this._gService.getDataByID(this.activeAcademicID, 'academic_years').subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                let ayData = JSON.parse(ayRes.result)[0];
                console.log(ayData)
                this.activeAcademicSession = ayData.start_year +'-'+ ayData.end_year;
            }
        })
        // alert(this.activeAcademicID)

        this.instService.getInstDataByID(this.instID).subscribe(res => {
            console.log(res)
            this.InstituteData = JSON.parse(res.result)
            this.allowedStudents = this.InstituteData[0].allowed_users
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        // console.log(this.instID, this.activeAcademicID)

        // this._sService.getSettingsData(this.instID).subscribe(aRes =>{
        //     console.log(aRes)
        //     if(aRes.status === true) {
        //         this.SettingData = JSON.parse(aRes.result)
        //         console.log(this.SettingData)
        //         if (this.SettingData[0].autogenerate_admission_gr_no == 1) {
        //             this.rObj.controls.Register_no.disable()
        //         } else {
        //             this.rObj.controls.Register_no.enable()
        //         }
        //         if (this.SettingData[0].autogenerate_roll_no == 1) {
        //             this.rObj.controls.Roll_no.disable()
        //         } else {
        //             this.rObj.controls.Roll_no.enable()
        //         }

        //         this._studService.getLastAdmissionNoData(this.instID, this.activeAcademicID).subscribe(aRes =>{
        //             console.log(aRes)
        //             if(aRes.status === true) {
        //                 let adata = aRes.result
        //                 console.log((adata))
        //                 let str = parseInt(adata.replace(/\D/g, "")) + 1
        //                 console.log(str)
        //                 this.admission_no = this.SettingData[0].prefix + str.toString()
        //                 console.log(this.admission_no)
        //             }
        //         })
        //     }
        // })

        

        this._studService.getLastRollNoData(this.instID, this.activeAcademicID, this.rObj.controls.Class.value, this.rObj.controls.Section.value).subscribe(aRes =>{
            console.log(aRes)
            if(aRes.status === true) {
                let adata = JSON.parse(aRes.result)
                console.log(adata)
                
            }
        })

        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'pickup_points').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.pickupPointData = JSON.parse(cRes.result);
                console.log(this.pickupPointData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.routeData = JSON.parse(cRes.result);
                console.log(this.routeData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.routeData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.hosteldetailsData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.hostelRoomsData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.studentData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Route_id'].valueChanges.subscribe((value: any) => {
            // alert(value)
            this._gService.showLoader(true);
            this._tService.getPickUpRouteData(this.instID, value).subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    this.pickUpRouteList = JSON.parse(dataRes.result)
                    console.log(this.pickUpRouteList)
                }
            })
            this._gService.showLoader(false);
        })
       

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this._gService.showLoader(false);
        })

        this._dService.getStudentsCount(this.instID, this.activeAcademicID).subscribe(res =>{
            console.log(res)
            this.totalStudents = JSON.parse(res.result)
            console.log(this.totalStudents)
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }

    onSelect(value : any) {
        // this.disabled = true
        if (value == 'father' || value == 'mother') {
            this.rObj.controls.Guardian_name.disable() 
            this.rObj.controls.Guardian_number.disable() 
            this.rObj.controls.Guardian_occupation.disable()
            this.rObj.controls.Guardian_relation.disable()

        } else {
            this.rObj.controls.Guardian_name.enable() 
            this.rObj.controls.Guardian_number.enable() 
            this.rObj.controls.Guardian_occupation.enable()
            this.rObj.controls.Guardian_relation.enable()
        }
    }

    getLastRollNo(classID: any, sectionID: any){
        this._studService.getLastRollNoData(this.instID, this.activeAcademicID, classID, sectionID).subscribe(aRes =>{
            console.log(aRes)
            if(aRes.status === true) {
                let adata = JSON.parse(aRes.result)
                console.log(adata)
                this.roll_no = adata + 1
                console.log(this.roll_no)
            } else {
                this.roll_no = 1
            }
        })
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.imageSrc = '';
    }
    onDoc1FileChange(event:any) {
        this.file1status = false
        const file = event.target.files[0];
        this.file1status = event.target.files.length > 0 ? true : false;
        if (this.file1status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file1Src = reader.result;   
                 
           }
        }
        console.log(this.file1Src)   
        
    }

    removeDocument1() {
        this.file1Src = '';
    }
    onDoc2FileChange(event:any) {
        this.file2status = false
        const file = event.target.files[0];
        this.file2status = event.target.files.length > 0 ? true : false;
        if (this.file2status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file2Src = reader.result;   
                 
           }
        }
        console.log(this.file2Src)   
        
    }

    removeDocument2() {
        this.file2Src = '';
    }
    onDoc3FileChange(event:any) {
        this.file3status = false
        const file = event.target.files[0];
        this.file3status = event.target.files.length > 0 ? true : false;
        if (this.file3status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file3Src = reader.result;   
                 
           }
        }
        console.log(this.file3Src)   
        
    }

    removeDocument3() {
        this.file3Src = '';
    }
    onDoc4FileChange(event:any) {
        this.file4status = false
        const file = event.target.files[0];
        this.file4status = event.target.files.length > 0 ? true : false;
        if (this.file4status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file4Src = reader.result;   
                 
           }
        }
        console.log(this.file4Src)   
        
    }

    removeDocument4() {
        this.file4Src = '';
    }

    setTabActive(tabName: string) {
        if (tabName == 'basicTab') {
            this.basicTab = true
        } else {
            this.basicTab = false
        }
        if (tabName == 'addressTab') {
            this.addressTab = true
        } else {
            this.addressTab = false
        }
        if (tabName == 'parentTab') {
            this.parentTab = true
        } else {
            this.parentTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'siblingsTab') {
            this.siblingsTab = true
        } else {
            this.siblingsTab = false
        }
        if (tabName == 'otherTab') {
            this.otherTab = true
        } else {
            this.otherTab = false
        }
    }


    saveStudent() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                medium: this.mediumID ? this.mediumID : 0,
                register_number: this.SettingData[0].autogenerate_admission_gr_no == 1 ? this.admission_no : rObj.Register_no,
                admission_date: rObj.Admission_date,
                class: rObj.Class,
                section: rObj.Section,
                roll_no: this.SettingData[0].autogenerate_roll_no == 1 ? this.roll_no : rObj.Roll_no,
                first_name: rObj.First_name,
                middle_name: rObj.Middle_name,
                last_name: rObj.Last_name,
                dob: rObj.Birth_date,
                gender: rObj.Gender,
                blood_group: rObj.Blood_Group,
                birth_place: rObj.Birth_place,
                nationality: rObj.Nationality,
                mother_tongue: rObj.Mother_tongue,
                category: rObj.Category,
                religion: rObj.Religion,
                caste: rObj.Caste,
                aadhaar_number: rObj.Adhar_no  ? rObj.Adhar_no : '',
                mobile: rObj.Mobile_no ? rObj.Mobile_no : '',
                email: rObj.Email,
                permanent_address: rObj.Permanent_address,
                current_address: rObj.Current_address,
                city: rObj.City,
                pincode: rObj.Pincode ? rObj.Pincode : '', 
                state: rObj.State,
                country: rObj.Country,
                father_name: rObj.Father_name,
                father_mobile: rObj.Father_mobile ? rObj.Father_mobile : '',
                father_occupation: rObj.Father_occupation,
                mother_name: rObj.Mother_name,
                mother_mobile: rObj.Mother_mobile ? rObj.Mother_mobile : '',
                mother_occupation: rObj.Mother_occupation,
                guardian_is: rObj.Guardian_is,
                guardian_name: rObj.Guardian_is == 'father'? rObj.Father_name : rObj.Guardian_is == 'mother' ? rObj.Mother_name : rObj.Guardian_name,
                guardian_relation: rObj.Guardian_is == 'father'? 'father' : rObj.Guardian_is == 'mother' ? 'mother' : rObj.Guardian_relation,
                guardian_mobile: rObj.Guardian_number ? rObj.Guardian_is == 'father'? rObj.Father_mobile : rObj.Guardian_is == 'mother' ? rObj.Mother_mobile : rObj.Guardian_number : '',
                guardian_email: rObj.Guardian_email,
                guardian_occupation: rObj.Guardian_is == 'father'? rObj.Father_occupation : rObj.Guardian_is == 'mother' ? rObj.Mother_occupation : rObj.Guardian_occupation,
                guardian_education: rObj.Guardian_education,
                guardian_income: rObj.Guardian_income ? rObj.Guardian_income : '',
                guardian_address: rObj.Guardian_address,
                previous_school_name: rObj.previous_school_name,
                previous_school_address: rObj.previous_school_address,
                previous_qualification: rObj.previous_qualification,
                sibling_ids: this.siblingIds.length > 0 ? this.siblingIds.toString() : '',
                transport_fees_months: rObj.Fees_Month ? rObj.Fees_Month.toString() : '',
                transport_route_id: rObj.Route_id ? rObj.Route_id : '',
                transport_route_pickup_id: rObj.Pickup_Point ? rObj.Pickup_Point : '',
                hostel_id: rObj.hostel_id ? rObj.hostel_id : '',
                hostel_room_id: rObj.hostel_room_id ? rObj.hostel_room_id : '',
                photo_path: this.imageSrc,
                document1_title: rObj.Document1_title,
                document1_file: this.file1Src,
                document2_title: rObj.Document2_title,
                document2_file: this.file2Src,
                document3_title: rObj.Document3_title,
                document3_file: this.file3Src,
                document4_title: rObj.Document4_title,
                document4_file: this.file4Src,
            }

           
            console.log(sData)
            if (this.totalStudents < this.allowedStudents) {
                this._studService.studentData(sData).subscribe(sRes => {
                    console.log(sRes)
                        if (sRes.status === true) {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);                            
                            this._gService.triggerSnackbar('Student Details Saved Successfully', 'success', true);
                        } else {
                            this._gService.triggerSnackbar('Something went wrong', 'error', true);
                        }
                    
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
            } else {
                this._gService.triggerSnackbar('Number of Students Exceedded', 'error', true);

            }
            this._gService.showLoader(false);
        }
    }

}