<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Inventory Item</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../store-management/inventory-category/add" class="content-header-link">Add Inventory Category</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Inventory Item
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Category</label>
                            <small class="req"> *</small>
                            <select formControlName="Category_name" class="form-select" required="">
                                <option value="">Select</option>     
                                <option *ngFor="let c of inventoryCategoryData" value="{{c.ID}}">{{c.category_name}}</option>                     
                            </select>
                            <div *ngIf="rObj.controls.Category_name.invalid && (rObj.controls.Category_name.dirty || rObj.controls.Category_name.touched)">
                                <p class="input-error-msg">Please enter Category </p>
                            </div>	  		                    
                        </div>
                        <div class="form-group">
                            <label>Inventory Item Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Item_name" value="">
                            <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                                <p class="input-error-msg">Please enter Item name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Quantity</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="Qty">
                            <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                                <p class="input-error-msg">Please enter Qty </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea class="form-control" formControlName="Description" value="" rows="3"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter Description </p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveItem()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Item List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryItemData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Category</th>
                                <th class="align-left">Item Name</th>
                                <th class="align-left">Quantity</th>
                                <th class="align-left">description</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of inventoryItemData">
                                <td class="align-left">
                                    <ng-container *ngFor="let c of inventoryCategoryData">
                                        <ng-container *ngIf="c.ID == item.category">
                                            {{c.category_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">{{item.item_name}}</td>
                                <td>{{item.qty}}</td>
                                <td class="align-left"><p>{{item.description}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openItemEditModal(item.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(item.ID, 'inventory_item', 'inventory_item')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!inventoryItemData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>     
</div>