import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { ExaminationService } from 'src/app/services/examination.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StudentService } from 'src/app/services/student.service';
import { markRegistermodalComponent } from 'src/app/components/modal/markRegistermodal/markRegistermodal.component';
@Component({
  selector: 'app-examination-examResult',
  templateUrl: './examResult.component.html',
  styleUrls : ['./examResult.component.css']
})

export class ExamResultComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public examtimetableData: any[] = [];
    public examData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectsData: any[] = [];
    public teacherData: any[] = [];
    public teachersData: any[] = [];
    public ExaminationData: any[] = [];
    // public designationId: any;
    public mediumData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    
    public classId : any;
    public sectionId : any;
    public examTypeId : any;
    public mediumId = this.userData.activeMedium;
    openpanel:boolean = false;
    dtOptions: any = {};
    public totalFullMarks: any
    public StudentListData: any[] = [];
    public subjectNamesArray: any[] = [];
    public percentageArray: any[] = [];
    public studentIds: any[] = [];
    public rankArray: any[] = [];
    public result = false;
    public etPatchDataArray: FormGroup;
    modalRef: BsModalRef;
    constructor(
        private _gService: GlobalService,
        private _eService: ExaminationService,
        private _sService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        public modalService: BsModalService
    ) {}

   public rObj = this._fBuilder.group({
        Exam:['',Validators.required],
        Class:['',Validators.required],
        Section:['',Validators.required],
        Medium:[''],
        etData: this._fBuilder.array([]),
    })


    ngOnInit(): void {

        // this.classId = this._aRoute.snapshot.paramMap.get('class');
        // this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        // this.examTypeId = this._aRoute.snapshot.paramMap.get('examType')
        // if(this.classId && this.sectionId && this.examTypeId) {
        //     this.openpanel = true
        //     this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             console.log(result)
        //             let sectionsArray = JSON.parse(result[0].sections)
        //             let sectionNamesArray = result[0].section_names.split(',')
        //             console.log(sectionsArray, sectionNamesArray)

        //             this.classSectionData = JSON.parse(result[0].sections)

        //             this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
        //                 return {'id': section_id, 'name': sectionNamesArray[index]}
        //             })
        //             console.log(this.classSectionData)
        //         }
        //     })
        //     this.rObj.patchValue({
        //         Class : this.classId,
        //         Section : this.sectionId,
        //         Exam :this.examTypeId,
        //     })
        //     this.gotoSearch()
        
        // }

        // this._aService.timetableByExamTypeClassSectionData(this.instID, this.activeAcademicID, this.rObj.controls.Exam.value, this.rObj.controls.Class.value, this.rObj.controls.Section.value, this.mediumId).subscribe(res => {
        //     console.log(res)
        //     if (res.status == true) {
        //         let data = JSON.parse(res.result)
        //         console.log(data)
        //     }
        // })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            //console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // //console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'exam').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.examData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        

        // this._sService.studentListData(this.instID, this.activeAcademicID,this.classID,this.sectionID).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this._reRoute.shouldReuseRoute = () => false;
        //         this._router.navigate([this._router.url]);
        //         this.studentListData = JSON.parse(sRes.result)
        //         console.log(this.instID,this.activeAcademicID,this.examID,this.classID,this.sectionID)
        //         this._eService.getMarksData(this.instID, this.activeAcademicID, this.classID, this.sectionID, this.examID).subscribe(cRes => {
        //             console.log(cRes)
        //             if (cRes.status == true) {
        //                 this.studentMarksData = JSON.parse(cRes.result);
        //                     // alert('yes')
        //                     let marksRowArray = <FormArray>this.sObj.controls.marksRow;
        //                     marksRowArray.controls = this.studentMarksData.map(s => this._fBuilder.group({
        //                         Student_id: [s.student_id],
        //                         Student_name: [s.full_name],
        //                         Admission_no: [s.admission_number], 
        //                         Roll_no: [s.roll_no],
        //                         Marks: [s.marks], 
        //                         Remark: [s.remark],
        //                     }))
        //                     console.log(marksRowArray.controls)
        //             } else {
        //                 if (this.studentListData.length) {
        //                 // alert('No')
        //                 let marksRowArray = <FormArray>this.sObj.controls.marksRow;
        //                 marksRowArray.controls = this.studentListData.map(s => this._fBuilder.group({
        //                     Student_id: [s.ID],
        //                     Student_name: [s.full_name],
        //                     Admission_no: [s.admission_number], 
        //                     Roll_no: [s.roll_no],            
        //                     Marks: [''],
        //                     Remark: [''],
        //                 }))
        //             }
        //             }
        //         })


        //     } else {
        //         this.studentListData = [];
        //     }
        //     this._gService.showLoader(false);
        // }, err => {
        //     console.log(err);
        //     this._gService.triggerSnackbar('Server Error!', 'error', true);
        // })

        // this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this.teacherData = JSON.parse(sRes.result);
        //     }
        //     this._gService.showLoader(false);
        // })

         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
    
        this.dtOptions = {
                pagingType: 'numbers',
                order: [],
                columnDefs: [{
                    targets: 'no-sort',
                    orderable: false,
                }],
                dom: 'fBrtip',
                buttons: [
                    'copy', 'excel', 'csv', 'pdf', 'print',
                ],
                language: { search: '', searchPlaceholder: "Search..." },
            };
    }

    // gotoSearch() {
    //     let robj = this.rObj.value;
    //     this._reRoute.shouldReuseRoute = () => false;
    //     this._router.navigate(['/examination/exam-Schedule/list/'+robj.Exam+'/'+robj.Class+'/'+robj.Section]);
        
    // }
       

    // openAddMarksModal(ExamId: number, SubMarks: number, Sub_name: any) {
    //     this.modalRef = this.modalService.show(markRegistermodalComponent, {
    //         class: 'modal-dialog-centered marks-edit-modal-dialog form-modal-dialog',
    //         backdrop : 'static',
    //         keyboard : false,
    //         initialState : {
    //             subject_name: Sub_name,
    //             examID: ExamId,
    //             classID: this.rObj.controls.Class.value,
    //             sectionID: this.rObj.controls.Section.value,
    //             subjectMarks: SubMarks,
    //             exam_type: this.rObj.controls.Exam.value
    //         } 
    //     });
    // }

    getMarks(studentID: number, examID: number) {
        // console.log(studentID,examID)
        // console.log(this.StudentListData.filter(s => s.student_id == studentID && s.exam_id == examID).map(s => s.marks))   
        return this.StudentListData.filter(s => s.student_id == studentID && s.exam_id == examID).map(s => s.marks)
    }

    trackByID(index: number, item: any) {
        return item.student_id
    }

    getTotalMarks(student_id: number) {
        let marksData = this.StudentListData.filter(s => s.student_id == student_id).map(s => s.marks)
        // console.log(marksData)
        let totalMarks = 0
        totalMarks = marksData.reduce((a,b) => a + Number(b),0)   
        return totalMarks
    }

    getPercentage(totalMarks: number) {
        // let marksData = this.ExaminationData.filter(s => s.ID == examID).map(s => s.full_marks)
        let marksData = this.ExaminationData.map(s => s.full_marks)
        let totalFullMarks = 0
        this.totalFullMarks = marksData.reduce((a,b) => a + Number(b),0)
        // console.log(marksData) 
        let percentage = (totalMarks/this.totalFullMarks)*100
        // console.log(percentage)
        // return Math.round(percentage)
        return parseFloat(percentage.toFixed(2))
    }

    getResults( studentID: number) {
        let marks = this.StudentListData.filter(s => s.student_id == studentID).map(s => s.marks)
        //console.log(marks)
        let marksData = this.ExaminationData.map(s => s.passing_marks)
        let result =  marks.filter((m, index) => {
            return m > marksData[index]
        })
        //console.log(result)
        return marks.length == result.length ? true : false
    }

    getRank(percentage: number, studentId: number) {
        this.percentageArray.push(percentage);
        this.studentIds.push(studentId)
    
        let sorted = [...new Set(this.percentageArray)].sort((a, b) => b - a);
        //console.log(sorted)
        let rank = new Map(sorted.map((x, i) => [x, i + 1]));
        let rankArray = this.percentageArray.map((x) => rank.get(x));

        let studentRankArray = this.studentIds.reduce((map, key, index) => {
            map[key] = rankArray[index]
            return map;
          }, {});

        return Object.values(studentRankArray)[Object.keys(studentRankArray).indexOf(studentId.toString())]
    }

   

    search(){
        let robj = this.rObj.value; 
        if (robj.Class && robj.Section && robj.Exam) {

            this._sService.studentListData(this.instID, this.activeAcademicID, robj.Class, robj.Section).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
               
                    this._eService.timetableByExamClassSectionData(this.instID, this.activeAcademicID, robj.Exam, robj.Class, robj.Section).subscribe(res => {
                        //console.log(JSON.parse(res.result))
                        if (res.status === true) {
                           this.ExaminationData = JSON.parse(res.result)
                           //console.log(this.ExaminationData)
                           let Exam_ids = []
                            this.ExaminationData.forEach(e =>{
                                 Exam_ids.push(e.ID)
                            })
                            console.log(Exam_ids.toString())
                           //console.log(this.instID, this.activeAcademicID, robj.Class, robj.Section, Exam_ids.toString())
                            this._eService.getAllMarksData(this.instID, this.activeAcademicID, robj.Class, robj.Section, Exam_ids.toString()).subscribe(res => {
                                console.log(res)
                                this.StudentListData = JSON.parse(res.result);
                                console.log(this.StudentListData)
                            })
                        }
                    })                    
                } else {
                    this.StudentListData = []
                    this._gService.triggerSnackbar('No Data Found!', 'error', true);
                }
            })
        }
    }
    
    
}