import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';

@Component({
  selector: 'app-institution-add',
  templateUrl: './add.component.html'
})

export class institutionAddComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;
    dtOptions: DataTables.Settings = {};
    public total_month : any
    currentYear: number = new Date().getFullYear();
    years: number[] = [];
    currentMonth: number = new Date().getMonth() + 1;
    months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    imageSrc:any = '';
    public total:number = 0;
    status:boolean = false
    public date = new Date()
    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _instService: InstService,
        private _router: Router
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Address: ['', Validators.required],
        Branch: ['', Validators.required],
        Pincode: ['',[Validators.required, Validators.pattern('[0-9]{5}')]],
        Country: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email ]],//Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        Phone: ['', [Validators.required, Validators.pattern(/^(6|7|8|9)(\d{9})$/)]],
        Fax: [''],
        Is_Vernacular: ['', Validators.required], 
        Is_Multi_branch: ['', Validators.required], 
        Currency:[{value: 'INR', disabled: true}],
        Language:[{value: 'English', disabled: true}],
        Start_date: [moment(new Date()).format('YYYY-MM-DD'), Validators.required], 
        Renewal_date: ['', Validators.required], 
        Update_date: [{value : moment(new Date()).format('YYYY-MM-DD'), disabled: true }], 
        Secret_Key: [{value: this.secretKeyGenerater(48), disabled: true}], 
        Subscription_period: ['', Validators.required], 
        Allowed_users: [0, Validators.required], 
        Subscription_Cost: [0, Validators.required], 
        Total_cost: [{value: 0,disabled: true}], 
        First_name: ['', Validators.required], 
        Last_name: ['', Validators.required], 
        Admin_Email: ['', Validators.required], 
        StartYear: [this.currentYear, Validators.required],
        StartMonth: [this.currentMonth, Validators.required],
        EndYear: [this.currentYear, Validators.required],
        EndMonth: [this.currentMonth, Validators.required],
        
    })

    ngOnInit(): void {
        let yearsList = this.currentYear + 5;
        
        for (let year = yearsList; year >= 2020; year--) {
            this.years.push(year);
        }
    }

    getValue(allowedUsers: any, cost: any) {
        this.total = allowedUsers * cost
        console.log(this.total)
        return this.total
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    copyInputMessage(inputElement: any){
       if(navigator.clipboard.writeText(inputElement)) {
            this._gService.triggerSnackbar('Copied', 'success', true)
       }
        
    }

    secretKeyGenerater(length: number) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; 
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    addInst() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            console.log(this.rObj.controls.Secret_Key.value)
            let instData = {
                action: 'save',
                inst_name: rObj.Name,
                isMultiBranch: rObj.Is_Multi_branch,
                isVernacular: rObj.Is_Vernacular,
                inst_address: rObj.Address,
                inst_branch: rObj.Branch,
                inst_pincode: rObj.Pincode,
                inst_country: rObj.Country,
                inst_email: rObj.Email,
                inst_phone: rObj.Phone,
                inst_fax: rObj.Fax,
                inst_currency: "INR",
                inst_language: "English",
                inst_key: this.rObj.controls.Secret_Key.value,
                start_date: rObj.Start_date,
                renewal_date: rObj.Renewal_date,
                update_date:rObj. Update_date,
                subscription_period: rObj.Subscription_period,
                allowed_users: rObj.Allowed_users,
                per_user_cost: rObj.Subscription_Cost,
                total_cost: this.total,
                icon_path: this.imageSrc,
                firstname: rObj.First_name,
                lastname: rObj.Last_name,
                email: rObj.Admin_Email,
                start_year: rObj.StartYear,
                start_month: rObj.StartMonth,
                end_year: rObj.EndYear,
                end_month: rObj.EndMonth
            }
    
            console.log(instData)
            this._adminService.addInstData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._router.navigate(['/admin/institution/list']);
                    this._gService.triggerSnackbar('Institution Added Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        } else {
            this._gService.triggerSnackbar('Please fill the red mark fields', 'error', true);
        }
    }

    Calculatemonth() {

        let start_date = this.rObj.controls.Start_date.value ? this.rObj.controls.Start_date.value : 0
        let date_start = new Date (start_date)
        let start_year = date_start.getFullYear()
        let month_start = date_start.getMonth()

        let end_date = this.rObj.controls.Renewal_date.value ? this.rObj.controls.Renewal_date.value : 0
        let date_end = new Date (end_date)
        let end_year = date_end.getFullYear()
        let month_end = date_end.getMonth()

        console.log(start_date, end_date)
        this.total_month= (end_year - start_year)*12 + (month_end - month_start)
        console.log(this.total_month)
    }
}