<header class="console-header">
    <div class="console-header-content">
        <div class="console-logo" [ngClass]="sidebarStatus ? 'collapse' : ''">
            <div class="icon"><img src="/assets/img/enp-header-icon.png"></div>
            <div class="logo"><img src="/assets/img/enp-header-logo.png"></div>
            <span>v 1.0</span>
        </div>
        <div class="console-header-item nav-control" (click)="toggleSidebar()">
            <span [ngClass]="sidebarStatus ? 'active' : ''">
                <i class="fas fa-bars"></i>
            </span>
        </div>
        <div class="console-header-item header-search-wrapper">
           <h3 class="text">Welcome, {{userName}}</h3>
            <!-- <input type="text" class="form-control search" placeholder="Search...">
            <button class="header-search-btn" name="header_search"><i class="fas fa-search"></i></button> -->
        </div>
        <ul class="console-header-items-right">
            <li class="console-header-item header-notification has-dd">
                <span (click)="showNotifications($event)" [ngClass]="showNotificationsClass ? 'active' : ''"><i class="fa-bell" [ngClass]="showNotificationsClass ? 'fas' : 'far'"></i></span>
                <div class="console-header-dd header-notification-info" [ngClass]="showNotificationsClass ? 'show' : ''" (click)="$event.stopPropagation()">
                    <div class="notification-info-data">
                        <h5>No new notifications</h5>
                    </div>
                    <div class="notification-link">
                        <a class="view-all-link" href="/notifications/">
                            View All
                        </a>
                    </div>
                </div>
                
            </li>
            <li class="console-header-item header-user has-dd">
                <span (click)="showUserInfo($event)" [ngClass]="showUserInfoClass ? 'active' : ''"><img src="{{imageSrc ? imgURL+imageSrc : '/assets/img/default-user-avatar.png'}}"></span>
                <div class="console-header-dd header-user-info" [ngClass]="showUserInfoClass ? 'show' : ''" (click)="$event.stopPropagation()">
                    <div class="user-info-data">
                        <div class="user-img">
                            <img src="{{imageSrc ? imgURL+imageSrc : '/assets/img/default-user-avatar.png'}}">
                        </div>
                        <div class="user-info">
                            <h5>{{userName}}</h5>
                            <h6>{{userRoleName}}</h6>
                            <a class="edit-profile-link" href="/admin/edit/profile/">
                                <i class="far fa-user" style="font-size: 13px; padding: 0px;"></i>
                                <span style="margin-left: 2px;">Profile</span>
                            </a>
                        </div>
                    </div>
                    <div class="user-info-links">
                        <a class="edit-profile-link" (click)="openChnagePasswordmodal()">
                            <i class="far fa-user"></i>
                            <span>Change Password</span>
                        </a>
                        <a class="logout-link" (click)="logOut()">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</header>