import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import Chart, { plugins } from 'chart.js/auto';
import { FormBuilder} from '@angular/forms';
import { CalendarEvent, CalendarDateFormatter, CalendarView } from 'angular-calendar';
import { CustomDateFormatter } from 'src/app/providers/custom-date-formatter.provider';  
import * as moment from 'moment';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter,
    },
],
})

// export class AdminDashboardComponent {}

export class AdminDashboardComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser'));
    public userName = this.userData.Name;
    public UserRole = this.userData.UserRole;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public totalStudents: number = 0;
    public totalTeachers: number = 0;
    public totalEmployees: number = 0;
    public totalEarnings: number = 0;

    public genderChart: any;
    public attendanceChart: any;
    public feesCollectionChart: any;

    public attendancePeriod: string = 'this_week';
    public attendanceClass: number = 98;
    public attendanceSection: number = 48;

    public circularData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];

    dtOptions: any = {};
    public imgURL = this._gService.getImgPath();

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate = new Date();
    events: CalendarEvent[] = [];

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (moment(date).isSame(this.viewDate, 'month')) {
            if ((moment(date).isSame(this.viewDate, 'day') && this.activeDayIsOpen === true) || events.length === 0) {
              this.activeDayIsOpen = false;
            } else {
              this.activeDayIsOpen = true;
            }
            this.viewDate = date;
          }
    }

    activeDayIsOpen: boolean = false;

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    constructor(
        private _gService: GlobalService,
        private _userService: UserService,
        private _dService: DashboardService,
        private _fBuilder: FormBuilder,
    ) {}

    public rObj = this._fBuilder.group({
        Class: [''], 
        Section: ['']
    })

    ngOnInit(): void {

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'circulars').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.circularData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } 
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } 
            this._gService.showLoader(false);
        })


        this._dService.getStudentsCount(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.totalStudents = sRes.result
            }
        })

        this._dService.getStaffCount(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.totalTeachers = sRes.result.teachers_count;
                this.totalEmployees = sRes.result.employees_count;
            }
        })

        this._dService.getEarningsData(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.totalEarnings = sRes.result;
            }
        })

        this._dService.getstudentGenderData(this.instID, this.activeAcademicID).subscribe(gRes => {
            console.log(gRes)
            if (gRes.status === true) {
                let genderCountData = JSON.parse(gRes.result)[0]
                console.log(genderCountData)
                this.createGenderChart(genderCountData.male_students_count, genderCountData.female_students_count);
            }
        })

        this._dService.getstudentAttendanceData(this.instID, this.activeAcademicID, this.attendanceClass, this.attendanceSection, this.attendancePeriod).subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                let attendanceData = JSON.parse(aRes.result);
                console.log(attendanceData)
                let labelData = attendanceData.map((a : any) => a.date)
                let presentData = attendanceData.map((a : any) => a.present_count)
                let absentData = attendanceData.map((a : any) => a.absent_count)
                this.createAttendanceChart(labelData, presentData, absentData);
            } else {
                this.createAttendanceChart([], [], [])
            }
        })

        this._dService.getFeesCollectionData(this.instID, this.activeAcademicID).subscribe(fRes => {
            console.log(fRes)
            if (fRes.status === true) {
                let feesData = JSON.parse(fRes.result);
                console.log(feesData)
                let labelData = feesData.map((f : any) => f.month)
                let feesCollectedtData = feesData.map((f : any) => f.fees_collected)
                let feesDueData = feesData.map((f : any) => f.fees_due)
                this.createFeesCollectionChart(labelData, feesCollectedtData, feesDueData);
            } else {
                this.createFeesCollectionChart([], [], [])
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'events').subscribe(eRes => {
            console.log(eRes);
            if (eRes.status === true) {
                let eventData = JSON.parse(eRes.result);
                console.log(eventData)
                eventData.forEach((event: any) => {
                    let eventData = {
                        title: event.event_name,
                        start: new Date(event.start_date)
                    }
                    console.log(eventData)
                    this.events.push(eventData);
                    // this.refresh.next();
                    console.log(this.events)
                    this.viewDate = new Date();
                });
            }
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)

                }
            })
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    
    }

    createGenderChart(maleCount: number, femaleCount: number) {
        this.genderChart = new Chart('genderChart', {
            type: 'doughnut',
            data: {
                labels: [
                    'Boys:' + maleCount,
                    'Girls:' + femaleCount
                ],
                datasets: [{
                    label: 'Gender',
                    data: [maleCount, femaleCount],
                    backgroundColor: [
                        '#1DCA7F',
                        '#5B5B5B'
                    ],
                    hoverOffset: 4
                }]
            },
            options: {
                cutout: '75%',
                plugins: {
                    title: {
                        display: false,
                        text: 'Custom Chart Title',
                        padding: {
                            top: 10,
                            bottom: 30
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            padding: 30
                          }

                    }
                }
            },
        });
    }

    setAttendancePeriod(e: any) {
        this.attendancePeriod = e.target.value;
        console.log(e.target.value)
        this._dService.getstudentAttendanceData(this.instID, this.activeAcademicID, this.attendanceClass, this.attendanceSection, this.attendancePeriod).subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                let attendanceData = JSON.parse(aRes.result);
                console.log(attendanceData)
                let labelData = attendanceData.map((a : any) => a.date)
                let presentData = attendanceData.map((a : any) => a.present_count)
                let absentData = attendanceData.map((a : any) => a.absent_count)
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart(labelData, presentData, absentData);
            } else {
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart([], [], [])
            }
        })
    }

    setAttendanceClass(e: any) {
        this.attendanceClass = e.target.value;
        console.log(e.target.value)
        this._dService.getstudentAttendanceData(this.instID, this.activeAcademicID, this.attendanceClass, this.attendanceSection, this.attendancePeriod).subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                let attendanceData = JSON.parse(aRes.result);
                console.log(attendanceData)
                let labelData = attendanceData.map((a : any) => a.date)
                let presentData = attendanceData.map((a : any) => a.present_count)
                let absentData = attendanceData.map((a : any) => a.absent_count)
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart(labelData, presentData, absentData);
            } else {
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart([], [], [])
            }
        })
    }

    setAttendanceSection(e: any) {
        this.attendanceSection = e.target.value;
        console.log(e.target.value)
        this._dService.getstudentAttendanceData(this.instID, this.activeAcademicID, 99, 49, this.attendancePeriod).subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                let attendanceData = JSON.parse(aRes.result);
                console.log(attendanceData)
                let labelData = attendanceData.map((a : any) => a.date)
                let presentData = attendanceData.map((a : any) => a.present_count)
                let absentData = attendanceData.map((a : any) => a.absent_count)
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart(labelData, presentData, absentData);
            } else {
                if (this.attendanceChart) this.attendanceChart.destroy();
                this.createAttendanceChart([], [], [])
            }
        })
    }

    createAttendanceChart(labelData: any, presentData: any, absentData: any) {
        this.attendanceChart = new Chart('attendanceChart', {
          type: 'bar', //this denotes tha type of chart
    
          data: {// values on X-Axis
            // labels: ['2022-05-10', '2022-05-11', '2022-05-12','2022-05-13','2022-05-14', '2022-05-15', '2022-05-16','2022-05-17', ], 
            labels: labelData,
               datasets: [
              {
                label: 'Present',
                // data: ['467','576', '572', '79', '92', '574', '573', '576'],
                data: presentData,
                backgroundColor: '#1DCA7F',
                borderColor: "rgba(0,0,0,0)",
                borderWidth: 5,
                barThickness: 30,
                maxBarThickness: 30,
                borderRadius: {
                    topLeft: 20,
                    topRight: 20
                }
              },
              {
                label: 'Absent',
                // data: ['542', '542', '536', '327', '17', '0.00', '538', '541'],
                data: absentData,
                backgroundColor: '#5B5B5B',
                borderColor: "rgba(0,0,0,0)",
                borderWidth: 5,
                barThickness: 30,
                maxBarThickness: 30,
                borderRadius: {
                    topLeft: 20,
                    topRight: 20
                }
              }  
            ]
          },
          options: {
            aspectRatio:2.5,
            layout: {
                padding: 20,
            },
            plugins: {
                legend: {
                    display: true,
                    // position: 'bottom',
                    labels: {
                        padding: 20
                    },
                    title: {
                        padding: {
                            left: 50
                        }
                    }
                }
            }
          },
          
        });
        console.log(this.attendanceChart.options.plugins.legend.fit)
        if (this.attendanceChart.data.labels.length === 0) {
            // Display an empty chart
            this.attendanceChart.options.plugins.title.display = true;
            this.attendanceChart.options.plugins.title.text = 'No data to display';
            this.attendanceChart.update();
          }
    }

    createFeesCollectionChart(labelData: any, feesCollectedData: any, feesDueData: any) {
        this.feesCollectionChart = new Chart('feesCollectionChart', {
          type: 'bar', //this denotes tha type of chart
    
          data: {// values on X-Axis
            // labels: ['2022-05-10', '2022-05-11', '2022-05-12','2022-05-13','2022-05-14', '2022-05-15', '2022-05-16','2022-05-17', ], 
            labels: labelData,
               datasets: [
              {
                label: 'Present',
                // data: ['467','576', '572', '79', '92', '574', '573', '576'],
                data: feesCollectedData,
                backgroundColor: '#1DCA7F',
                borderColor: "rgba(0,0,0,0)",
                borderWidth: 5,
                barThickness: 30,
                maxBarThickness: 30,
                borderRadius: {
                    topLeft: 20,
                    topRight: 20
                }
              },
              {
                label: 'Absent',
                // data: ['542', '542', '536', '327', '17', '0.00', '538', '541'],
                data: feesDueData,
                backgroundColor: '#5B5B5B',
                borderColor: "rgba(0,0,0,0)",
                borderWidth: 5,
                barThickness: 30,
                maxBarThickness: 30,
                borderRadius: {
                    topLeft: 20,
                    topRight: 20
                }
              }  
            ]
          },
          options: {
            aspectRatio:2.5,
            layout: {
                padding: 20,
            },
            plugins: {
                legend: {
                    display: true,
                    // position: 'bottom',
                    labels: {
                        padding: 20
                    },
                    title: {
                        padding: {
                            left: 50
                        }
                    }
                }
            }
          },
          
        });
        console.log(this.feesCollectionChart.options.plugins.legend.fit)
        if (this.feesCollectionChart.data.labels.length === 0) {
            // Display an empty chart
            this.feesCollectionChart.options.plugins.title.display = true;
            this.feesCollectionChart.options.plugins.title.text = 'No data to display';
            this.feesCollectionChart.update();
          }
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    logOut() {
        this._userService.userLogout();
    }
}