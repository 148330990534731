<div class="console-content ">
    
    <div class="row">
        
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper primary-color">
                            <span class="data-icon students-icon"></span>
                            <!-- <i class="fas fa-user-graduate data-icon"></i> -->
                        </div>
                        <div class="data">{{totalStudents}}</div>
                    </div>
                    <div class="data-title">Total Students</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper secondary-color">
                            <span class="data-icon teachers-icon"></span>
                            <!-- <i class="fa-solid fa-chalkboard-user data-icon"></i> -->
                        </div>
                        <div class="data">{{totalTeachers}}</div>
                    </div>
                    <div class="data-title">Total Teachers</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper primary-color">
                            <span class="data-icon employees-icon"></span>
                            <!-- <i class="fas fa-user-tie data-icon"></i> -->
                        </div>
                        <div class="data">{{totalEmployees}}</div>
                    </div>
                    <div class="data-title">Total Employees</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card hover-card">
                <div class="card-body">
                    <div class="data-wrapper">
                        <div class="data-icon-wrapper secondary-color">
                            <span class="data-icon earnings-icon"></span>
                            <!-- <i class="fa-regular fa-money-bill-1 data-icon"></i> -->
                        </div>
                    <div class="data"><span class="inr-symbol">₹</span>{{totalEarnings}}</div>
                    </div>
                    <div class="data-title">Total Earnings</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="card hover-card">
                <div class="card-header">
                    <h5 class="card-title align-center">Total Students by Gender</h5>
                </div>
                <div class="card-body">
                    <div class="gender-chart-wrapper">
                    <canvas id="genderChart" width="400" height="400"></canvas>
                    <span class="chart-center-text">{{totalStudents}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card hover-card">
                <div class="card-header flex">
                    <h5 class="card-title">Attendance</h5>
                    <form [formGroup]="rObj">
                    <div class="right-options-wrapper">
                        <select class="card-select" (change)="setAttendancePeriod($event)">
                            <option value="this_week">This week</option>
                            <option value="last_week">Last week</option>
                            <option value="this_month">This Month</option>
                            <option value="last_month">Last Month</option>
                            <option value="last_3_month">Last 3 Months</option>
                            <option value="last_6_month">Last 6 Months</option>
                            <option value="last_12_month">Last 12 Month</option>
                        </select>
                        <select class="card-select" formControlName="Class" (change)="setAttendanceClass($event)">
                            <option value="">Please Select</option>
                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option> 
                        </select>
                        <select class="card-select" formControlName="Section" (change)="setAttendanceSection($event)">
                            <option value="">Please Select</option>
                            <ng-container *ngFor="let section of classSectionData" >
                                <ng-container *ngFor="let s of sectionData">
                                    <ng-container *ngIf="s.ID == section">
                                        <option [value]="section">{{s.name}}</option>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div>
                    </form>
                </div>
                <div class="card-body">
                    <canvas id="attendanceChart" width="400" height="400"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <div class="card hover-card">
                <div class="card-header flex">
                    <h5 class="card-title">Fees Collection</h5>
                    <div class="right-options-wrapper">
                        <select class="card-select" (change)="setAttendancePeriod($event)">
                            <option value="this_week">This week</option>
                            <option value="last_week">Last week</option>
                            <option value="this_month">This Month</option>
                            <option value="last_month">Last Month</option>
                            <option value="last_3_month">Last 3 Months</option>
                            <option value="last_6_month">Last 6 Months</option>
                            <option value="last_12_month">Last 12 Month</option>
                        </select>
                        <!-- <select class="card-select" (change)="setAttendanceClass($event)">
                            <option value="98">98</option>
                            <option value="99">99</option>
                        </select>
                        <select class="card-select" (change)="setAttendanceSection($event)">
                            <option value="48">48</option>
                            <option value="49">49</option>
                        </select> -->


                        <select class="card-select" formControlName="FeeCollection_Class" (change)="setAttendanceClass($event)">
                            <option value="">Please Select</option>
                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option> 
                        </select>
                        <select class="card-select" formControlName="FeeCollection_Section" (change)="setAttendanceSection($event)">
                            <option value="">Please Select</option>
                            <ng-container *ngFor="let section of feeCollectionclassSectionData">
                                <ng-container *ngFor="let s of sectionData">
                                    <ng-container *ngIf="s.ID == section">
                                        <option [value]="section">{{s.name}}</option>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="card-body">
                    <canvas id="feesCollectionChart" width="400" height="400"></canvas>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card hover-card">
                <div class="card-header flex">
                    <h5 class="card-title">Event Calender</h5>
                    <div class="right-options-wrapper">
                        <button class="calender-nav-btn prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="fa fas fa-angle-left"></i></button>
                        <button class="calender-nav-btn today" mwlCalendarToday [(viewDate)]="viewDate">Today</button>
                        <button class="calender-nav-btn next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="fa fas fa-angle-right"></i></button>
                    </div>
                </div>
                <div class="card-body" style="padding-top: 0;">
                    <h5 class="calender-title align-center">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h5>
                    <ng-template #customCellTemplate let-day="day" let-locale="locale">
                        <div class="cal-cell-top" [ngClass]="day.badgeTotal > 0 ? 'highlight': ''"> 
                          <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
                          >
                        </div>
                    </ng-template>
                    <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" (dayClicked)="dayClicked($event.day)" [cellTemplate]="customCellTemplate" [activeDayIsOpen]="activeDayIsOpen"></mwl-calendar-month-view>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            
            <div class="card hover-card">
                <div class="card-header">
                    <h5 class="card-title">Notice Board</h5>
                </div>
                <div class="card-body noticeboard">
                    <table *ngIf="circularData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Title</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Attachment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of circularData">
                                <ng-container *ngIf="UserRole == 4 ? c.sent_to == 4 || c.sent_to == 0: (UserRole == 5 ? c.sent_to == 6 : c.sent_to == 4 || c.sent_to == 6 || c.sent_to == 0)">
                                    <td class="align-left">{{c.title}}</td>
                                    <td><p>{{c.description}}</p></td>
                                    <td>{{getDate(c.date)}}</td>
                                    <td>
                                        <ng-container *ngIf="c.attachment">
                                            <a href="{{imgURL}}{{c.attachment}}" target="_blank">view document</a>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </tr>                           
                        </tbody>
                    </table> 
                </div>
            </div>
        </div>
    </div>       
</div>
