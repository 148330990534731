<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Users</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Medium</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        User List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="adminUsersData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Name</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <!-- <th>Password</th> -->
                                <th>User Role</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of adminUsersData">
                                <td class="align-left">{{a.name}}</td>
                                <td>{{a.username}}</td>
                                <td>{{a.email}}</td>
                                <!-- <td>{{a.password}}</td> -->
                                <td>{{getuserName(a.userRole)}}</td>

                                <td class="action-btn">
                                    <a tooltip="Change Password" class="edit-btn" (click)="openChangePassword(a.ID)"><i class="fa fa-key" aria-hidden="true"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="openUsersEditModal(a.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(a.ID, 'admin_users', 'user')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!adminUsersData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>