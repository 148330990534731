import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InstService } from 'src/app/services/inst.service';

@Component({
    selector: 'app-settings-general',
    templateUrl: './general.component.html',
    styles: [
        ' .form-group .input-edit-btn.active {cursor: auto;color: #ABABAB}', 
        ' .form-group .input-edit-btn {position: absolute;display: block; bottom: 138px;right: 36%;cursor: pointer;color: #555555;}',
        ' .form-group .input-edit-btn i {font-size: 18px;}'
    ]
})

export class GeneralComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userRole = this.userData.UserRole;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public designationData: any[] = [];
    public departmentData: any[] = [];
    public countriesData: any[] = [];
    public staffData: any[] = [];
    public userRoleData: any[] = [];
    public mediumData: any[] = [];
    public academicYearsData: any[] = [];
    public startMonth: any;
    public endMonth: any;

    
    imageSrc:any = '';
    status:boolean = false;
    image2Src:any = '';
    status2:boolean = false;
    // file2Src:any = '';
    // file2status:boolean = false;
    // file3Src:any = '';
    // file3status:boolean = false;
    // file4Src:any = '';
    // file4status:boolean = false;
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];
    public imgURL = this._gService.getImgPath();


    dtOptions: any = {};

    modalRef: BsModalRef;

    public basicTab: boolean = true;
    public addressTab: boolean = false;
    public documentTab: boolean = false;
    public payrollTab: boolean = false;
    public otherTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _instService: InstService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Inst_name: ['', Validators.required],
        Inst_address: ['',Validators.required],
        Inst_branch: ['',Validators.required], 
        Inst_pincode: ['', Validators.required],
        Inst_country: ['', Validators.required],
        Inst_email: ['',[Validators.required,Validators.email]],
        Inst_phone: ['', Validators.pattern("[0-9 ]{10}")],
        Inst_fax: ['', Validators.required],
        Inst_code: ['', Validators.required],
        Inst_currency: ['', Validators.required],
        Inst_language: ['',Validators.required],
        Current_session_id: [''],
        Start_month: [{value: '', disabled: true}],
        End_month: [{value: '', disabled: true}],
        Start_subscription_date: [{value:'', disabled: true}],
        Renewal_date: [{value:'', disabled: true}],
        Update_date:  [{value:'', disabled: true}],
        Inst_key: [{value:'', disabled: true}],
        Subscription_period: [{value:'', disabled: true}],
        Allowed_users: [{value:'', disabled: true}],
        Per_user_cost: [{value:'', disabled: true}],
        Total_cost: [{value:'', disabled: true}],
        Is_Vernacular: [{value:'0', disabled: true}],
        Medium:[''],
        Admission_Gr_No:['0'],
        Prefix:[''],
        Roll_no:['0'],

    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._instService.getInstDataByID(this.instID).subscribe(mRes => {
            console.log(mRes)
            console.log(this.instID)
            if (mRes.status === true) {
                let gData = JSON.parse(mRes.result);
                console.log(gData)
                this.rObj.patchValue({
                    // Medium: gData[0].medium,
                    Inst_name: gData[0].inst_name,
                    Inst_address: gData[0].inst_address,
                    Inst_branch: gData[0].inst_branch, 
                    Inst_pincode: gData[0].inst_pincode,
                    Inst_country: gData[0].inst_country,
                    Inst_email: gData[0].inst_email,
                    Inst_phone: gData[0].inst_phone,
                    Inst_fax: gData[0].inst_fax,
                    Inst_code: gData[0].inst_code,
                    Inst_currency: gData[0].inst_currency,
                    Inst_language: gData[0].inst_language,
                    Start_subscription_date: gData[0].start_date,
                    Renewal_date: gData[0].renewal_date,
                    Update_date:  gData[0].update_date,
                    Inst_key: gData[0].inst_key,
                    Subscription_period: gData[0].subscription_period,
                    Allowed_users: gData[0].allowed_users,
                    Per_user_cost: gData[0].per_user_cost,
                    Total_cost: gData[0].total_cost,
                    Is_Vernacular : gData[0].isVernacular,                    
                    });
                this.imageSrc = gData[0].icon_path ? this.imgURL+gData[0].icon_path : '';
                // this.imageSrc2 = cData[0].logo_img ? this.imgURL+cData[0].logo_img : '';
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._sService.getSettingsData(this.instID).subscribe(aRes =>{
            console.log(aRes)
            if(aRes.status === true) {
                let adata = JSON.parse(aRes.result)
                console.log(adata)
                this.startMonth = this.academicYearsData.filter( function(a) {
                    return a.ID == adata[0].inst_current_id    
                }).map(r => this.MonthData.filter(m => m.month == r.start_month).map(r => r.monthName))
                this.endMonth = this.academicYearsData.filter( function(a) {
                    return a.ID == adata[0].inst_current_id 
                }).map(r => this.MonthData.filter(m => m.month == r.end_month).map(r => r.monthName))
                this.rObj.patchValue({
                    Medium : adata[0].medium,
                    Current_session_id : this.userData.AcademicID,
                    Admission_Gr_No: adata[0].autogenerate_admission_gr_no,
                    Prefix: adata[0].prefix,
                    Roll_no: adata[0].autogenerate_roll_no
                })
                this.startMonth = adata[0].inst_start_month,
                this.endMonth = adata[0].inst_end_month
                console.log(this.startMonth, this.endMonth)
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.designationData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.staffData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.mediumData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAcademicYearsDataByInstID(this.instID).subscribe(ayRes => {
            console.log(ayRes);
            if (ayRes.status === true) {
                this.academicYearsData = JSON.parse(ayRes.result);
                console.log(this.academicYearsData)
            } else {
                console.log(ayRes.msg);
            }
            this._gService.showLoader(false);
        })

        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Current_session_id'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);            
            this.startMonth = this.academicYearsData.filter( function(a) {
                return a.ID == value    
            }).map(r => this.MonthData.filter(m => m.month == r.start_month).map(r => r.monthName))
            this.endMonth = this.academicYearsData.filter( function(a) {
                return a.ID == value
            }).map(r => this.MonthData.filter(m => m.month == r.end_month).map(r => r.monthName))
            this._gService.showLoader(false);
            console.log(this.startMonth[0][0], this.endMonth[0][0])
        })
        

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    copyInputMessage(inputElement: any){
        if(navigator.clipboard.writeText(inputElement)) {
        //  alert('copied')
         this._gService.triggerSnackbar('Copied Successfully', 'success', true);
        }
         
     }

    // setTabActive(tabName: string) {
    //     if (tabName == 'basicTab') {
    //         this.basicTab = true
    //     } else {
    //         this.basicTab = false
    //     }
    //     if (tabName == 'addressTab') {
    //         this.addressTab = true
    //     } else {
    //         this.addressTab = false
    //     }
    //     if (tabName == 'documentTab') {
    //         this.documentTab = true
    //     } else {
    //         this.documentTab = false
    //     }
    //     if (tabName == 'payrollTab') {
    //         this.payrollTab = true
    //     } else {
    //         this.payrollTab = false
    //     }
    //     if (tabName == 'otherTab') {
    //         this.otherTab = true
    //     } else {
    //         this.otherTab = false
    //     }
    // }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;                    
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.imageSrc = '';
    }

    // on2FileChange(event:any) {
    //     this.status2 = false
    //     const file = event.target.files[0];
    //     this.status2 = event.target.files.length > 0 ? true : false;
    //     if (this.status2 == true) {
    //        const reader = new FileReader();
    //        reader.readAsDataURL(file);
    //        reader.onload = () => {
    //           this.image2Src = reader.result;   
                 
    //        }
    //     }
    //     console.log(this.image2Src)   
        
    // }

    // removeDocument1() {
    //     this.image2Src = '';
    // }

    save(){
        this._gService.showLoader(true);
        // if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let settingData = {
                inst_id : this.instID,
                settings : {
                    
                    // activeSession : rObj.Current_session_id,
                    inst_current_id : rObj.Current_session_id,
                    inst_start_month : this.startMonth ? this.startMonth.toString() : this.startMonth[0].toString(),
                    inst_end_month : this.endMonth ? this.endMonth.toString() : this.endMonth[0].toString(),
                    medium : this.isVernacular == 1 ? rObj.Medium : 0, 
                    autogenerate_admission_gr_no: rObj.Admission_Gr_No,
                    prefix: rObj.Admission_Gr_No == '1' ? rObj.Prefix : '',
                    autogenerate_roll_no: rObj.Roll_no
                }
            }
            console.log(settingData)
            this._sService.saveSettingsData(settingData).subscribe(cmsRes => {
                console.log(cmsRes)
                if (cmsRes.status === true) {
                    console.log(this.rObj.controls.Current_session_id.value)
                    // if (this.rObj.controls.Current_session_id.value && this.userData.AcademicID != this.rObj.controls.Current_session_id.value) {
                        this.userData.AcademicID = this.rObj.controls.Current_session_id.value;
                        localStorage.setItem('currentUser', JSON.stringify(this.userData));
                    // }
                    console.log(this.userData)
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('General Settings Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        // }
        this._gService.showLoader(false);
    }

}