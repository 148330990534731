import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AcademicYearModalComponent } from '../../modal/academicyearmodal/academicyearmodal.component';
import { SettingsService } from 'src/app/services/settings.service';
import { ChangePasswordModalComponent } from '../../modal/changePasswordmodal/changePassword.component';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName : any;
    public imageSrc : any;
    public gender : any;
    public userRole = this.userData.UserRole ;
    public staffID ;
    public userID = this.userData.UserID;
    public isMultiInst = this.userData.Is_Multi_branch;
    public isVernacular = this.userData.Is_Vernacular;
    public activeMedium = this.userData.activeMedium;
    public activeLanguage = this.userData.activeLanguage;
    public imgURL = this._gService.getImgPath();

    public activeAcademicID = this.userData.AcademicID;
   

    public allLanguages: any[] = [{'title':'English', 'value': 'en', 'icon': 'english.png'},{'title':'Hindi', 'value': 'hi', 'icon': 'hindi.png'},{'title':'Marathi', 'value': 'mr', 'icon': 'marathi.png'},{'title':'Gujrati', 'value': 'gu', 'icon': 'gujrati.png'}]
    public activeAcademicSession: string = '';
    public instID = this.userData.InstID;

    public showNotificationsClass: boolean = false;
    public showUserInfoClass: boolean = false;
    public showLanguageClass: boolean = false;

    modalRef: BsModalRef;

    @Input() sidebarStatus : any = false;
    @Output() showSidebar = new EventEmitter();

    public searchData: any[] = [];
    public mediumData: any[] = [];
    public notificationData: any[] = [];
    
    constructor(
        private _gService: GlobalService,
        private _nService: NotificationService,
        private _userService: UserService,
        private modalService: BsModalService,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    selectEvent(item: any) {
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/student/view/'+item.id])
    }

    onChangeSearch(val: string) {
        this._gService.getSearchData(val).subscribe(sRes => {
            console.log(sRes);
            if (sRes.status === true) {
                this.searchData = JSON.parse(sRes.result)
            }
        })
    }
    
    onFocused(e: any) {
        this.searchData = []
    }

    searchItem(item: any) {
        let searchImgSrc = item.photo ? this.imgURL+item.photo : '../../assets/img/default-institution-icon.png';
        let classSection = item.type == 'student' ? '<span class="search-meta">'+item.class_name+' ('+item.section_name+')</span>' : '';
        return '<div class="search-item-wrapper"><img class="search-img" src="'+searchImgSrc+'"> <div class="search-name">'+item.name + classSection+'</div><span class="search-result-type">Student</span></span><div>';
    }

    ngOnInit(): void {     

        this.isVernacular = this.userData.isVernacular

        this._nService.getNotificationData(this.instID, this.activeAcademicID, this.userRole, this.userID).subscribe(res => {
            console.log(res)
            if(res.status === true) {
                this.notificationData = JSON.parse(res.result)
                console.log(this.notificationData)
            }
        })

        this._gService.getDataByID(this.activeAcademicID, 'academic_years').subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                let ayData = JSON.parse(ayRes.result);
                console.log(ayData)
                this.activeAcademicSession = ayData[0].start_year +'-'+ ayData[0].end_year;
                // this.activeAcademicSession = ayData[0].ID
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } 
            this._gService.showLoader(false);
        })
        
        if(this.userRole == '1'){
            //alert('1')
            console.log(this.userData);
            this._gService.getDataByID( this.userID, 'users').subscribe(uRes => {
                console.log(uRes)
                if (uRes.status === true) {
                    let userResponse = JSON.parse(uRes.result)
                    console.log(userResponse);
                    this.userName = userResponse[0].name
                    this.imageSrc = userResponse[0].photo_path
                    this._gService.getDataByID(this.userData.UserRole, 'user_roles').subscribe(res =>{
                        console.log(res)
                        let roleData = JSON.parse(res.result)
                        console.log(roleData)
                        this.userRole = roleData[0].name
                    })

                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            })
        } else {
            //alert('2')
            console.log(this.userData);
            let userData =  {
                user_id: this.userData.UserID,
                user_role: this.userData.UserRole,
            }
            console.log(userData);
            this._userService.getUserDataByID(userData).subscribe(uRes => {
                console.log(uRes)
                if (uRes.status === true) {
                    let userResponse = JSON.parse(uRes.result)
                    console.log(userResponse);
                    this.userName = userResponse[0].full_name
                    this.imageSrc = userResponse[0].photo_path
                    this.staffID = userResponse[0].ID
                    this.gender = userResponse[0].gender
                    this._gService.getDataByID(this.userData.UserRole, 'user_roles').subscribe(res =>{
                        console.log(res)
                        let roleData = JSON.parse(res.result)
                        console.log(roleData)
                        this.userRole = roleData[0].name
                    })
    
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            })
        }

    }

    getDate(date: any){
        return moment(date).fromNow()
    }

    gotoPage(link: any) {
        return this._router.navigate([link])
     }

    openChnagePasswordmodal() {
        this.modalRef = this.modalService.show(ChangePasswordModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
            } 
        });
    }


    switchMedium(e : any) {
        console.log(e.target.value)
        this.activeMedium = e.target.value;
        this.userData.activeMedium = this.activeMedium;
        localStorage.setItem('currentUser', JSON.stringify(this.userData));
    }

    switchLanguage(language: string) {
        console.log(language)
        this.activeLanguage = language;
        this.showLanguageClass = false;
        this.userData.activeLanguage = this.activeLanguage;
        localStorage.setItem('currentUser', JSON.stringify(this.userData));
    }

    activeLanguageIcon() {
        return this.allLanguages.filter(l => l.value == this.activeLanguage).map(l => l.icon);
    }

    toggleSidebar() {
        this.sidebarStatus = !this.sidebarStatus;
        this.showSidebar.emit(this.sidebarStatus)
    }

    @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
        event.stopPropagation();
        this.showNotificationsClass = false;
        this.showUserInfoClass = false;
        this.showLanguageClass = false;
      }

    showNotifications(event: Event) {
        event.stopPropagation()
        this.showUserInfoClass = false;
        this.showLanguageClass = false;
        this.showNotificationsClass = !this.showNotificationsClass;
    }

    showUserInfo(event: Event) {
        event.stopPropagation()
        this.showNotificationsClass = false;
        this.showLanguageClass = false;
        this.showUserInfoClass = !this.showUserInfoClass;
    }

    showLanguages(event: Event) {
        event.stopPropagation()
        this.showNotificationsClass = false;
        this.showUserInfoClass = false;
        this.showLanguageClass = !this.showLanguageClass;
    }

    openAcademicYearsModal() {
        this.modalRef = this.modalService.show(AcademicYearModalComponent, {
            class: 'modal-dialog-top academic-year-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                activeAcademicID: this.activeAcademicID,
            } 
        });
    }

    logOut() {
        this._userService.userLogout();
    }
}