import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { AttendanceService } from 'src/app/services/attendance.service';
import { Router, ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { SyllabusService } from 'src/app/services/syllabus.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SyllabusStatusDateModalComponent } from 'src/app/components/modal/syllabusStatusDatemodal/syllabusStatusDatemodal.component';

@Component({
  selector: 'app-syllabusStatus-syllabusStatus',
  templateUrl: './syllabusStatus.component.html',
  styleUrls:['./syllabusStatus.component.css']
})

export class SyllabusStatusComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public subjectData: any[] = [];
    public classSectionData: any[] = [];
    public syllabusStatus: any[] = [];
    public studentAttendanceData: any[] = [];
    public topicData: any[] = [];
    
    public topicsArray: any[] = []
    public classId : any;
    public sectionId : any;
    public subjectId : any;
    public subjectName : any;
    public status = 0;
    public topicStatus : any;
    public completionDate : any;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public medium = this.userData.activeMedium
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _sService: SyllabusService,
        private _aService: AttendanceService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,

    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required], 
        Section: ['', Validators.required],
        Subject: ['', Validators.required],
        isCompleted : [''],
    })

    public sObj = this._fBuilder.group({
        attendanceRow : this._fBuilder.array([])
    })
    
    attendanceRow(): FormArray {
        return this.sObj.get("attendanceRow") as FormArray
    }

    ngOnInit(): void {

        this._gService.showLoader(true);

        console.log(this.sObj.controls.attendanceRow)

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        this.subjectId = this._aRoute.snapshot.paramMap.get('subject')

        if(this.classId && this.sectionId && this.subjectId){
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                Subject : this.subjectId,
            })
            this.search()
        }

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
        //     // console.log(cRes)
        //     if (cRes.status === true) {
        //         this.mediumData = JSON.parse(cRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        // })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.subjectData = JSON.parse(cRes.result);
                this.subjectName = this.subjectData.filter(s => s.ID == this.subjectId).map(s => s.name)

            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'topics').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.topicsArray = JSON.parse(cRes.result);
                console.log(this.topicsArray)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
            }
        })
        this._gService.showLoader(false);
    })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'ftip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

        console.log(this.topicsArray)

        this.modalService.onHide.subscribe((reason) => {
            let topicindex = this.topicsArray.findIndex(t => t.ID == reason)
            this.topicsArray[topicindex].status = 0
        })
        this._gService.showLoader(false);

    }


    checkStatus(e: any, topicID: any) {
        let topicindex = this.topicsArray.findIndex(t => t.ID == topicID)
        if(e.target.checked) {
            this.topicsArray[topicindex].status = 1
            this.status = 1
            this.openDatemodal(topicID,this.status)

        } else {
            this.topicsArray[topicindex].status = 0
            this.status= 0
            this.updateFunction(topicID,this.status)
        }
    }

    updateFunction(topicId: any, status: any) {
        let mData = {
            topic_id: topicId,
            status: status,
            completion_date: '',
        }
        console.log(mData)
        this._sService.updateTopicsStatusData(mData).subscribe(mRes => {
            console.log(mRes)
            this.modalRef.hide();
            if (mRes.status === true) {
                this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url])
                    this._gService.triggerSnackbar('Syllabus Updated Successfully', 'success', true) 
                });
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        })
    }
    

    openDatemodal(topicID: any, status: any) {
        this.modalRef = this.modalService.show(SyllabusStatusDateModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                topicID: JSON.parse(topicID),
                status: status
            } 
        });
    }

    arrayToString(array: any){
        return JSON.stringify(array)
    }

    stringToArray(text: string) {
        // console.log(text)
        if(text) {
            return text.split(',')
        }
       
    }
    getToicData(lessonID: any) {
        this._gService.showLoader(true);
        console.log(this.topicsArray.filter(l => l.lesson_id == lessonID))
        this._gService.showLoader(false);

        return this.topicsArray.filter(l => l.lesson_id == lessonID)
        
    }

    
    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/syllabus/status/'+robj.Class+'/'+robj.Section+'/'+robj.Subject]);
    }

    search() {

        this._sService.getSyllabusStatusData(this.instID,this.activeAcademicID,this.classId,this.sectionId,this.subjectId).subscribe(res =>{
        this._gService.showLoader(true);
            console.log(res)
            this.syllabusStatus = JSON.parse(res.result)
            console.log(this.syllabusStatus)
            this._gService.showLoader(false);

        })

    }

}