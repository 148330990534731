import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-online-admission-settings',
        templateUrl: './settings.component.html',
        })

        export class OnlineAdmissionSettingsComponent implements OnInit {
            public userData = JSON.parse(localStorage.getItem('currentUser')!);
            public userID = this.userData.UserID;

            public instID = this.userData.InstID;
            public activeAcademicID = this.userData.AcademicID;
            public status : boolean ;
            public fileSrc : any

            public mediumData: any[] = [];
            public applicationDataArray: any[] = [];
            public fieldsValue: any[] = [];
            public selectedFields: any[] = [];
            public imgURL = this._gService.getImgPath();
            
            
            dtOptions: any = {};

            modalRef: BsModalRef;

            editorConfig: AngularEditorConfig = {
                editable: true,
                height: '150px',
                placeholder: 'Enter text here...',
            }

            constructor(
                private _gService: GlobalService,
                private _sService: SettingsService,
                private _fBuilder: FormBuilder,
                private _router: Router,
                private _reRoute: RouteReuseStrategy,
                private modalService: BsModalService,
            ) {}

            public rObj = this._fBuilder.group({
                Online_admission: ['',Validators.required],
                Online_admission_pay: [''],
                Fees: [''],
                Document : [''],
                Instructions : [''],
                Terms_Condition : [''],
                inputList : this._fBuilder.array([]) 
            })

            ngOnInit(): void {
                this._gService.showLoader(true);

                let setting_names = "online_admission,online_admission_pay,fees,document_img,instructions,terms_and_condition,application_fields";

                this._sService.getMultipleSettingsData(this.instID, setting_names).subscribe(mRes => {
                    console.log(mRes)
                    if (mRes.status === true) {
                        let gData = JSON.parse(mRes.result);
                        this.selectedFields = JSON.parse(gData[0].application_fields)
                        this.markFieldSelected(JSON.parse(gData[0].application_fields))
                        console.log(this.selectedFields)
                        console.log(gData)
                        this.rObj.patchValue({
                            // Theme: gData[0].theme,
                            Online_admission: gData[0].online_admission,
                            Online_admission_pay: gData[0].online_admission_pay,
                            Fees: gData[0].fees, 
                            Instructions: gData[0].instructions,
                            Terms_Condition: gData[0].terms_and_condition,    
                            });
                        this.fileSrc = gData[0].document_img ? this.imgURL+gData[0].document_img : '';
                    } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this.rObj.controls.Online_admission_pay.valueChanges.subscribe(val => {
            if(val == '1') {
                this.rObj.controls.Fees.addValidators(Validators.required)
            } 
        })

        this.rObj.controls.Online_admission.valueChanges.subscribe(val => {
            if(val == '1') {
                this.rObj.controls.inputList.addValidators(Validators.required)
            }
        })

        // this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.fileSrc = reader.result;          
           }
           console.log(this.fileSrc)
        }
    }

    removeImg() {
        this.fileSrc = '';
    }


    openMediumEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    onFieldSelect(e: any) {
        const selectedFields = <FormArray>this.rObj.controls.inputList;
        selectedFields?.markAllAsTouched();

        if(e.target.checked) {
            selectedFields.push(new FormControl(e.target.value))
        } else {
            let index = selectedFields.controls.findIndex( x => x.value == e.target.value)
            selectedFields.removeAt(index)
        }
        this.fieldsValue = selectedFields.value;
        console.log(selectedFields.value)
    }

    markFieldSelected(fields: any) {
        const selectedFields = <FormArray>this.rObj.controls.inputList;

        fields.forEach((field : any) => {
            selectedFields.push(new FormControl(field));
        });

        this.fieldsValue = selectedFields.value;
    }

    save() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id: this.instID,
                settings : {
                    online_admission: rObj.Online_admission,
                    online_admission_pay: rObj.Online_admission_pay,
                    fees: rObj.Fees,
                    document_img: this.fileSrc,
                    instructions: rObj.Instructions,
                    terms_and_condition: rObj.Terms_Condition,
                    application_fields : JSON.stringify(this.fieldsValue)
                },
                
            }
            console.log(mData)
            this._sService.saveSettingsData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('settings Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}