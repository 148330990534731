<div class="modal-header">
    <h5 class="modal-title">Edit Class</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group" *ngIf="isvernacular == 1">
                <label>Select Medium</label>
                <small class="req"> *</small>
                <div class="radio required" *ngFor="let medium of mediumData">
                    <label class="radio-inline">
                        <input type="radio" value="{{medium.ID}}" formControlName="MediumType">{{medium.name}}
                    </label>
                    <div *ngIf="rObj.controls.MediumType.invalid && (rObj.controls.MediumType.dirty || rObj.controls.MediumType.touched)">
                        <p class="input-error-msg">Please select medium type</p>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Class Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Class">
                <div *ngIf="rObj.controls['Class'].invalid && (rObj.controls['Class'].dirty || rObj.controls['Class'].touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Sections</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <!-- <label *ngFor="let section of sectionData.slice().reverse(); let i = index" formArrayName="Section"> -->
                    <label *ngFor="let section of sectionData; let i = index" formArrayName="Section">
                        <input type="checkbox" [checked]="rObj.controls['Section'].value[i].isSelected" [value]="rObj.controls['Section'].value[i].value" [formControlName]="i" (change)="onSectionSelect(i, section.ID, $event)">
                        {{section.name}}           
                    </label>
                </div>    
                <div *ngIf="rObj.controls['Section'].errors?.['required'] && rObj.controls['Section'].touched">
                    <p class="input-error-msg">Please select a section</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateClass()">Update</button>
            </div>
        </form> 
    </div>
</div>
