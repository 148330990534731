<div class="console-content">
    <form [formGroup]="rObj">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Add Staff</h1>
        <div class="btn-container link-container col-md-6">
            <a href="../import/" class="content-header-link">Import Staff</a>
            <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="saveStaff()">Save</button>
     
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="console-content-navbar">
                <ul class="content-links">
                    <li class="content-link" [ngClass]="basicTab ? 'active' : ''" (click)="setTabActive('basicTab')">
                        <a id="basic-details">
                            <i class="fas fa-user"></i>
                            <span>Basic Details</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="addressTab ? 'active' : ''" (click)="setTabActive('addressTab')">
                        <a id="address-details">
                            <i class="fas fa-map-marker-alt"></i>
                            <span>Address Details</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="documentTab ? 'active' : ''" (click)="setTabActive('documentTab')">
                        <a id="documents">
                            <i class="fas fa-file-alt"></i>
                            <span>Documents</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="payrollTab ? 'active' : ''" (click)="setTabActive('payrollTab')">
                        <a id="payroll-details">
                            <i class="nav-icon fas fa-file-invoice"></i>
                            <span>Payroll Details</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="otherTab ? 'active' : ''" (click)="setTabActive('otherTab')">
                        <a id="other-details">
                            <i class="fas fa-folder-plus"></i>
                            <span>Other Details</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="row">
        
        <div class="col-md-12 console-content-tab">
            <div id="basic-details" class="panel panel-margin-top" [ngClass]="basicTab ? 'active' : ''" >
                <div class="panel-header">
                    <div class="panel-title">
                        Official Details:
                    </div>
                </div>
                <div class="panel-body ">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Staff ID</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Staff_id" class="form-control" value="">
                                     <div *ngIf="rObj.controls.Staff_id.invalid && (rObj.controls.Staff_id.dirty || rObj.controls.Staff_id.touched)">
                                        <p class="input-error-msg">Please enter staff id</p>
                                    </div>
                            </div>
                            <div class="col-md-4">
                                <label>Staff Role</label>
                                <small class="req"> *</small>
                                <select formControlName="Role" class="form-select" required="">
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let role of userRoleData.reverse()">
                                        <option value="{{role.ID}}">
                                            {{role.name}}
                                        </option>
                                     </ng-container>                         
                                </select>
                                <div *ngIf="rObj.controls.Role.invalid && (rObj.controls.Role.dirty || rObj.controls.Role.touched)">
                                    <p class="input-error-msg">Please select role </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Joining Date</label>
                                <small class="req"> *</small>
                                <input type="date" formControlName="Joining_date" class="form-control date-input" autocomplete="off" value="19-03-2024" required="">
                                <div *ngIf="rObj.controls.Joining_date.invalid && (rObj.controls.Joining_date.dirty || rObj.controls.Joining_date.touched)">
                                    <p class="input-error-msg">Please enter joining date </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Department</label>
                                <small class="req"> *</small>
                                <select formControlName="Department" class="form-select" required="">
                                    <option value="">Please Select</option>
                                     <ng-container *ngFor="let dept of departmentData">
                                        <option value="{{dept.ID}}">
                                            {{dept.name}}
                                        </option>
                                     </ng-container>                                   
                                </select>
                                <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                    <p class="input-error-msg">Please select department</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Designation</label>
                                <small class="req"> *</small>
                                <select formControlName="Designation" class="form-select" required="">
                                    <option value="">Please Select </option>
                                    <ng-container *ngFor="let des of designationData">
                                        <option value="{{des.ID}}">
                                            {{des.name}}
                                        </option>
                                     </ng-container>                                  
                                </select>
                                <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                                    <p class="input-error-msg">Please select designation</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>PAN Number</label>
                                <input type="text" formControlName="Pan_number" class="form-control" value="">
                                <div *ngIf="rObj.controls.Pan_number.invalid && (rObj.controls.Pan_number.dirty || rObj.controls.Pan_number.touched)">
                                    <p class="input-error-msg">Please enter pan number</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-header padding-top-none">
                    <div class="panel-title">
                        Personal Details:
                    </div>
                </div>
                <div class="panel-body row">
                    <div class="col-md-10">
                        <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>First Name</label>
                                      <small class="req"> *</small>
                                      <input type="text" formControlName="First_name" class="form-control" >
                                      <div *ngIf="rObj.controls.First_name.invalid && (rObj.controls.First_name.dirty || rObj.controls.First_name.touched)">
                                        <p class="input-error-msg">Please enter first name</p>
                                    </div>
                                </div>
                                  <div class="col-md-4">
                                      <label>Last Name</label>
                                      <small class="req"> *</small>
                                      <input type="text" formControlName="Last_name" class="form-control" >
                                      <div *ngIf="rObj.controls.Last_name.invalid && (rObj.controls.Last_name.dirty || rObj.controls.Last_name.touched)">
                                        <p class="input-error-msg">Please enter last name</p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                      <label>Date of Birth</label>
                                      <small class="req"> *</small>
                                      <input type="date" formControlName="Dob" class="form-control date-input">  
                                      <div *ngIf="rObj.controls.Dob.invalid && (rObj.controls.Dob.dirty || rObj.controls.Dob.touched)">
                                        <p class="input-error-msg">Please enter date of birth</p>
                                    </div>                               
                                </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>Gender</label>
                                      <small class="req"> *</small>
                                      <select formControlName="Gender" class="form-select" >
                                          <option value="">Please Select</option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                      </select>
                                      <div *ngIf="rObj.controls.Gender.invalid && (rObj.controls.Gender.dirty || rObj.controls.Gender.touched)">
                                        <p class="input-error-msg">Please select gender</p>
                                    </div>
                                  </div>
                                  <!-- <div class="col-md-4">
                                      <label>Father Name</label>
                                      <input type="text" formControlName="Father_name" class="form-control">
                                      <div *ngIf="rObj.controls.Father_name.invalid && (rObj.controls.Father_name.dirty || rObj.controls.Father_name.touched)">
                                        <p class="input-error-msg">Please enter father name</p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                      <label>Mother Name</label>
                                      <input type="text" formControlName="Mother_name" class="form-control" value="">
                                      <div *ngIf="rObj.controls.Mother_name.invalid && (rObj.controls.Mother_name.dirty || rObj.controls.Mother_name.touched)">
                                        <p class="input-error-msg">Please enter mother name</p>
                                    </div>
                                  </div> -->

                                  <div class="col-md-4">
                                    <label>Email</label>
                                    <small class="req"> *</small>
                                    <input type="email" formControlName="Email" class="form-control" value="" required="">
                                    <div *ngIf="rObj.controls.Email.invalid && (rObj.controls.Email.dirty || rObj.controls.Email.touched)">
                                      <p class="input-error-msg">Please enter email</p>
                                  </div>
                              </div>
                                <div class="col-md-4">
                                    <label>Mobile Number</label>
                                    <small class="req"> *</small>
                                    <input type="number" formControlName="Mobile" class="form-control" value="" required="">
                                    <div *ngIf="rObj.controls.Mobile.invalid && (rObj.controls.Mobile.dirty || rObj.controls.Mobile.touched)">
                                      <p class="input-error-msg">Please enter mobile number</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                        <div class="form-group">
                              <div class="row">
                                 
                                <div class="col-md-4">
                                    <label>Emergency Contact Number</label>
                                    <input type="number" formControlName="Alt_mobile" class="form-control" >
                                    <div *ngIf="rObj.controls.Alt_mobile.invalid && (rObj.controls.Alt_mobile.dirty || rObj.controls.Alt_mobile.touched)">
                                    <p class="input-error-msg">Please enter alternate number</p>
                                    </div>
                                </div>
                                  <div class="col-md-4">
                                    <label>Marital Status</label>
                                      <select class="form-select" formControlName="Marital_status">
                                        <option value="">Select status</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Widowed">Widowed</option>
                                        <option value="Separated">Separated</option>
                                        <option value="Not Specified">Not Specified</option>  	             
                                      </select>
                                      <div *ngIf="rObj.controls.Marital_status.invalid && (rObj.controls.Marital_status.dirty || rObj.controls.Marital_status.touched)">
                                          <p class="input-error-msg">Please enter marital status</p>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Aadhaar Number</label>
                                    <input type="number" formControlName="Aadhaar_number" class="form-control" value="">
                                    <div *ngIf="rObj.controls.Aadhaar_number.invalid && (rObj.controls.Aadhaar_number.dirty || rObj.controls.Aadhaar_number.touched)">
                                      <p class="input-error-msg">Please enter aadhaarnumber</p>
                                  </div>
                              </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <div class="row">
                                  
                                <div class="col-md-4">
                                    <label>Qualification</label>
                                    <input type="text" formControlName="Qualification" class="form-control" value="">
                                    <div *ngIf="rObj.controls.Qualification.invalid && (rObj.controls.Qualification.dirty || rObj.controls.Qualification.touched)">
                                    <p class="input-error-msg">Please enter qualification</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Total Work Experience</label>
                                    <small class="float-right">(In Years)</small>
                                    <input type="number" formControlName="Work_experience" class="form-control" >
                                    <div *ngIf="rObj.controls.Work_experience.invalid && (rObj.controls.Work_experience.dirty || rObj.controls.Work_experience.touched)">
                                      <p class="input-error-msg">Please enter work experience</p>
                                  </div>
                              </div>
                              </div>
                          </div>
                          <!-- <div class="form-group">
                              <div class="row">
                                 
                                  <div class="col-md-4">
                                  </div>
                                  <div class="col-md-4">
                                  </div>
                              </div>
                          </div> -->
                      </div>
                      <div class="col-md-2">
                          <div class="form-group">
                              <label>Staff Photo</label>
                                <div class="form-img-wrapper">
                                    <img class="student-photo photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/default-user-avatar.png'">
                                </div>
                                <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                    <button class="upload-btn">Select Photo</button>
                                    <input type="file" formControlName="PhotoUpload" id="photoUpload" (change)="onFileChange($event)">
                                </div>
                                <div class="upload-btn-wrapper" *ngIf="imageSrc">
                                    <button class="upload-btn" (click)="removeImg()">Remove Photo</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="address-details" class="panel panel-margin-top" [ngClass]="addressTab ? 'active' : ''">
                <div class="panel-header">
                    <div class="panel-title">
                        Address Details:
                    </div>
                </div>
                <div class="panel-body">
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-6">
                                      <label>Permanent Address</label>
                                      <textarea formControlName="Permanent_address" class="form-control"></textarea>
                                      <div *ngIf="rObj.controls.Permanent_address.invalid && (rObj.controls.Permanent_address.dirty || rObj.controls.Permanent_address.touched)">
                                        <p class="input-error-msg">Please enter permanent address</p>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                      <label>Current Address</label>
                                      <textarea formControlName="Current_address" class="form-control"></textarea>
                                      <div *ngIf="rObj.controls.Current_address.invalid && (rObj.controls.Current_address.dirty || rObj.controls.Current_address.touched)">
                                        <p class="input-error-msg">Please enter current address number</p>
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>City</label>
                                      <input type="text" formControlName="City" class="form-control" >
                                </div>
                                  <div class="col-md-4">
                                      <label>Pincode</label>
                                      <input type="number" formControlName="Pincode" class="form-control" >
                                  </div>
                                  <div class="col-md-4">
                                      <label>State</label>
                                      <input type="text" formControlName="State" class="form-control" >
                                  </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>Country</label>
                                      <select formControlName="Country" class="form-select">
                                        <option value="">Select Country</option>
                                        <ng-container  *ngFor="let country of countriesData">
                                          <option [value]="country.name">
                                              {{country.name}}
                                          </option>
                                        </ng-container>
                                               <div *ngIf="rObj.controls.Country.invalid && (rObj.controls.Country.dirty || rObj.controls.Country.touched)">
                                                      <p class="input-error-msg">Please enter </p>
                                               </div>
                                      </select>
                                    </div>
                              </div>
                          </div>
                </div>
            </div>
            
            <div id="documents" class="panel panel-margin-top" [ngClass]="documentTab ? 'active' : ''">
                <div class="panel-header">
                    <div class="panel-title">
                        Documents:
                    </div>
                </div>
                <div class="panel-body">
                        <div class="form-group">
                              <div class="row">
                                  <div class="col-md-3">
                                      <label>Document Title</label>
                                      <input id="docTitle1" type="text" formControlName="Document1_title" class="form-control docTitle" placeholder="Document 1" value="">
                                </div>
                                  <div class="col-md-3">
                                      <label>Upload Document</label>
                                        <div class="imgUpload-wrapper">
                                            <div class="removeImgOverlay" (click)="removeDocument1()" *ngIf="file1Src">Remove Document</div>  
                                            <input type="file" formControlName="Document1_file" class="form-control" (change)="onDoc1FileChange($event)">
                                        </div>
                                  </div>
                                  <div class="col-md-3">
                                      <label>Document Title</label>
                                      <input id="docTitle2" type="text" formControlName="Document2_title" class="form-control docTitle" placeholder="Document 2" value="">
                                  </div>
                                  <div class="col-md-3">
                                      <label>Upload Document</label>
                                      <div class="imgUpload-wrapper">
                                        <div class="removeImgOverlay" (click)="removeDocument2()" *ngIf="file2Src">Remove Document</div>  
                                        <input type="file" formControlName="Document2_file" class="form-control" (change)="onDoc2FileChange($event)">
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-3">
                                      <input id="docTitle3" type="text" formControlName="Document3_title" class="form-control docTitle" placeholder="Document 3" >
                                </div>
                                  <div class="col-md-3">
                                    <div class="imgUpload-wrapper">
                                        <div class="removeImgOverlay" (click)="removeDocument3()" *ngIf="file3Src">Remove Document</div>  
                                        <input type="file" formControlName="Document3_file" class="form-control" (change)="onDoc3FileChange($event)">
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                      <input id="docTitle4" type="text" formControlName="Document4_title" class="form-control docTitle" placeholder="Document 4" >
                                  </div>
                                  <div class="col-md-3">
                                    <div class="imgUpload-wrapper">
                                        <div class="removeImgOverlay" (click)="removeDocument4()" *ngIf="file4Src">Remove Document</div>  
                                        <input type="file" formControlName="Document4_file" class="form-control" (change)="onDoc4FileChange($event)">
                                    </div>
                                  </div>
                              </div>
                          </div>
                          
                </div>
            </div>
            
            <div id="payroll-details" class="panel panel-margin-top" [ngClass]="payrollTab ? 'active' : ''">
                <div class="panel-header">
                    <div class="panel-title">
                        Payroll Details:
                    </div>
                </div>
                <div class="panel-body">
                        <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>EPF Number</label>
                                      <input type="text" formControlName="Epf_number" class="form-control" >
                                </div>
                                  <div class="col-md-4">
                                      <label>Basic Salary</label>
                                      <small class="float-right">INR</small>
                                      <input type="number" formControlName="Basic_salary" class="form-control" >
                                  </div>
                                  <div class="col-md-4">
                                      <label>Contract Type</label>
                                      <select formControlName="Contract_type" class="form-select">
                                          <option value="">Please Select</option>
                                          <option value="1">Permanent</option>
                                          <option value="2">Probation</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                </div>
                <div class="panel-header padding-top-none">
                    <div class="panel-title">
                        Bank Account Details:
                    </div>
                </div>
                <div class="panel-body">
                        <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>Account Name</label>
                                      <input type="text" formControlName="Bank_account_name" class="form-control" >
                                </div>
                                  <div class="col-md-4">
                                      <label>Account Number</label>
                                      <input type="text" formControlName="Bank_account_number" class="form-control" >
                                  </div>
                                  <div class="col-md-4">
                                      <label>Bank Name</label>
                                      <input type="text" formControlName="Bank_name" class="form-control" >
                                  </div>
                              </div>
                          </div>
                          
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>Bank Branch Name</label>
                                      <input type="text" formControlName="Bank_branch_name" class="form-control" >
                                </div>
                                  <div class="col-md-4">
                                      <label>IFSC Code</label>
                                      <input type="text" formControlName="Bank_ifsc" class="form-control" >
                                  </div>
                                  <div class="col-md-4">
                                  </div>
                              </div>
                          </div>
                          
                </div>
            </div>
            
            <div id="other-details" class="panel panel-margin-top" [ngClass]="otherTab ? 'active' : ''">
                <div class="panel-header">
                    <div class="panel-title">
                        Leave Details:
                    </div>
                </div>
                <div class="panel-body">
                        <div class="form-group">
                              <div class="row">
                                  <div class="col-md-4">
                                      <label>Casual Leave</label>
                                      <small class="float-right">No. of Days</small>
                                      <input type="number" formControlName="Casual_leave" class="form-control" >
                                </div>
                                  <div class="col-md-4">
                                      <label>Medical Leave</label>
                                      <small class="float-right">No. of Days</small>
                                      <input type="number" formControlName="Medical_leave" class="form-control">
                                  </div>
                                  <div class="col-md-4">
                                      <label>Maternity/Paternity Leave</label>
                                      <small class="float-right">No. of Days</small>
                                      <input type="number" formControlName="Maternity_paternity_leave" class="form-control" >
                                  </div>
                              </div>
                          </div>
                </div>
            </div>
            
        </div>
    </div>
    
    </form>
        
</div>