import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { FeesService } from 'src/app/services/fees.service';
import * as moment from 'moment';
import { printReceiptModalComponent } from '../printReceiptmodal/printReceiptmodal.component';
@Component({
    selector: 'app-paymodal',
    templateUrl: './paymodal.component.html',
    styleUrls: [],
})

export class PayModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public fmIds: any;
    public due_date: any;
    public feeID: any;
    
    public totalAmount: any;
    public studentId: number;
    public amount: number;
    public fine_type: number;
    public fine_amount: number;
    public discount_id : number;
    public typeId : any;
    public fee_group : any;
    private disabledStatus : boolean;
    public rObj : any;
    public isDue : any;
    
    public discount_amt : number = 0;
    public feeDiscountData: any[] = [];
    public statusData: any[] = [];
    
    public now = new Date()
    public  today = moment(this.now).format('YYYY-MM-DD')

    public discount_applied : boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _feeService: FeesService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private modalService: BsModalService,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    ngOnInit(): void {
        this._gService.showLoader(true);

        

        this.disabledStatus = true;
         this.rObj = this._fBuilder.group({
            Date: ['', Validators.required],
            Amount: [{value: this.amount, disabled: this.disabledStatus}],
            Fine_type: [{value: this.fine_type, disabled: this.disabledStatus}],
            Fine_amount: [{value: this.fine_amount, disabled: this.disabledStatus} ],
            Discount_code: ['0'],
            Discount_amount: ['0'],
            Payment_mode: ['cash', Validators.required],
            Note: [''],
            TotalAmount:[],
            Discount_type:[''],
            Fees_group:[''],
        })

        this.dueCheck(this.due_date)
        // console.log(this.isDue,this.totalAmount,this.amount,this.due_date)
        this.totalAmount = this.isDue ? this.totalAmount : this.amount
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_discount').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.feeDiscountData = JSON.parse(lRes.result);
                this.rObj.patchValue({
                    // Discount_code: this.feeDiscountData[0].discount_code,
                    // Discount_amount: this.feeDiscountData[0].discount_amount,
                    Date: this.today
                })
                

            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
       
            this._feeService.getStatusData(this.studentId).subscribe( res => {
                console.log(res)
                if(res.status === true){
                    this.statusData = JSON.parse(res.result);
                }
                console.log(this.statusData)
            })
            this._gService.showLoader(false);
        // })
    }
    getdiscountType(e : any){
        // alert(e.target.value)
        let discount_id = e.target.value

        if (discount_id != '') {
            this._gService.getDataByID(discount_id, 'fees_discount').subscribe(dataRes => {
                console.log(dataRes)
                let dData = JSON.parse(dataRes.result)
                console.log(dData)
                this.typeId = dData[0].discount_type
                this.discount_amt = dData[0].discount_amount
                console.log(this.rObj.value.Discount_code)
                if(this.typeId == 'fix') {
                    this.totalAmount = this.totalAmount - dData[0].discount_amount  
                } else {
                    this.totalAmount = Math.round(this.totalAmount - ((this.isDue ? this.totalAmount : this.amount * dData[0].discount_percentage) / 100 ))                        
                }    
                this.discount_applied = true
            })
        } else {
            this.totalAmount = this.totalAmount
            // this.totalAmount = 10
        }       
    }
    openReceiptModal(TxnID : any) {
        this.modalRef = this.modalService.show(printReceiptModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                TxnID: TxnID,
            } 
        });
    }
    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_ids.split(',').includes(feesId)).map(s => s.txn_id)
    }

    dueCheck(due_date : any){
        let now = new Date()
        let  today = moment(now).format('YYYY-MM-DD')
        if(today > due_date) {
            this.isDue = true

        } else {
            this.isDue = false
        }
        return this.isDue
        }
    
    View(){}
    PayAndPrint(){
        // if (this.rObj.valid) {
        //     let rObj = this.rObj.value;
        //     let mData = {
        //         inst_id : this.instID ,
        //         academic_id : this.activeAcademicID,
        //         student_id : this.studentId,
        //         fees_master_id: this.fmIds,
        //         fees_group_id: this.fee_group,
        //         amount:this.totalAmount,
        //         fine_amount:this.fine_amount,
        //         discount_type: rObj.Discount_code,
        //         discount_amount: this.discount_amt,
        //         fine_type:this.fine_type,
        //         payment_mode: rObj.Payment_mode,
        //         payment_date: rObj.Date,
        //         note : rObj.Note,
        //     }
        //     // alert(mData)
        //     console.log(mData)
        //     this._fService.payFeesData(mData).subscribe(mRes => {
        //         console.log(mRes)
        //         this.modalRef.hide();
        //         if (mRes.status === true) {
        //             this.modalRef.onHidden?.subscribe(() => {
        //                 this._reRoute.shouldReuseRoute = () => false;
        //                 this._gService.triggerSnackbar('Payment Saved  Successfully', 'success', true) 
        //             });
        //         } else {
        //             this._gService.triggerSnackbar('Something went wrong', 'error', true);
        //         }
        //     }, err => {
        //         console.log(err);
        //         this._gService.triggerSnackbar('Server Error!', 'error', true);
        //     })
        // }
        this.Pay()
        this.openReceiptModal(this.getTxnId(this.feeID))
    }

    Pay() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id : this.instID ,
                academic_id : this.activeAcademicID,
                student_id : this.studentId,
                fees_master_ids: this.fmIds,
                fees_group_id: this.fee_group,
                amount: this.amount,
                fine_amount:this.fine_amount,
                discount_type: rObj.Discount_code,
                discount_amount: this.discount_amt,
                fine_type:this.fine_type,
                paid_amount: this.totalAmount,
                payment_mode: rObj.Payment_mode,
                payment_date: rObj.Date,
                note : rObj.Note,
            }
            // alert(mData)
            console.log(mData)
            this._feeService.payFeesData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Payment Saved  Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}