import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
@Component({
    selector: 'app-printPaymodal',
    templateUrl: './printPaymodal.component.html',
    styleUrls: ['./printPaymodal.component.css'],
})

export class PrintPaymodalModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public feeCollectData: any[] = [];
    public payrollData: any[] = [];
    public departmentData: any[] = [];
    public designationData: any[] = [];
    // public payrollData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public staff_name: string;
    public month: any;
    public year: string;
    public department: any;
    public designation: any;
    public departmentName: string;
    public designationName: string;
    public monthYear: string;
    public payId: any;
    public feeTypeNames: any;
    public feeTypeAmts: any;
    public TxnID: number;
    public PayslipHeader: any;
    public PayslipFooter: any;
    public imgURL = this._gService.getImgPath();

    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];


    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        public modalRef: BsModalRef,
        private _aRoute: ActivatedRoute,
        private _router: Router

    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);
        this._sService.getMultipleSettingsData(this.instID, 'payslip_header_img,payslip_footer_content').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let cData = JSON.parse(mRes.result);
                console.log(cData)
                this.PayslipHeader = cData[0].payslip_header_img
                this.PayslipFooter = cData[0].payslip_footer_content
                console.log(this.PayslipFooter)
                console.log(this.PayslipHeader)
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        console.log(this.department,this.designation)
        this._gService.getDataByID(this.payId, 'payroll').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.payrollData = JSON.parse(cRes.result);
                console.log(this.payrollData)
                    //    this.payment_amt = this.payrollData[0].net;
            } 
        })

        this._gService.getDataByID(this.department, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
                console.log(this.departmentData)
                this.departmentName = this.departmentData[0].name
            } else {

            }
        })
        this._gService.getDataByID(this.designation, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
                console.log(this.designationData)
                this.designationName = this.designationData[0].name       
            }
        })
        
        this.monthYear = this.MonthData[this.month-1].monthName+'-'+this.year
        console.log(this.departmentName,this.designationName)
        this._gService.showLoader(false);
    }

    print1(){
        const originalContents = document.body.innerHTML;
        window.print();
    }

   
    
    print(){

            //const printContents : any =  document.querySelector(".modal-content-wrapper")?.innerHTML;
            //const originalContents = document.body.innerHTML;
            //document.body.innerHTML = originalContents
            window.print();
            // document.body.innerHTML = originalContents;
            // this._router.navigate([this._router.url])
    }

    
}

