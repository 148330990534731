<div class="console-content">
    <form [formGroup]="rObj">
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link" [ngClass]="studentReports ? 'active' : ''">
                        <a (click)="setTabActive('studentReports')">Students Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="classSectionReports ? 'active' : ''">
                        <a (click)="setTabActive('classSectionReports')">Class Section Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="guardianReports ? 'active' : ''">
                        <a (click)="setTabActive('guardianReports')">Guardian Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="studentHistoryReports ? 'active' : ''">
                        <a (click)="setTabActive('studentHistoryReports')">Student History</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="classSubjectReports ? 'active' : ''">
                        <a (click)="setTabActive('classSubjectReports')">Class Subject Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="admissionReports ? 'active' : ''">
                        <a (click)="setTabActive('admissionReports')">Admission Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="siblingsReports ? 'active' : ''">
                        <a (click)="setTabActive('siblingsReports')">Sibling Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="studentGenderRatioReports ? 'active' : ''">
                        <a (click)="setTabActive('studentGenderRatioReports')">Student Gender Ratio Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="studentTeacherRatioReports ? 'active' : ''">
                        <a  (click)="setTabActive('studentTeacherRatioReports')">Student Teacher Ratio Report</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="student-details" class="panel panel-margin-top" [ngClass]="studentReports ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-3">Student Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row"> 
                                <div class="col-md-2">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Gender</label>
                                    <select formControlName="Gender" class="form-select">
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label>Category</label>
                                    <select formControlName="Category" class="form-select">
                                        <option value="">Select Category</option>
                                        <option value="General">General</option>
                                        <option value="OBC">OBC</option>
                                        <option value="SC">SC</option>
                                        <option value="ST">ST</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-btn-wrapper" style="margin-top: 24px;">
                                        <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                              
                    </div>
                    <div class="panel-body">
                        <!-- <ng-container *ngIf="studentData.length">
                            <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                                <span class="rows_selected">{{selectedIds.length}} Selected</span>
                                <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                            </div>
                        </ng-container> -->
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Student Name</th>
                                     <th class="align-left">Admission/GR number</th>
                                    <th>Class</th>
                                    <th>Section</th>
                                    <th>Roll Number</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Category</th>
                                    <th>Blood Group</th>
                                   
                                    <!-- <th class="no-sort">Manage</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentData">
                                    <!-- <td >
                                        <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)"> -->
                                        <!-- <input type='checkbox' class="list-checkbox" data-id='<?php echo $row['ID'] ; ? >' > -->
                                    <!-- </td> -->
                                    <td class="align-left">
                                        <img [src]="s.photo_path ? imgURL+s.photo_path : '/assets/img/default-user-avatar.png'" style="width: 40px; border: 1px solid #dcdcdc;">
                                        {{s.full_name}}
                                    </td>
                                    
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>
                                        <ng-container *ngFor="let class of classData">
                                            <ng-container *ngIf="class.ID == s.class">
                                                {{class.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let section of sectionData">
                                            <ng-container *ngIf="section.ID == s.section">
                                                {{section.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{s.roll_no}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.category}}</td>
                                    <td>{{s.blood_group}}</td>
                                    
                                    <!-- <td class="action-btn">
                                        <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                        <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                        <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>  
                        <div *ngIf="!rObj.valid">
                            <p style="text-align: center;">Please enter the fields </p>
                        </div> 
                        <div *ngIf="!studentData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="classSection-details" class="panel panel-margin-top" [ngClass]="classSectionReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-6">Class Section Report</h1>
                        <div class="header-msg col-md-6"></div>
                    </div>
                    
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentCountData.length">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Class (Section)</th>
                                    <th>Student</th>
                                    <th class="no-sort">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                               <tr *ngFor="let s of studentCountData; let i=index">
                                <td>{{i+1}}</td>
                                <td>{{s.class_name}}({{s.section_name}})</td>
                                <td>{{s.students_count}}</td>
                                <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.class,s.section)" ><i class="far fa-eye"></i></a>
                                </td>
                               </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentCountData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="guardian-details" class="panel panel-margin-top" [ngClass]="guardianReports ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Guardians Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">  
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group btn-container" style="margin-top: 24px;">
                                        <button class="submit-btn primary-btn" type="submit" [disabled]="!rObj.valid" (click)="searchGuardianReport()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                               
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="guardianData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Class(Section)</th>
                                    <th>Admission No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Mobile Number</th>
                                    <th>Guardian Name</th>
                                    <th>Guardian Relation</th>
                                    <th>Guardian Phone</th>
                                    <th>Father Name</th>
                                    <th>Father Phone</th>
                                    <th>Mother Name</th>
                                    <th>Mother Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of guardianData">
                                    <td class="align-left">
                                        <ng-container *ngFor="let class of classData">
                                            <ng-container *ngIf="class.ID == s.class">
                                                {{class.name}}
                                            </ng-container>
                                        </ng-container>
                                    ( <ng-container *ngFor="let section of sectionData">
                                            <ng-container *ngIf="section.ID == s.section">
                                                {{section.name}}
                                            </ng-container>
                                        </ng-container>)
                                    </td>
                                    
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{s.guardian_name? s.guardian_name : '-'}}</td>
                                    <td>{{s.guardian_relation? s.guardian_relation : '-'}}</td>
                                    <td>{{s.guardian_mobile? s.guardian_mobile : '-'}}</td>
                                    <td>{{s.father_name? s.father_name : '-'}}</td>
                                    <td>{{s.father_mobile ? s.father_mobile : '-'}}</td>
                                    <td>{{s.mother_name ? s.mother_name : '-'}}</td>
                                    <td>{{s.mother_mobile? s.mother_mobile : '-'}}</td>
                                 
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!guardianData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="studentHistory-details" class="panel panel-margin-top" [ngClass]="studentHistoryReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Student History</h1>
                        <div class="header-msg col-md-5">
                                        </div>
                    </div>

                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentHistoryData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Admission No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Admission Date</th>
                                    <th>Class(Start-End)</th>
                                    <th>Session(Start-End)</th>
                                    <th>Years</th>
                                    <th>Mobile Number</th>
                                    <th>Guardian Name</th>
                                    <th>Guardian Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentHistoryData">
                                    <!-- <td >
                                      <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                    </td> -->                                    
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>{{s.student_name}}</td>
                                    <td>{{s.admission_date}}</td>
                                    <td>{{s.start_class}}-{{s.end_class}}</td>
                                    <td>{{s.start_academic_start_year}}-{{getTwoDigit(s.start_academic_end_year)}} - {{s.end_academic_start_year}}-{{getTwoDigit(s.end_academic_end_year)}}</td>
                                    <td>{{s.years}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{s.guardian_name}}</td>
                                    <td>{{s.guardian_phone}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentHistoryData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
    
                    </div>
                </div>
                <div id="classSubject-details" class="panel panel-margin-top" [ngClass]="classSubjectReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-6">Class Subject Reports</h1>
                        <div class="header-msg col-md-6">
                        </div>
                    </div>
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">   
                                
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group btn-container" style="margin-top:24px">
                                        <button class="submit-btn primary-btn" type="submit" [disabled]="!rObj.valid" (click)="searchclassSubjectReport()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                              
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="classSubjectData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Class</th>
                                    <th>Section</th>
                                    <th>Subject</th>
                                    <th>Teacher Name</th>
                                    <th>Timing</th>
                                    <th>Room No </th>
                                    <!-- <th class="no-sort">Manage</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of classSubjectData">                                    
                                    <td class="align-left">{{s.class_name}}</td>
                                    <td>{{s.section_name}}</td>
                                    <td>{{s.subject_name}}</td>
                                    <td>{{s.teacher_name}}</td>
                                    <td>{{s.time_from}}-{{s.time_to}}</td>
                                    <td>{{s.room_no}}</td>
                                   
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!classSubjectData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                   
                </div>
                <div id="admission-details" class="panel panel-margin-top" [ngClass]="admissionReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Admission Report</h1>
                        <div class="header-msg col-md-5">

                        </div>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group row">   
                            <div class=" col-md-5"> 
                                <label>Period</label>   
                                <select class="form-select" formControlName="Search_type" autocomplete="off">
                                    <option value="">Select</option>
                                    <option value="today">Today</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_3_month">Last 3 Months</option>
                                    <option value="last_6_month">Last 6 Months</option>
                                    <option value="last_12_month">Last 12 Months</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom">Custom Period</option>
                                </select>   
                            </div>
                            <div  class="col-md-7" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                <div class="row">
                                    <div class="col-md-6" >
                                        <label>Date From</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_From" >
                                        <div *ngIf="rObj.controls.Date_From.invalid && (rObj.controls.Date_From.dirty || rObj.controls.Date_From.touched)">
                                            <p class="input-error-msg">Please select date from</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Date To</label>
                                        <small class="req">*</small>
                                        <input type="date" class="form-control date-input" formControlName="Date_to" >
                                        <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                            <p class="input-error-msg">Please select Date to</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group btn-container">
                                <button class="submit-btn primary-btn btn" type="submit" (click)="searchAdmission()">Search</button>
                            </div>       
                        </div>
                    </div>

                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="getAdmissionData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Admission No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Class(Section)</th>
                                    <th>Father Name</th>
                                    <th>Date of Birth</th>
                                    <th>Admission date</th>
                                    <th>Gender</th>
                                    <th>Category</th>
                                    <th>Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of getAdmissionData">   
                                    <td class="align-left">{{s.admission_number}}</td>  
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.class_name}}({{s.section_name}})</td>
                                    <td>{{s.father_name}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.admission_date}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.category    }}</td>
                                    <td>{{s.mobile}}</td>                                  
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!getAdmissionData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>

                </div>
                <div id="sibling-details" class="panel panel-margin-top" [ngClass]="siblingsReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-3">Siblings Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row"> 
                                 
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group btn-container" style="margin-top: 24px;">
                                        <button class="submit-btn primary-btn" type="submit" [disabled]="!rObj.valid" (click)="searchsiblingReport()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                               
                    </div>                               
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="SiblingData.length">
                            <thead>
                                <tr>                                   
                                    <th class="align-left">Student Name</th>
                                    <th>Father Name</th>
                                    <th>Mother Name</th>
                                    <th>Guardian Name</th>
                                    <th>Student Name(Siblings)</th>
                                    <th>Class(Section)</th>
                                    <th>Admission Date</th>
                                    <th>Gender</th>                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of SiblingData">
                                    <td class="align-left">{{s.full_name}}</td>
                                    <td>{{s.father_name ? s.father_name : '-'}}</td>
                                    <td>{{s.mother_name? s.mother_name : '-'}}</td>
                                    <td>{{s.guardian_name? s.guardian_name : '-'}}</td>
                                    
                                    <td>
                                        <ng-container *ngFor="let sibling of stringToArray(s.sibling_ids)">
                                            <!-- {{sibling}} -->
                                            <div><a [href]="'/student/view/'+sibling">{{getStudentData(sibling, 'full_name')}}</a></div>
                                        </ng-container>   
                                    <td>
                                        <ng-container *ngFor="let sibling of stringToArray(s.sibling_ids)">
                                            <div>{{getStudentData(sibling, 'class_name')}}
                                                ({{getStudentData(sibling, 'section_name')}})
                                            </div>
                                        </ng-container>
                                       
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let sibling of stringToArray(s.sibling_ids)">
                                            <div>{{getStudentData(sibling, 'admission_date')}}</div>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let sibling of stringToArray(s.sibling_ids)">
                                            <div>{{getStudentData(sibling, 'gender')}}</div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!SiblingData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="genderRatio-details" class="panel panel-margin-top" [ngClass]="studentGenderRatioReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-6">Student Gender Ratio Report</h1>
                        <div class="header-msg col-md-5">
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentGenderData.length">
                            <thead>
                                <tr>
                                    <th>Class (Section)</th>
                                    <th>Total Boys</th>
                                    <th>Total Girls</th>
                                    <th>Total Students</th>
                                    <th>Boys-Girls Ratio</th>
                                </tr>
                            </thead>
                            <tbody>
                               <tr *ngFor="let s of studentGenderData">
                                <td>{{s.class_name}}({{s.section_name}})</td>
                                <td>{{s.boys_count}}</td>
                                <td>{{s.girls_count}}</td>
                                <td>{{s.students_count}}</td>
                                <td>{{getRatio(s.boys_count,s.girls_count)}}</td>
                               </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentGenderData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="teacherRatio-details" class="panel panel-margin-top" [ngClass]="studentTeacherRatioReports ? 'active' : ''">
                    <div class="console-content-header row">
                        <h1 id="panel_title" class="title col-md-6">Student Teacher Ratio Report</h1>
                        <div class="header-msg col-md-5">
                                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentTeacherData.length">
                            <thead>
                                <tr>
                                    <th>Class (Section)</th>
                                    <th>Total Students</th>
                                    <th>Total Teachers</th>
                                    <th>Student-Teacher Ratio</th>
                                </tr>
                            </thead>
                            <tbody>
                               <tr *ngFor="let s of studentTeacherData">
                                <td>{{s.class_name}}({{s.section_name}})</td>
                                <td>{{s.students_count}}</td>
                                <td>{{s.teachers_count}}</td>
                                <td>{{getRatio(s.students_count,s.teachers_count)}}</td>
                               </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentTeacherData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>